function HtmlToWord(element, filename = "") {
  let weburl = window.location.origin.toString();

  console.log("weburl ===>", weburl);
  
  element = element.replace("logo192.png", `${weburl}/logo192.png`);
  element = element.replace("painscale.png", `${weburl}/painscale.png`);
 
  // console.log("element ===>", element);

  var css = `<style>\ 
  .tbl {
    width: 100%;
    border: 1px solid #b3adad;
    border-collapse: collapse;
    padding: 5px;
  }
  .tbl th {
    border: 1px solid #b3adad;
    padding: 5px;
    background: #f0f0f0;
    color: #313030;
  }
  .tbl td {
    border: 1px solid #b3adad;
    text-align: center;
    padding: 5px;
    background: #ffffff;
    color: #313030;
  }
  

  #tblGeneral {
    width: 100%;
    border: 1px solid #b3adad;
    border-collapse: collapse;
    padding: 5px;
  }  
  #tblGeneral th {
    border: 1px solid #b3adad;
    padding: 5px;
    background: #f0f0f0;
    color: #313030;
  }
  #tblGeneral td {
    border: 1px solid #b3adad;
    text-align: center;
    padding: 5px;
    background: #ffffff;
    color: #313030;
  }
  

  #tblDirectBilling {
    width: 100%;
    border: 1px solid #b3adad;
    border-collapse: collapse;
    padding: 5px;
  }
  #tblDirectBilling th {
    border: 1px solid #b3adad;
    padding: 5px;
    background: #f0f0f0;
    color: #313030;
  }
  #tblDirectBilling td {
    border: 1px solid #b3adad;
    text-align: center;
    padding: 5px;
    background: #ffffff;
    color: #313030;
  }
  
#tblOpdBillChargesList tbody > tr {
  border: 3px solid #b3adad;  
}
#tblOpdBillChargesList tr td { 
  margin: 0px;
  padding: 7px;
}
#tblOpdBillChargesList tr:hover { 
  background: #f0f0f0;  
}

.twoColorInTableTd {
  background: linear-gradient(to right, lightgray 50%, rgb(249, 179, 166) 50%) !important;
}

.lab-header {
  height: 120px;
  margin-bottom: 10px;
  text-align: center;
}
.lab-header-dumy-space {
  height: 130px;
  display: none;
  text-align: center;
  margin-bottom: 20px;
}
.lab-footer {
  height: 150px; 
  padding-left: 150px;
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  padding-top: 40px;
  width: 100%;
}
.lab-footer-dumy-space {
  height: 150px;
  margin-top: 20px;
  display: none;
} 
\ </style>`;

  // console.log('My HTML Page css ====>', css)

  var preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
    <head>
      <meta charset='utf-8'>
      <title>Export HTML To Doc</title>
      ${css}
    </head>
    <body>`;
  var postHtml = "</body></html>";
  //var html = preHtml + document.getElementById(element).innerHTML + postHtml;

  // console.log('My HTML Page ====>', preHtml)

  var html = preHtml + element + postHtml;

  var blob = new Blob(["\ufeff", html], {
    type: "application/msword",
  });

  // Specify link url
  var url = "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

  // Specify file name
  filename = filename ? filename + ".doc" : "document.doc";

  // Create download link element
  var downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Create a link to the file
    downloadLink.href = url;

    // Setting the file name
    downloadLink.download = filename;

    //triggering the function
    downloadLink.click();
  }

  document.body.removeChild(downloadLink);
}

export { HtmlToWord };
