import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { AppProvider } from "./context/Context";
// import { createRoot } from "react-dom/client";
// import { createBrowserRouter, createRoutesFromElements, RouterProvider, BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { BrowserRouter, Link } from "react-router-dom";

// import { ClearCacheProvider, useClearCacheCtx } from "react-clear-cache";

import { Provider } from "react-redux";

// ReactDOM.render('Kya Karna hai', 'Kaha karna hai', Callback function);

// createRoot(document.getElementById("root")).render(
//   <RouterProvider router={router} />
// );

// const router = createBrowserRouter(
//   createRoutesFromElements(
//     <Route path="/" element={<Home />}>
//       {/* <Route path="dashboard" element={<Dashboard />} /> */}
//       <Route path="/opd" element={<OpdRegn />} />,
//       <Route path="/regn" element={<TestReducePage />} />,
//       <Route path="/t2" element={<T2 />} />,
//       <Route path="/t3" element={<T3 />} />,
//     </Route>
//   )
// );

{
  /* <BrowserRouter>
  <Navbar />
  <Routes>
    <Route path="/" element={<Home />}></Route>
    <Route path="/opd" element={<OpdRegn />} />,
    <Route path="/regn" element={<Registration />} />,
    <Route path="/t2" element={<T2 />} />,
    <Route path="/t3" element={<T3 />} />,
    <Route path="*" element={<ErrorPage />} />
  </Routes>
</BrowserRouter>; */
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <React.StrictMode>
      {/* <RouterProvider router={router}>
      <Navbar /> */}
      <Provider store={store}>
        {/* <Navbar /> */}

        <AppProvider>
          <BrowserRouter> 
              <App /> 
          </BrowserRouter>
        </AppProvider> 
        {/* </RouterProvider> */}
      </Provider>
    </React.StrictMode>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
