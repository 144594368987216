export const ActionTypes= {
    GET_FEE_HEADS_MASTER_LIST :"GET_FEE_HEADS_MASTER_LIST",
    OPD_BILLING_GET_FEEHEADS_LIST_MASTER : "OPD_BILLING_GET_FEEHEADS_LIST_MASTER",
    OPD_BILLING_SELECTED_ORIGINAL_FEEHEADS : "OPD_BILLING_SELECTED_ORIGINAL_FEEHEADS",
    OPD_BILLING_REMOVE_SELECTED_FEEHEADS : "OPD_BILLING_REMOVE_SELECTED_FEEHEADS",
    LOGIN : "LOGIN",
    LOGOUT : "LOGOUT",
    ADD_CSV_DATA: "ADD_CSV_DATA",
    REMOVE_CSV_ONE_ROW: "REMOVE_CSV_ONE_ROW",
    // ADD_OPD: "ADD_OPD",
    // DELETE_OPD: "DELETE_OPD",
    SET_OPD_LIST: "SET_OPD_LIST",
    // GET_OPD_LIST: "GET_OPD_LIST",
    // GET_OPD_LIST_BY_MRNO: "GET_OPD_LIST_BY_MRNO",
    SET_HOSPITAL_HEADER:"SET_HOSPITAL_HEADER",
    SET_LAB_HEADER:"SET_LAB_HEADER",
    ADD_LAB_TEST_LIST_MASTER_DATA: "ADD_LAB_TEST_LIST_MASTER_DATA",
}

 