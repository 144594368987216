import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import axios from "../../axios/axios";
// import RegistrationForm from "./RegistrationForm";
import PageHeader from "./../../components/PageHeader";

import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";

import DataGrid from "../../components/useDataGrid";
import Popup from "../../components/Popup";
import Controls from "../../components/Controls";
import Checkbox from "@mui/material/Checkbox";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import { Stack } from "@mui/system";
import Grid from "@mui/material/Grid";
import { Typography, InputAdornment, Box } from "@mui/material";

import * as GenServices from "../../helper/GenServices";
import * as RegnServices from "../../helper/RegnServices";
import { Toolbar } from "@mui/material";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
// import { ComponentToPrint } from './ComponentToPrint';

import { MyUseForm, MyForm } from "../../components/useForm";

const initialValues = {
  //_id: "",
  hid: "2",
  staffId: "",
  name: "",
  soDo: "S/o.",
  fhName: "",
  gender: "0", //here used 0 becouse of DropDown Control
  dob: null, // dayjs().format("YYYY/MM/DD"),
  doj: null,
  dor: null,
  aadhaarNo: "",
  unitName: null, //here used null becouse of AutoComplete Control
  deptt: [],
  // subDeptt: null, //here used null becouse of AutoComplete Control
  desig: null, //here used null becouse of AutoComplete Control
  bloodGroup: "0", //here used 0 becouse of DropDown Control
  qualification: "",
  experience: { years: 0, months: 0, days: 0 },
  pAddress: {
    address: "",
    city: "",
    pincode: "",
    state: "Haryana",
    country: "India",
    nationality: "Indian",
  },
  mobileNo: {
    pMobCountry: "+91",
    pMobNo: "",
    hMobCountry: "+91",
    hMobNo: "",
  },
  phoneNo: "",
  email: "",
  //isEmailVerified
  // picUrl: "",
  staffUN: "",
  ip: "",
  isConsultant: false,
  isOfficeStaff: false,
  // remarks: "",
  // banned: false,
  // bannedOn: null,
  // bannedReason: null,
  //userName
  // password: "1234",
  // roles:[{role: {type:"",staffUN:"", ip:"", doe:null}}]
  //tokens
};

const emailExpression = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

// const isWeekend = (date) => {
//   const day = date.day();
//   return day === 0 || day === 6;
// };

export default function StaffForm(props) { 

  const { addOrEdit, recordForEdit } = props;

  const [staffIdValue, setStaffIdValue] = useState("");
  const [notify, setNotify] = React.useState({ isOpen: false, message: "", type: "" });

  const [deptt, setDeptt] = useState([]);
  const [designation, setDesig] = useState([]);
  const [unitName, setUnitName] = useState([]);

  const getDepartment = async () => {
    const rrr = await GenServices.getEntityInnnerData(2, "Department");
     console.log("Department ------", rrr);
    setDeptt(rrr);
    // return rrr;
  };

  const getDesignation = async () => {
    const rrr = await GenServices.getEntityInnnerData(2, "Designation");
    // console.log("Designation ------", rrr);
    setDesig(rrr);
    // return rrr;
  };

  const getHospitalUnit = async () => {
    const rrr = await GenServices.getEntityInnnerData(2, "HospitalUnit");
    // console.log("HospitalUnit ------", rrr);
    setUnitName(rrr);
    // return rrr;
  };

  useEffect(() => {
    getDepartment();
    getDesignation();
    getHospitalUnit();
  }, []);

  const validate = (fieldValues = formData) => {
    let temp = { ...errors };

    // console.log("fieldValues =====>", fieldValues.dob);

    if ("name" in fieldValues) temp.name = fieldValues.name ? "" : "Name is required.";
    // if ("fhName" in fieldValues) temp.fhName = fieldValues.fhName ? "" : "Field is required.";
    if ("gender" in fieldValues) temp.gender = fieldValues.gender != 0 ? "" : "Gender is required.";
    // if ("dob" in fieldValues) temp.dob = dayjs(fieldValues.dob).isValid() ? "" : "required.";
    // if ("doj" in fieldValues) temp.doj = dayjs(fieldValues.doj).isValid() ? "" : "required.";

    if ("unitName" in fieldValues)
      temp.unitName = fieldValues.unitName !== "" && fieldValues.unitName !== null ? "" : "required.";
    if ("deptt" in fieldValues) temp.deptt = fieldValues.deptt !== "" && fieldValues.deptt !== null ? "" : "required.";
    if ("desig" in fieldValues) temp.desig = fieldValues.desig !== "" && fieldValues.desig !== null ? "" : "required.";

    if ("bloodGroup" in fieldValues) temp.bloodGroup = fieldValues.bloodGroup !== "0" ? "" : "BloodGroup is required.";
    // if ("age" in fieldValues) temp.age = parseInt(fieldValues.age) ? "" : "Age is required.";
    // if ("email" in fieldValues) {
    //   // if (fieldValues.email != "")
    //   temp.email = emailExpression.test(fieldValues.email) ? "" : "Email is not valid.";
    //   // if (fieldValues.email == "") temp.email = "";
    // }
    // if ("disease" in fieldValues) temp.disease = fieldValues.disease ? "" : "Disease is required.";

    // if ("mobileNo" in fieldValues) {
    //   if ("pMobNo" in fieldValues.mobileNo) {
    //     // console.log("pMobNo =====>",fieldValues );
    //     temp.pMobNo = fieldValues.mobileNo.pMobNo.length == 10 ? "" : "Enter Correct Mobile No.";
    //   }
    // }

    if ("pAddress" in fieldValues) {
      if ("address" in fieldValues.pAddress) {
        // console.log("pMobNo =====>",fieldValues );
        temp.address = fieldValues.pAddress.address.length >= 3 ? "" : "Enter Correct Address";
      }
      if ("city" in fieldValues.pAddress) {
        // console.log("pMobNo =====>",fieldValues );
        temp.city = fieldValues.pAddress.city.length >= 3 ? "" : "Enter Correct City";
      }
      if ("state" in fieldValues.pAddress) {
        temp.state =
          fieldValues.pAddress.state !== "" && fieldValues.pAddress.state !== null ? "" : "Enter Correct state";
      }
      if ("country" in fieldValues.pAddress) {
        temp.country =
          fieldValues.pAddress.country !== "" && fieldValues.pAddress.country !== null ? "" : "Enter Correct country";
      }
      if ("nationality" in fieldValues.pAddress) {
        temp.nationality = fieldValues.pAddress.nationality !== "0" ? "" : "Enter Correct nationality";
      }
    }

    setErrors({
      ...temp,
    });

    // console.log("temp===>", temp);

    if ((fieldValues = formData)) return Object.values(temp).every((x) => x == "");
  };

  const { formData, setFormData, errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);

  // console.log(values);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(formData);
    } else {
      setNotify({ isOpen: true, message: "Please enter the value in required fields!", type: "error" });
      //  console.log(errors)
    }
  };

  useEffect(() => {
    if (recordForEdit != null) {
      // console.log('aaaaaaaaaaaaa');
      setFormData({
        ...recordForEdit,
      });

      // setStaffIdValue(recordForEdit.staffId);
    }
  }, [recordForEdit]);

  const resetForm = () => {
    setNotify({ isOpen: false, message: "", type: "" });
    setFormData(initialValues);
    setErrors({});
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    // if (dayjs(value).isValid()) {
    //   setFormData({
    //     ...formData,
    //     [name]: value,
    //   });
    // }

    // if (name == "dob") {
    //   if (dayjs(value).isValid()) {
    //     setFormData((preValues) => ({ ...preValues, ["dob"]: value }));
    //     // setFormData((preValues) => ({ ...preValues, ["age"]: GenServices.getAgeInYear(value) }))
    //   } else {
    //     setFormData((preValues) => ({ ...preValues, ["dob"]: null }));
    //   }
    // }
    // // else if (name == "age" && !dayjs(formData.dob).isValid()) {
    // //   setFormData((preValues) => ({ ...preValues, ["age"]: value }));
    // //   // validate({ ["age"]: value });
    // //   //console.log('aaaaaaaaaa  age', value)
    // // }
  };

  const getDataByStaffID = async () => {
    try {
      if (recordForEdit === null) {
        if (staffIdValue) {
          const res = await axios.get(`/regn/find/${staffIdValue}`);
          console.log("Res  ========> ", res);
          if (res) {
            if (res.data.length > 0) {
              // setRegnValues(res.data[0]);
              setFormData((preValues) => ({ ...preValues, ref_reg_patient: res.data[0]._id }));
              // setFormData((preValues) => {
              //   return { ...preValues, mrNo: res.data[0].mrNo };
              // });
            } else {
              setNotify({ isOpen: true, message: "Data not found!", type: "error" });
            }
          } else {
            setNotify({ isOpen: true, message: "Data not found!", type: "error" });
          }
        } else {
          setNotify({ isOpen: true, message: "Please enter the MRNo!", type: "error" });
        }
      } else {
        setNotify({ isOpen: true, message: "Please reset the form!", type: "error" });
      }
    } catch (error) {
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
  };

  return (
    <>
      <MyForm onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* staffId */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Controls.Input
              label="Staff ID"
              name="staffId"
              value={formData.staffId}
              disabled
              // onChange={handleChangeStaffID}
              // title="press tab key for get record"
              // onKeyDown={(e) => (e.key === "Tab" ? getDataByStaffID() : "")}
              // error={errors.staffId}
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              //       <IconButton onClick={getDataByStaffID} color="primary" sx={{ p: "10px" }} aria-label="directions">
              //         <DirectionsIcon />
              //       </IconButton>
              //     </InputAdornment>
              //   ),
              // }}
            />
          </Grid>

          {/* Name */}
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Controls.Input
              label="Full Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={errors.name}
            />
          </Grid>

          {/* Father/MotherName */}
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Controls.Input
              label="Father/Husband Name"
              name="fhName"
              value={formData.fhName}
              onChange={handleChange}
              // error={errors.fhName}
              sx={{ "& .MuiInputBase-root": { pl: 0 } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Controls.DropdownList
                      name="soDo"
                      value={formData.soDo}
                      onChange={handleChange}
                      options={[
                        { val: "S/o.", text: "S/o." },
                        { val: "D/o.", text: "D/o." },
                        { val: "W/o.", text: "W/o." },
                      ]}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* Gender */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.DropdownList
              label="Gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              options={GenServices.getGender()}
              error={errors.gender}
            />
          </Grid>

          {/* dob */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.DatePicker
              label="Date of Birth"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              // error={errors.dob}
            />
          </Grid>

          {/* doj */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.DatePicker
              label="Date of Joining"
              name="doj"
              value={formData.doj}
              onChange={handleChange}
              // error={errors.doj}
            />
          </Grid>

          {/* dor */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.DatePicker
              label="Date of Relieving"
              name="dor"
              value={formData.dor}
              onChange={handleChange}
              // error={errors.dor}
            />
          </Grid>

          {/* aadhaarNo */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.Input
              label="Aadhaar No."
              name="aadhaarNo"
              value={formData.aadhaarNo}
              onChange={handleChange}
              //error={errors.aadhaarNo}
            />
          </Grid>

          {/* bloodGroup */}
          <Grid item xs={4} sm={4} md={2} lg={2}>
            <Controls.DropdownList
              label="bloodGroup"
              name="bloodGroup"
              value={formData.bloodGroup}
              onChange={handleChange}
              options={GenServices.getBloodGroup()}
              error={errors.bloodGroup}
            />
          </Grid>

          {/* unitName */}
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <Controls.Autocomplete
              label="Unit Name"
              name="unitName"
              value={formData.unitName}
              onChange={handleChange}
              options={unitName.map((val) => val.text)}
              error={errors.unitName}
            />
          </Grid>

          {/* deptt	 */}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Controls.AutocompleteTag
              label="Department"
              name="deptt"
              value={formData.deptt}
              onChange={handleChange}
              //options={deptt.map((val) => val.text)}
              options={deptt.map((val) => val.text)}
              //options={GenServices.getAuthRoles()}
              error={errors.deptt}
            />
          </Grid>

          {/* desig */}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Controls.Autocomplete
              label="Designation"
              name="desig"
              value={formData.desig}
              onChange={handleChange}
              options={designation.map((val) => val.text)}
              error={errors.desig}
            />
          </Grid>

          {/* qualification */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controls.Input
              label="Qualification"
              name="qualification"
              value={formData.qualification}
              onChange={handleChange}
              // Tooltip="Add multiple line with using semicolon"
              title="Add multiple line with using semicolon, this will be used in Lab-Report"
              //error={errors.qualification}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Controls.Input
              label="Experience Year"
              name="experience.years"
              value={formData.experience.years}
              onChange={handleChange}
              // error={errors.years}
              type="Number"
              sx={{ "& .MuiInputBase-root": { pr: 0 } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ pr: 0, mr: 0 }}>
                    <Controls.Input
                      type="Number"
                      label="months"
                      name="experience.months"
                      value={formData.experience.months}
                      onChange={handleChange}
                    />
                    <Controls.Input
                      type="Number"
                      label="days"
                      name="experience.days"
                      value={formData.experience.days}
                      onChange={handleChange}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* Email */}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Controls.Input
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              // error={errors.email}
            />
          </Grid>

          {/* mobileNo.pMobNo */}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Controls.Input
              label="Mobile Number"
              name="mobileNo.pMobNo"
              value={formData.mobileNo.pMobNo}
              onChange={handleChange}
              // error={errors.pMobNo}
            />
          </Grid>

          {/* mobileNo.hMobNo */}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Controls.Input
              label="Home Mobile Number"
              name="mobileNo.hMobNo"
              value={formData.mobileNo.hMobNo}
              onChange={handleChange}
              // error={errors.hMobNo}
            />
          </Grid>

          {/* pAddress.Address */}
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <Controls.Input
              label="Present Address"
              name="pAddress.address"
              value={formData.pAddress.address}
              onChange={handleChange}
              error={errors.address}
            />
          </Grid>

          {/* City */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.Input
              label="City"
              name="pAddress.city"
              value={formData.pAddress.city}
              onChange={handleChange}
              error={errors.city}
            />
          </Grid>

          {/* Pincode */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.Input
              label="Pincode"
              name="pAddress.pincode"
              value={formData.pAddress.pincode}
              onChange={handleChange}
              // error={errors.pincode}
            />
          </Grid>

          {/* State */}
          <Grid item xs={12} sm={4} md={3} lg={3}>
            <Controls.Autocomplete
              label="State"
              name="pAddress.state"
              value={formData.pAddress.state}
              // onChange={(e, val) => handleChangeAutocomplete(val, "pAddress.state")}
              onChange={handleChange}
              options={GenServices.getStateList().map((option) => option.name)}
              error={errors.state}
            />
          </Grid>

          {/* Country */}
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Controls.Autocomplete
              label="Country"
              name="pAddress.country"
              value={formData.pAddress.country}
              onChange={handleChange}
              options={GenServices.getCountryList().map((option) => option.label)}
              error={errors.country}
            />
          </Grid>

          {/* Nationality */}
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Controls.DropdownList
              label="Nationality"
              name="pAddress.nationality"
              value={formData.pAddress.nationality}
              onChange={handleChange}
              options={GenServices.getNationality()}
              error={errors.nationality}
            />
          </Grid>

          {/* phoneNo */}
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Controls.Input
              label="Phone No."
              name="phoneNo"
              value={formData.phoneNo}
              onChange={handleChange}
              // error={errors.phoneNo}
            />
          </Grid>

          {/* remarks */}
          <Grid item xs={6} sm={6} md={5} lg={5}>
            <Controls.Input
              label="Remarks"
              name="remarks"
              value={formData.remarks}
              onChange={handleChange}
              // error={errors.remarks}
            />
          </Grid>

          {/* isConsultant */}
          <Grid item xs={3} sm={3} md={2} lg={2}>
            <Controls.Checkbox
              sx={{ mx: "10px" }}
              label="Is-Consultant"
              name="isConsultant"
              value={formData.isConsultant}
              onChange={handleChange}
              // error={errors.isActive}
            />
          </Grid>

          {/* isOfficeStaff */}
          <Grid item xs={3} sm={3} md={2} lg={2}>
            <Controls.Checkbox
              sx={{ mx: "10px" }}
              label="Is-OfficeStaff"
              name="isOfficeStaff"
              value={formData.isOfficeStaff}
              onChange={handleChange}
              // error={errors.isActive}
            />
          </Grid>

          {/* Button */}
          <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">
            <Box m={1} display="flex">
              
              <span style={{ mx: "10px", flexGrow: 1, textAlign: "center" }}>
                <Controls.Button
                  sx={{ mx: "10px" }}
                  text="Save"
                  //onClick={() => console.log(formData)}
                  //variant="outlined"
                  type="submit"
                  startIcon={<SaveRoundedIcon />}
                />
              </span>
              <span>
                <Controls.Button
                  text="Reset"
                  onClick={resetForm}
                  //variant="outlined"
                  startIcon={<RestartAltIcon />}
                  // color="default"
                />
              </span>
            </Box>
          </Grid>

          {/* <Grid item sm={12}>
          <FormControlLabel control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid> */}

          {/* <Grid item sm={12} md={3}>
          <FormGroup row>  
            
            <TextField label="Test-Name" variant="standard" placeholder="username" />
            </FormGroup>
        </Grid> */}
        </Grid>
      </MyForm>

      <Notification notify={notify} setNotify={setNotify} />

      {/* <Grid item sm={12} md={3}>
            <Controls.RadioGroup
              label="Gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              items={GeneralServices.getGender()}
              //required={true}
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">Year</InputAdornment>
              //   ),
              // }}
            />
          </Grid> */}
    </>
  );
}
