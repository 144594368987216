import { useState } from "react";
import { makeStyles } from "@mui/styles";
import Controls from "./Controls";
import Grid from "@mui/material/Grid";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Toolbar } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import * as XLSX from "xlsx";
import { add_CSV_data } from "../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "@mui/system";

// import { CSVLink } from "react-csv";
// import { getExtension } from "../helper/GenServices";

export default function Import_CSV_File() {
  //   const ref = useRef();
  const dispatch = useDispatch();

  //   const hookData = useSelector((state) => {
  //     return {
  //       uploadeddata: state.GenReducer.uploadeddata,
  //       //regHeadList:state.FeeHeadList_Reducer.feeHeadMain
  //     };
  //   });

  // on change states
  //   const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState("");

  //   const [csvData, setCSVData] = useState([]);

  // it will contain array of objects

  const fileType = [
    "application/vnd.ms-excel",
    "text/csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const readCSVFile = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && fileType.includes(selectedFile.type)) {
      //alert("this is csv");
      const data = await selectedFile.arrayBuffer(selectedFile);
      //   console.log("readCSVFile Data ==========>", data);
      const workbook = XLSX.read(data);
      // console.log("workbook Data ==========>", workbook);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const exceljson = XLSX.utils.sheet_to_json(worksheet);
      console.log("exceljson===> ", exceljson);
      //   setCSVData(exceljson);
      if ("id" in exceljson[0]) {
        dispatch(add_CSV_data(exceljson));
        setExcelFileError("");       
      } else {
        const excelDataWithId = exceljson.map((item, index) => {
          return { ...item, id: index + 1 };
        });
        // console.log("excelDataWithId", excelDataWithId);
        dispatch(add_CSV_data(excelDataWithId));
        setExcelFileError("");
        // setExcelFileError("id field not exists in uploading sheet!");
        // e.target.value = null;
      }
      setTimeout(() => {
        e.target.value = null;
      }, 5000);

    } else {
      //console.log("please select csv file");
      setExcelFileError("Please select csv/excel file for uploading!");
      //setCSVData([]);
    }
  };

  const resetForm = () => {
    setExcelFileError("");
    // setCSVData([]);
    dispatch(add_CSV_data([]));
  };

  return (
    <>
      <div>
        <Container>
          <h2>Upload CSV/EXCEL file</h2>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={3} md={2} lg={2}></Grid>

            <Grid item xs={6} sm={6} md={4} lg={4}>
              {/* <label htmlFor="contained-button-file"> */}
              <Controls.Input
                type="file"
                // accept="image/*"
                // id="contained-button-file"
                // multiple
                // onClick={e => (e.target.value = null)}
                // ref={ref}
                // value={(e) => (csvData.length === 0  ? null: e.target.value)}
                // value=""
                onChange={(e) => readCSVFile(e)}
                error={excelFileError}
              />
              {/* <Controls.Button variant="contained" component="span">
        Upload
      </Controls.Button> */}
              {/* </label> */}
            </Grid>

            <Grid item xs={4} sm={3} md={2} lg={2}>
              <Box style={{ textAlign: "center" }}>
                {/* <Controls.Button
                sx={{ mx: "10px" }}
                text="Save"
                //onClick={() => console.log(formData)}
                //variant="outlined"
                //   type="submit"
                onClick={handleOnClick}
                startIcon={<SaveRoundedIcon />}
              /> */}
                <Controls.Button
                  text="Reset"
                  onClick={resetForm}
                  //variant="outlined"
                  startIcon={<RestartAltIcon />}
                  // color="default"
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
