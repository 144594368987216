import React, { useEffect } from "react";
import axios from "../../axios/axios";
import StaffForm from "./StaffForm";
import PageHeader from "../../components/PageHeader";
// import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { Box, Toolbar } from "@mui/material";

import { Dialog, Paper, Table, TableBody, TableCell, TableRow } from "@mui/material";
import * as StaffServices from "../../helper/StaffServices";
import { useState } from "react";
import DataGrid from "../../components/useDataGrid";
import Popup from "../../components/Popup";
import Controls from "../../components/Controls";
import ConfirmDialog from "../../components/ConfirmDialog";

import Notification from "../../components/Notification";

import { Stack } from "@mui/system";

import { useGlobalContext } from "../../context/Context";

export default function Staff() {
  const { staffUN, ip, isEditRight, isDeleteRight } = useGlobalContext();

  const [hid] = useState(2);

  const [records, setRecords] = useState([]);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState({});
  const [notify, setNotify] = React.useState({ isOpen: false, message: "", type: "" });

  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    isConfirm: null,
    title: "title",
    subtitle: "subtitle",
  });

  const getStaffRecord = async () => {
    //setLoading(true);
    // validate(initialValues);
    try {
      const res = await axios.get(`/staff/?hid=${hid}`);
      //  console.log("getStaffRecord  ===>", res);

      if (res) {
        setRecords(res.data);
        // console.log("res.data ===>", res.data)
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  useEffect(() => {
    getStaffRecord();
  }, []);

  // const handleClickOpen = () => {
  //   setOpenPopup(true);
  // };

  // const handleClose = (value) => {
  //   setOpenPopup(false);
  //   setSelectedValue(value);
  // };

  const bindData = () => {
    // resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    getStaffRecord();
    // setIsSaved(true);
  };

  const addOrEdit = async (StaffData) => {
    // const StaffData = {...formData};
    // console.log("opdData addOrEdit =========>", opdData);
    // const queryobj = { params: { mrNo: 1234 } }; //<=== this query only for testing
    if (StaffData._id) {
      //This is for Update
      try {
        if (isEditRight) {
          // console.log("axios patch Start===>", StaffData);
          StaffData.staffUN = staffUN;
          StaffData.ip = ip;

          const res = await axios.patch(`/staff/${StaffData._id}`, StaffData);
          // console.log("axios.patch response===>", res);
          if (res) {
            bindData();
            setNotify({ isOpen: true, message: "Updated Successfully", type: "success" });
          }
        } else {
          setNotify({ isOpen: true, message: "you do not have the right to edit!", type: "error" });
        }
      } catch (error) {
        console.error("error=====>", error);
        setNotify({ isOpen: true, message: error.message, type: "error" });
      }
    } else {
      //This is for Create new
      try {
        // console.log("axios Insert Start===>", StaffData);
        StaffData.staffUN = staffUN;
        StaffData.ip = ip;

        const res = await axios.post("/staff", StaffData);
        // console.log("axios Insert response===>", res);
        if (res) {
          bindData();
          setNotify({ isOpen: true, message: "Saved Successfully", type: "success" });
        }
      } catch (error) {
        console.error("error=====>", error);
        setNotify({ isOpen: true, message: error.response.data.message, type: "error" });
      }
    }
  };

  const openInPopup = (data) => {
    // console.log("openInPopup====>", data);

    // data.staffUN = staffUN;
    // data.ip = ip;
    setRecordForEdit(data);
    setOpenPopup(true);
    // setIsSaved(false);
  };

  const onDelete = async (_id) => {
    //if (window.confirm("Do you want to delete this record?")) {

    try {
      // console.log("axios.patch===>", Patient);
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      const res = await axios.delete(`/staff/${_id}`);
      if (res) {
        setNotify({ isOpen: true, message: "Deleted Successfully", type: "success" });
        bindData();
      }
    } catch (error) {
      console.error("error=====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }

    // setConfirmDialog({
    //   ...confirmDialog,
    //   isOpen: false,
    // });

    // console.log("onDelete ====>", id);
    // StaffServices.deleteStaff(id);
    // // setRecords(StaffServices.getAllStaff());
    // getStaffRecord();
    // setNotify({
    //   isOpen: true,
    //   message: "Deleted Successfully",
    //   type: "error",
    // });
  };

  const handleClickDelete = (data) => {
    if (isDeleteRight) {
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure to delete this record?",
        subtitle: "You can't undo this operation",
        isConfirm: () => {
          onDelete(data._id);
        },
      });
    } else {
      setNotify({ isOpen: true, message: "you do not have the right to delete", type: "error" });
    }
  };

  //setRecordForEdit(curObj.row);
  //setOpenPopup(true);

  const columns = [
    { field: "staffId", headerName: "StaffID", width: 90 },
    { field: "name", headerName: "Full name", width: 150, editable: true },
    { field: "fhName", headerName: "F/H Name", width: 150, editable: true },
    { field: "gender", headerName: "Gender", width: 80, editable: true },
    { field: "aadhaarNo", headerName: "AadhaarNo", width: 100, editable: true },
    
    { field: "desig", headerName: "Designation", width: 150, editable: true },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (curObj) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              <Controls.ActionButton color="primary" title="Edit" onClick={() => openInPopup(curObj.row)}>
                <ModeEditOutlineIcon />
              </Controls.ActionButton>
              <Controls.ActionButton color="red" title="Delete" onClick={() => handleClickDelete(curObj.row)}>
                <CloseIcon />
              </Controls.ActionButton>
            </Stack>
          </>
        );
      },
    },
    { field: "deptt", headerName: "Department", width: 250, editable: false },
  ];

  return (
    <>
      <PageHeader
        title="Staff Entry Form"
        subTitle="SubTitle here"
        icon={<PeopleOutlineRoundedIcon fontSize="large" color="primary" />}
      />

      <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
        <Controls.Button
          text="Add Staff"
          onClick={() => {
            setOpenPopup(true);
            setRecordForEdit(null);
            // setIsSaved(false);
          }}
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{ right: "15px" }}
          size="medium"
        />

        {/* <Controls.Button
          text="Edit Staff"
          onClick={() => {
            setOpenPopup(true);
            setRecordForEdit(null);
          }}
          variant="outlined"
          startIcon={<ModeEditOutlineIcon />}
          // sx={{ position: "absolute", right: "25px" }}
          size="medium"
        /> */}
      </Box>

      {/* <Toolbar sx={{ justifyContent: "flex-end" }}>
         <div>
       
        </div>
        <div>
       
         </div>
      </Toolbar> */}

      <Popup title="Staff Entry Form" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <StaffForm addOrEdit={addOrEdit} recordForEdit={recordForEdit} />
      </Popup>

      <br></br>
      {/* <Paper>
        {/* { records.map((item) => console.log(item.name) ) } * /} 
        */}

      <DataGrid heading="Staff Records" columns={columns} rows={records} pageSize={50} boxHeight={800} />

      <br></br>

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );

  // {
  //   field: "fullName",
  //   headerName: "Full name",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  // },
}
