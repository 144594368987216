import React from "react";
// import Input from "./Input";
import "dayjs/locale/en-in";
import { makeStyles } from "@mui/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import {
  Button as MuiButton,
  TextField,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  InputAdornment,
  Select,
  MenuItem,
  FormGroup,
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
  InputLabel,
  FormHelperText,
  Autocomplete as MuiAutocomplete,
  Box,
  Chip as MuiChip
} from "@mui/material";

// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const size = "small"; //small, medium
const variant = "outlined"; //outlined  standard

const Input = (props) => {
  const { label, name, value, onChange, type, required, error = null, ...other } = props;

  return (
    <TextField
      // components="label"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      fullWidth
      autoComplete={name}
      required={false || required}
      //variant="standard"
      variant={variant}
      size={size}
      type={type || "text"}
      // margin={'dense' | 'none'}
      {...other}
      //error
      //helperText="this is error"
      {...(error && { error: true, helperText: error })}
    />
  );
};

const DropdownList = (props) => {
  const { label, name, value, onChange, options, error = null, defaultValue, defaultText, ...other } = props;

  return (
    <FormControl fullWidth variant={variant} size={size} {...(error && { error: true })}>
      <InputLabel>{label}</InputLabel>
      <Select label={label} name={name} value={value} onChange={onChange} {...other} defaultValue={defaultValue || "0"}>
        <MenuItem disabled value={defaultValue || "0"}>
          {defaultText || "--select--"}
        </MenuItem>
        {/* <MenuItem disabled value="choose">Choose Option</MenuItem> */}
        {Array.isArray(options) &&
          options.map((data, index) => (
            <MenuItem key={index} value={data.val}>
              {data.text}
            </MenuItem>
          ))}
        {/* <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
          <MenuItem value="Transgender">Transgender</MenuItem> */}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>

    //    <FormControl fullWidth variant="standard">
    //         <InputLabel id="gender-select">Gender</InputLabel>
    //         <Select
    //           label="Gender"
    //           name="gender"
    //           labelId="gender-select"
    //           value={values.gender}
    //           onChange={handleChange}
    //         >
    //           <MenuItem value="Male">Male</MenuItem>
    //           <MenuItem value="Female">Female</MenuItem>
    //           <MenuItem value="Transgender">Transgender</MenuItem>
    //         </Select>
    //       </FormControl>
  );
};

const RadioGroup = (props) => {
  const { label, name, value, onChange, items } = props;

  return (
    <FormControl>
      <FormLabel> {label} </FormLabel>
      <MuiRadioGroup row name={name} value={value} onChange={onChange}>
        {items && items.map((data, index) => (
          <FormControlLabel key={index} value={data.val} control={<Radio />} label={data.text} />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};

const Checkbox = (props) => {
  const { label, name, value, onChange, items, ...others } = props;

  const convertEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <FormControl>
      <FormControlLabel
        control={
          <MuiCheckbox
            color="secondary"
            name={name}
            checked={value}
            onChange={(e) => onChange(convertEventPara(name, e.target.checked))}
            {...others}
          />
        }
        label={label}
      ></FormControlLabel>
    </FormControl>
  );
};

const DatePicker = (props) => {
  const { label, name, value, onChange, error = null, ...other } = props;

  const convertEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });
  return (
    <LocalizationProvider adapterLocale="en-in" dateAdapter={AdapterDayjs}>
      <MuiDatePicker
        label={label}
        name={name}
        value={value}
        onChange={(date) => onChange(convertEventPara(name, date))}
        renderInput={(params) => (
          <TextField {...params} size={size} error={error ? true : false} helperText={error ? error : ""} />
        )}
        {...other}
      />
    </LocalizationProvider>
  );

  // return (
  //   <LocalizationProvider
  //     adapterLocale="en-in"
  //     // dateFormats={'mm-dd-yyyy'}
  //     dateAdapter={AdapterDayjs}
  //   >
  //     <MuiDatePicker
  //       label={label}
  //       name={name}
  //       value={value}
  //       onChange={(date) => onChange(convertEventPara(name, date))}
  //       renderInput={(params) => (
  //         // <Input {...params} helperText={params?.inputProps?.placeholder} />
  //         <Input {...params} />
  //       )}
  //     />
  //   </LocalizationProvider>
  // );
};

const DateTimePicker = (props) => {
  const { label, name, value, onChange, error = null, ...other } = props;

  const convertEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });
  return (
    <LocalizationProvider adapterLocale="en-in" dateAdapter={AdapterDayjs}>
      <MuiDateTimePicker
        label={label}
        name={name}
        value={value}
        onChange={(date) => onChange(convertEventPara(name, date))}
        renderInput={(params) => (
          <TextField {...params} size={size} error={error ? true : false} helperText={error ? error : ""} />
        )}
        {...other}
      />
    </LocalizationProvider>
  );

  // return (
  //   <LocalizationProvider
  //     adapterLocale="en-in"
  //     // dateFormats={'mm-dd-yyyy'}
  //     dateAdapter={AdapterDayjs}
  //   >
  //     <MuiDatePicker
  //       label={label}
  //       name={name}
  //       value={value}
  //       onChange={(date) => onChange(convertEventPara(name, date))}
  //       renderInput={(params) => (
  //         // <Input {...params} helperText={params?.inputProps?.placeholder} />
  //         <Input {...params} />
  //       )}
  //     />
  //   </LocalizationProvider>
  // );
};

const Button = (props) => {
  const { text, size, color, variant, onClick, startIcon, endIcon, ...other } = props;

  // console.log("text ===>", text)

  return (
    <MuiButton
      size={size || "medium"} //"small" "medium" "large"
      color={color || "primary"}  //secondary
      variant={variant || "contained"}  //outlined
      onClick={onClick}
      startIcon={startIcon || ""}
      endIcon={endIcon || ""}
      {...other}
    >
      {text}
    </MuiButton>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0 + "!important",
    margin: theme.spacing(0.5) + "!important",
  },
  primary: {
    backgroundColor: "#caddf0!important",
    "& .MuiButton-label": {
      color: theme.palette.primary.main + "!important",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main + "!important",
    },
  },
  secondary: {
    // backgroundColor: theme.palette.secondary.light +'!important', #cdbbd0
    backgroundColor: "#cdbbd0!important",
    "& .MuiSvgIcon-label": {
      color: theme.palette.secondary.main + "!important",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.secondary.main + "!important",
    },
  },
  red: {
    backgroundColor: "#dda1c2!important",
    "& .MuiButton-label": {
      color: "#e2077f!important",
    },
    "& .MuiSvgIcon-root": {
      color: "#e2077f!important",
    },
  },
}));

const ActionButton = (props) => {
  const classes = useStyles();
  const { color, size, children, onClick, ...other } = props;

  return (
    <MuiButton
      className={`${classes.root} ${classes[color]}`}
      size={size || "small"} //"small" "medium" "large"
      //color={color || "primary"}
      //variant={variant || "contained"}
      onClick={onClick}
      //startIcon={startIcon || ""}
      //endIcon={endIcon || ""}
      {...other}
    >
      {children}
    </MuiButton>
  );
};

const Autocomplete = (props) => {
  //const [valuesCountry, setValuesCountry] = React.useState("India");
  const { label, name, value, onChange, options, error = null, ...other } = props;

  // console.log("error in Autocomplete ===>", error);

  const convertEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  return (
     
    <MuiAutocomplete
      // freeSolo
      fullWidth
      size={size}
      // autoHighlight
      // value={values.pAddress.country}
      value={value}
      // isOptionEqualToValue={}

      // isOptionEqualToValue={(option, value) => option.id === value}
      
      //Below line added on 25-08-2023       
      //  isOptionEqualToValue={(option, value) => option.value === value.value || option.value === value || option === value}
      //  isOptionEqualToValue={(option, value) => {console.log('option ===>', option); console.log('value ===>', value)}}

      {...other}
      // onChange={onChange}
      onChange={(e, val) => onChange(convertEventPara(name, val))}
      // getOptionLabel={(option) =>option.title ||  option.text || option.label || option.feeHead || option || ""}
      getOptionLabel={(option) => option.title || option.text || option.label || option || ""}
      options={options}
      // renderInput={(params) => <TextField {...params} label={label} />}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          // {...(error && <FormHelperText>{error}</FormHelperText>)}
          error={error ? true : false}
          helperText={error ? error : ""}
        />
      )}
    />
       
      //catch(){}
  );
};

const AutocompleteTag = (props) => {
  //const [valuesCountry, setValuesCountry] = React.useState("India");
  const { label, name, value, onChange, options, error = null, ...other } = props;

  // console.log("error in Autocomplete ===>", error);

  const convertEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <MuiAutocomplete
      multiple
      fullWidth
      size={size}
      // id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      value={value}
      // onChange={onChange}
      onChange={(e, val) => onChange(convertEventPara(name, val))}
      getOptionLabel={(item) => item}
      renderOption={(props, item1, { selected }) => (
        <li {...props}>
          <MuiCheckbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {item1}
        </li>
      )}
      // style={{ width: 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          // label="Checkboxes"
          placeholder="Select"
          label={label}
          // {...(error && <FormHelperText>{error}</FormHelperText>)}
          error={error ? true : false}
          helperText={error ? error : ""}
        />
      )}
    />
  );

  // <MuiAutocomplete
  //   // name={name}
  //   disablePortal
  //   id="combo-box-demo"
  //   options={options}
  //   // sx={{ width: 300 }  ['India', 'Pakistan']}
  //   {...other}
  //   //{error && <FormHelperText>{error}</FormHelperText>}
  //   renderInput={(params) => <TextField {...params} label={label}  />}
  // />);

  // const [value, setValue] = React.useState(options[0]);
  // const [inputValue, setInputValue] = React.useState('');

  // <MuiAutocomplete
  // name={name}
  //value={value}
  // getOptionSelected = (option, value) => option === value
  // getOptionSelected={(option, value) => option.id === value.id}
  //getOptionSelected={(option, value) => option.id === value}
  // isOptionEqualToValue={(option, value) => option.id === value}
  // isOptionEqualToValue={(option, value) => option.value === value.value}
  // getOptionSelected={(option, value) => option.value === value.value}
  // onChange={onChange}
  // onChange={(event, newValue) => {
  //   setValue(newValue);
  // }}

  // inputValue={inputValue}
  // onInputChange={(event, newInputValue) => {
  //   setInputValue(newInputValue);
  // }}
  //   id="controllable-states-demo"
  //   options={options}

  //   renderInput={(params) => <TextField {...params} label="Controllable" />}
  // />
  // );
};



const Chip = (props) => { 
  const {label, color, size, startIcon, ...other } = props;
  return (
    <MuiChip 
    sx={{fontSize:18}}
    label={"₹" + label}
    color={color || "primary"}  //secondary, primary
    variant={variant || "outlined"}  //"contained"
    size={size || "large"} //"small" "medium" "large"
    icon={startIcon || ""}
    // {...other}
    />
       //example:-
    //<Chip avatar={<Avatar>M</Avatar>} label="Avatar" />

//  <Chip
//   avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
//   label="Avatar"
//   variant="outlined"
// />
 
  );
};

const Controls = {
  Input,
  DropdownList,
  RadioGroup,
  Checkbox,
  DatePicker,
  DateTimePicker,
  Button,
  ActionButton,
  Autocomplete,
  AutocompleteTag,
  Chip
};

export default Controls;
