import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { DialogActions, DialogContent, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Controls from "./Controls";
import { NotListedLocation } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2) + "!important",
    position: "absolute!important",
    top: theme.spacing(10) + "!important",
  },
  dialogContent: {
    textAlign: "center !important",
  },
  dialogAction: {
    justifyContent: "center !important",
  },
  dialogTitle: {
    textAlign: "center !important",
  },
  titleIcon: {
    // backgroundColor: theme.palette.secondary.light + "!important",
    backgroundColor: '#dda1c2!important',
    color: theme.palette.secondary.main + "!important",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light + "!important",
      cursor: "default !important",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem !important",
      color:'#e2077f!important',
    },
  },

  btnRed:{
    backgroundColor: '#e2077f!important',
    '& .MuiButton-label':{
      color:'#e2077f!important',
    },
    '& .MuiSvgIcon-root':{
      color:'#e2077f!important',
    }
  }
}));

export default function ConfirmDialog(props) {
  const classes = useStyles();

  const { confirmDialog, setConfirmDialog } = props;

  return (
    <div>
      <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }} maxWidth="lg">
        <DialogTitle className={classes.dialogTitle}>
          <IconButton disableRipple className={classes.titleIcon}>
            <NotListedLocation />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="h6">{confirmDialog.title}</Typography>
          <Typography variant="subtitle2">{confirmDialog.subtitle}</Typography>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Controls.Button text="No" color="primary"
            onClick={() => {
              setConfirmDialog({ ...confirmDialog, isOpen: false });
            }}
          />
          <Controls.Button text="Yes" className={classes.btnRed} onClick={confirmDialog.isConfirm} />
        </DialogActions>
      </Dialog>
    </div>
  );
}
