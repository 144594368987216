import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import StaffRoles from "../HRM/StaffRoles";
import OfficeStaffSecurity from "./OfficeStaffSecurity";
 
// import FeeHeadSub from "./FeeHeadSub";
// import FeeHeads_Import from "./FeeHeads_Import";
import { Container } from "@mui/system";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <Typography> </Typography> */}
            {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserSecurity_Tabs() {
  // export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs" variant="scrollable"  scrollButtons allowScrollButtonsMobile>
          <Tab label="Office-Staff-Security" {...a11yProps(0)} />
          <Tab label="Staff-Roles" {...a11yProps(1)} />
          {/* <Tab label="Lab-Test-Import" {...a11yProps(2)} /> */}
          {/* <Tab label="...." {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <OfficeStaffSecurity/>
      </TabPanel>
      <TabPanel value={value} index={1}> 
       <StaffRoles/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        ......
      </TabPanel>
    </Box>
    
  );
}
