import "./LabReport.module.css";
import mystyles from "./LabReport.module.css";
import React, { useEffect, useRef, useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import Controls from "../../components/Controls";
import dayjs from "dayjs";
import Barcode from "../../components/Barcode";

import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
import LabReportPrint_InnerTable from "./LabReportPrint_InnerTable";
import { IndeterminateCheckBox } from "@mui/icons-material";
import { clear } from "@testing-library/user-event/dist/clear";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { HtmlToWord } from "../../utils/HtmlToWord";

export default function LabReportPrint(props) {
  // const dispatch = useDispatch();
  const hospitalHeader = useSelector((state) => state.HospitalHeader_Reducer);
  const labHeader = useSelector((state) => state.LabHeader_Reducer);
  const [isShowHeader, setIsShowHeader] = useState(true);

  const [pageTopMargin, setPageTopMargin] = useState("1cm");

  // console.log("hospitalHeader ====>", hospitalHeader);
  // const {printForm} = props;
  // console.log("printForm props ====>", props);

  const { isPrintDirect, setIsPrintDirect } = props;
  // console.log("billHeading ====>", billHeading);

  // const ChangeTopMargin = () => {
  //   // return  `<style> @page {margin-top: ${pageTopMargin}cm;} </style>`
  //   return `@page {marginTop: ${pageTopMargin};}`;
  // };

  const {
    labResultNo,
    mrNo,
    iopNo,
    name,
    age,
    gender,
    pMobNo,
    email,
    billNo,
    billDate,
    refBy,
    depttName,
    // diet  Type,
    // mainHeading,
    // subHeading,
    sampleDate,
    reportDate,
    isResultAdded,
    // testList,
    staffUN,
  } = props.printForm[0];

  const arrTableData = props.printForm;
  // const { pMobNo } = props.ref_reg_patient.mobileNo;
  // const { opdNo, opdDate, billNo, staffUN, consultant, paidRs, paymentMode, testName, testList, } = props;

  // const [totalRate, setTotalRate] = useState(0);
  // const [totalDisc, setTotalDisc] = useState(0);
  // const [totalAmount, setTotalAmount] = useState(0);

  const handleClose = (value) => {
    setIsPrintDirect && setIsPrintDirect(false);
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    pageStyle: `@media print { 
      @page {        
        size: A4 !important;
        margin-top: ${pageTopMargin} !important;   
        margin-bottom: 2cm !important;
        margin-left: 2cm !important;
      }
      .lab-report-footer-barcode11111111{
        width: 100% !important;
        position: fixed !important;
        bottom: 0 !important;
        text-align: center !important;                 
      }
      .lab-incharge-align{
        text-align: left !important;
        font-weight: bold !important;
        font-size: 16px !important;
      }
      // tfoot{display:table-row-group !important;}
    }
    // @page:last {
    //   .lab-report-footer-barcode {        
    //     position: absolute !important;
    //     bottom: 0 !important;
    //     background-color: blue !important;
    //   }
    }    
    `,
    content: () => componentRef.current,
    documentTitle: ".",
    onPrintError: (error) => console.log(error),
    // removeAfterPrint: true,

    // copyStyles: true,

    // print: async (printIframe) => {
    //   const document = printIframe.contentDocument;
    //   if (document) {
    //     const html = document.getElementsByTagName("html")[0];
    //     console.log(html);
    //     await html2pdf().from(html).save();
    //     // const exporter = new Html2Pdf(html);
    //     // await exporter.getPdf(true);
    //   }
    // },
  });

  //@bottom-center { content: 'Page ' counter(page) !important;}

  // const getPageMargins = () => {
  //   return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
  // };

  useEffect(() => {
    // isPrintDirect && handlePrint();
    handleClose();
    // TableBodyRow();
  }, [isPrintDirect]);

  // useEffect(() => {
  //   if (Array.isArray(testList)) {
  //     let conRs =0.00;
  //       testList.map((item, index) => {
  //         // conRs= parseFloat(conRs) + parseFloat(item.discAmt);
  //         // console.log("item.discAmt: ========>", conRs)
  //     });

  //     conRs = conRs.toFixed(2);
  //     // setTotalDisc(conRs);
  //   } id="tblDirectBilling"
  // }, []);

  // const TableBodyRow = () => {
  //   {
  //     Array.isArray(arrTableData) &&
  //       arrTableData.map((row, ind) => {
  //         // const { testList, mainHeading, subHeading } = row;
  //         {
  //           <LabReportPrint_InnerTable row={row} />;
  //         }
  //       });
  //   }
  // };

  let tableHeaderValue = "";
  let isPrintTableHeader = true;

  const convertHtmlToDocx = () => {
    //var htmlData = document.getElementById(element).innerHTML;
    var htmlData = componentRef.current.innerHTML;
    HtmlToWord(htmlData, "Lab-Report.docx");
  };

  // const numberOfPage = Math.ceil(height / PAGE_HEIGHT);
  //   const numberOfPage = Math.ceil(500 / 100);

  //   let requiredHeight = heightWithSingleHeader
  // if (numberOfPage > 1) {
  //     const headerHeight = printElement.getElementsByTagName("thead")?.[0]?.clientHeight || 0
  //     const footerHeight = printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight || 0
  //     requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight)
  // }

  return (
    // <h1>{props.opdNo}</h1>
    <>
      {/* Table for Top-Buttons */}
      <div style={{ position: "fixed", top: "100px", left: "35%", width: "30%" }}>
        {/* <button onClick={()=>convertHtmlToDocx('divHtmlPrint')}>Export as docx</button> */}
        {/* Table for Top-Buttons */}
        <table style={{ width: "100%" }}>
          <tr>
            <td>
              <Controls.ActionButton color="primary" title="Export to MS-Word" onClick={convertHtmlToDocx}>
                <FileDownloadIcon />
              </Controls.ActionButton>
            </td>
            <td>
              <Controls.Checkbox
                label="Show-Header"
                name="ShowHeader"
                value={isShowHeader}
                onChange={(e) => {
                  setIsShowHeader(e.target.value);
                }}
                // error={errors.zeroBilling}
              />

              {/* <Controls.Button
                text="Change Color" 
                onClick={(e) => {
                  const color = getComputedStyle(document.documentElement).getPropertyValue("--color-logo");
                  document.documentElement.style.setProperty("--color-logo", color == "red" ? "blue" : "red");
                }} /> */}
            </td>
            <td style={{ width: "30%" }}>
              <Controls.DropdownList
                label="Page-Top-Margin"
                name="PageTopMargin"
                // width={"200px"}
                value={pageTopMargin}
                //handleChange
                onChange={(e) => {
                  // console.log("aaaaaaaaaaaaaa", e.target.value);
                  setPageTopMargin(e.target.value);
                  //document.documentElement.style.setProperty('--color-logo', 'red');
                }}
                options={[
                  { text: "1cm", val: "1cm" },
                  { text: "2cm", val: "2cm" },
                  { text: "3cm", val: "3cm" },
                  { text: "4cm", val: "4cm" },
                  { text: "5cm", val: "5cm" },
                ]}
                // defaultValue="1"
              />
            </td>
            <td>
              <Controls.ActionButton color="primary" title="Print Lab Report" onClick={handlePrint}>
                <PrintIcon />
              </Controls.ActionButton>
            </td>
          </tr>
        </table>

        {/* <Controls.Button text="bbbbbbbb" variant="outlined" title="Print Lab Report" onClick={handlePrint}>
           Print
        </Controls.Button> */}
      </div>

      <div
        style={{
          display: "table",
          marginLeft: "auto",
          marginRight: "auto",
          height: "inherit",
        }}
        id="divHtmlPrint"
        ref={componentRef}
      >
        {/* Table for Page Header */}
        {isShowHeader && (
          <table
            style={{
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              fontWeight: "bold",
              border: "1px solid #000000",
            }}
          >
            <tbody>
              <tr>
                {/* borderRight: "1px solid #000000" */}
                <td style={{ width: "45%", textAlign: "center" }}>
                  <div>
                    <span style={{ fontSize: "18px" }}> {labHeader.labName} </span>
                  </div>
                  <div> {labHeader.labAddress} </div>
                  <div> {labHeader.labAddress2} </div>
                  <div> {labHeader.labPhoneNo} </div>
                </td>
                <td style={{ width: "10%" }}>
                  <div style={{ padding: "10px" }}>
                    <img src="logo192.png" alt="Header Image" width={150} height={100} />
                  </div>
                </td>
                <td style={{ width: "45%", textAlign: "center" }}>
                  <div>
                    <span style={{ fontSize: "18px" }}> {hospitalHeader.hospitalName} </span>
                  </div>
                  <div> {hospitalHeader.hospitalAddress} </div>
                  <div> {hospitalHeader.hospitalAddress2} </div>
                  <div> {hospitalHeader.hospitalPhoneNo} </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {/* Main Table for Report */}
        <table
          id="labMainTable"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            height: "100%",
            fontSize:"14px"
          }}
        >
          <thead style={{ verticalAlign: "top" }}>
            <tr style={{ height: "190px" }}>
              <td>
                <div
                  style={{
                    width: "100%",
                    border: "0",
                  }}
                >
                  {/* Table for Patient Detail ie Name... */}
                  <table
                    style={{
                      width: "100%",
                      border: "1px solid #b3adad",
                      backgroundColor: "transparent",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop:"5px"
                    }}
                  >
                    <tbody>
                      <tr>
                        <td style={{ width: "15%" }}>&nbsp; Name</td>
                        <td style={{ width: "35%",  }}>: {name} </td>
                        <td style={{ width: "15%" }}>MRNo.:</td>
                        <td style={{ width: "35%",  }}>: {mrNo}</td>
                      </tr>
                      <tr>
                        <td>&nbsp; Age/Sex</td>
                        <td>
                          : {age} / {gender}
                        </td>
                        <td>BillNo/BillDate</td>
                        <td>
                          : {billNo} ({dayjs(billDate).format("DD/MMM/YYYY")})
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp; Lab. No. </td>
                        <td>: {labResultNo}</td>
                        <td> Collected on </td>
                        <td>: {dayjs(sampleDate).format("DD/MMM/YYYY hh:mm a")}</td>
                      </tr>
                      <tr>
                        <td>&nbsp; OPD/IPD No. </td>
                        <td>: {iopNo} </td>
                        <td> Reported on </td>
                        <td>: {dayjs(reportDate).format("DD/MMM/YYYY hh:mm a")} </td>
                      </tr>
                      <tr>
                        <td>&nbsp; Mobile No. </td>
                        <td>: {pMobNo}</td>
                        <td> Reffered By </td>
                        <td>: {refBy} </td>
                      </tr>
                    </tbody>
                    {/* <tfoot></tfoot> */}
                  </table>

                  {/* Table for Test header ie TestName, Result, BiologicalRange */}
                  <table
                    style={{
                      width: "100%",
                      border: "1px solid #b3adad",
                      backgroundColor: "#f0f0f0",
                      marginTop: "15px",
                      marginBottom: "10px",
                    }}
                  >
                    <tbody>
                      <tr style={{ fontWeight: "bold" }}>
                        <td style={{ width: "50%", textAlign: "center" }}> Test Name </td>
                        <td style={{ width: "25%", textAlign: "center" }}>
                          <div>
                            <table style={{ width: "100%", textAlign: "center" }}>
                              {/* <tr>
                                      <td colSpan={2}> Results</td>
                                    </tr> */}
                              <tr>
                                <td>IN RANGE </td> <td>OUT RANGE </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                        <td style={{ width: "25%", textAlign: "center" }}> Biological Ref. Range </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* <div style={{clear:"both"}}></div> */}
              </td>
            </tr>
          </thead>
          <tbody style={{ height: "auto", display: "table", tableLayout: "fixed" }}>
            <tr>
              <td>
                {/*Table for Report Body Content */}
                <div
                  style={{
                    width: "850px",
                    backgroundColor: "white",
                    border: "0",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div style={{ padding: "0px" }}>
                    {Array.isArray(arrTableData) &&
                      arrTableData.map((row, index) => {
                        if (tableHeaderValue === row.mainHeading) {
                          isPrintTableHeader = false;
                        } else {
                          tableHeaderValue = row.mainHeading;
                          isPrintTableHeader = true;
                        }
                        //row.map((item, idx) => {
                        return (
                          <LabReportPrint_InnerTable {...row} key={index} isPrintTableHeader={isPrintTableHeader} />
                        );
                        // })
                        // const { testList, mainHeading, subHeading } = row; key={row._id}
                      })}
                  </div>
                  <div style={{ margin: "30px", textAlign: "center" }}>
                    * * * * * * * * * * * * * * * * End of Report * * * * * * * * * * * * * * * *
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr style={{ height: "100px" }}>
              <td>
                {/* Page Footer */}
                {/* <div className="center">
                <Barcode value='Lab-23005'/>
                </div>            */}
                {/* <div>
                  <div style={{ textAlign: "right", marginRight: "50px", fontSize: "11px" }}>
            <span style={{ fontWeight: "normal" }}> MRNo.: </span> {mrNo},
            <span style={{ fontWeight: "normal" }}> Name: </span> {name}
          </div> */}
                {/* <div className="mystyles.pagenumber"></div> */}
                {/* <div className="lab-report-footer-barcode"> */}
                <div>
                  <div style={{ textAlign: "center" }}>
                    <Barcode value={labResultNo} />
                  </div>
                  <div className="lab-incharge-align" style={{ textAlign: "left" }}> 
                    Lab Incharge
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>

        {/* <div style={{ paddingTop: "120px" }}>
          <h3> Lab Incharge </h3>
        </div> */}

        {/* <div className={mystyles.Applogo}>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAA      </div> */}

        {/* <div class="footer">    
    <div align="center">Page <span class="pagenumber"/> of <span class="pagecount"/></div>*/}

        {/* <div className="lab-report-footer-barcode222">
          <div className="lab-incharge-align" style={{ textAlign: "left" }}> Lab Incharge </div>
          <div style={{ textAlign: "center" }}>
            <Barcode value={labResultNo} />
          </div>
        </div>*/}
      </div>
    </>
  );
}
