export default function TableForPrint(props) {
  //   console.log("DDDDDDDDDDDDDDDDD====", props);

  //   console.log("AAAAAAAAAAAAAAAAAAAAAAAAA====",props); id="tbl lab-report-tblOnOnePageDirectBilling"
  const { testList, mainHeading, subHeading, tableType, isPrintTableHeader } = props;
  // return;

  let widalTestName = "";

  let semen1_table6 = testList.filter((item) => item.columnNo == "6");
  let semen1_table7 = testList.filter((item) => item.columnNo == "7");

  const renderElement = () => {
    if (tableType == "GENERAL")
      return (
        <table className="tbl lab-report-tblOnOnePage" style={{ fontWeight: "bold" }}>
          <thead>
            {isPrintTableHeader && (
              <tr>
                <th colSpan={3} style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px" }}>
                  {mainHeading}
                </th>
              </tr>
            )}
          </thead>
          <tbody>
            {testList.length > 1 && (
              <tr>
                <td
                  colSpan={3}
                  style={{ textAlign: "left", paddingLeft: "20px", fontWeight: "bold", fontSize: "16px" }}
                >
                  {subHeading}
                </td>
              </tr>
            )}
            {/* {Array.isArray(testList) && */}
            {testList.map((item, index) => (
              //testName, normalValue, unit, result
              <tr key={index + 1}>
                {/* <td>{index + 1}</td> */}
                <td style={{ textAlign: "left", width: "50%", fontSize: "14px" }}>{item.testName}</td>
                <td className="twoColorInTableTd" style={{ textAlign: "center", width: "25%", padding:"0px" }}>
                  {CheckAndBoldValue(item.result, item.normalValue)}
                </td>
                <td style={{ textAlign: "center", width: "25%" }}>
                  {item.normalValue} {item.unit}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot></tfoot>
        </table>
      );
    else if (tableType == "URINE")
      return (
        <table className="tbl lab-report-tblOnOnePage">
          <thead>
            <tr>
              <th colSpan={3} style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px" }}>
                {mainHeading}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={3} style={{ textAlign: "left", paddingLeft: "20px", fontWeight: "bold", fontSize: "16px" }}>
                {subHeading}
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "top", width: "34%" }}>
                <table style={{ textAlign: "left", width: "100%" }}>
                  <thead>
                    <tr>
                      <th>PHYSICAL EXAMINATION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testList.map(
                      (item, index) =>
                        //testName, normalValue, unit, result
                        item.columnNo == "1" && (
                          <tr key={index + 1}>
                            {/* <td>{index + 1}</td> */}
                            <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                              {item.testName} {": "} <strong>{item.result}</strong> {item.unit}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </td>
              <td style={{ verticalAlign: "top", width: "33%" }}>
                <table style={{ textAlign: "left", width: "100%" }}>
                  <thead>
                    <tr>
                      <th>CHEMICAL EXAMINATION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testList.map(
                      (item, index) =>
                        //testName, normalValue, unit, result
                        item.columnNo == "2" && (
                          <tr key={index + 1}>
                            {/* <td>{index + 1}</td> */}
                            <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                              {item.testName} {": "} <strong>{item.result}</strong> {item.unit}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </td>
              <td style={{ verticalAlign: "top", width: "33%" }}>
                <table style={{ textAlign: "left", width: "100%" }}>
                  <thead>
                    <tr>
                      <th>MICROSCOPIC EXAMINATION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testList.map(
                      (item, index) =>
                        //testName, normalValue, unit, result
                        item.columnNo == "3" && (
                          <tr key={index + 1}>
                            {/* <td>{index + 1}</td> */}
                            <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                              {item.testName} {": "} <strong> {item.result}</strong> {item.unit}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </td>
            </tr>

            {/* {Array.isArray(testList) && */}
          </tbody>
          <tfoot></tfoot>
        </table>
      );
    else if (tableType == "BFA")
      return (
        <table className="tbl lab-report-tblOnOnePage">
          <thead>
            <tr>
              <th colSpan={3} style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px" }}>
                {mainHeading}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={3} style={{ textAlign: "left", paddingLeft: "20px", fontWeight: "bold", fontSize: "16px" }}>
                {subHeading}
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "top", width: "34%" }}>
                <table style={{ textAlign: "left", width: "100%" }}>
                  <thead>
                    <tr>
                      <th>PHYSICAL EXAMINATION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testList.map(
                      (item, index) =>
                        //testName, normalValue, unit, result
                        item.columnNo == "1" && (
                          <tr key={index + 1}>
                            {/* <td>{index + 1}</td> */}
                            <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                              {item.testName} {": "} <strong>{item.result}</strong> {item.unit}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </td>
              <td style={{ verticalAlign: "top", width: "33%" }}>
                <table style={{ textAlign: "left", width: "100%" }}>
                  <thead>
                    <tr>
                      <th>BIOCHEMICAL EXAMINATION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testList.map(
                      (item, index) =>
                        //testName, normalValue, unit, result
                        item.columnNo == "2" && (
                          <tr key={index + 1}>
                            {/* <td>{index + 1}</td> */}
                            <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                              {item.testName} {": "} <strong>{item.result}</strong> {item.unit}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </td>
              <td style={{ verticalAlign: "top", width: "33%" }}>
                <table style={{ textAlign: "left", width: "100%" }}>
                  <thead>
                    <tr>
                      <th>MICROSCOPIC EXAMINATION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testList.map(
                      (item, index) =>
                        //testName, normalValue, unit, result
                        item.columnNo == "3" && (
                          <tr key={index + 1}>
                            {/* <td>{index + 1}</td> */}
                            <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                              {item.testName} {": "} <strong> {item.result}</strong> {item.unit}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </td>
            </tr>

            {/* {Array.isArray(testList) && */}
          </tbody>
          <tfoot></tfoot>
        </table>
      );
    else if (tableType == "WIDAL")
      return (
        <table className="tbl lab-report-tblOnOnePage" style={{ marginTop: "20px" }}>
          <thead>
            <tr>
              <th colSpan={7} style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px" }}>
                {mainHeading}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={7} style={{ textAlign: "left", paddingLeft: "20px", fontWeight: "bold", fontSize: "16px" }}>
                {subHeading}
              </td>
            </tr>
            <tr style={{ backgroundColor: "silver" }}>
              <td> Dilution </td> <td> 1:20 </td> <td> 1:40 </td> <td> 1:80 </td> <td> 1:160 </td> <td> 1:320 </td>
              <td> 1:640 </td>
            </tr>

            <tr>
              <td>"O"</td>
              {testList.map(
                (item, index) =>
                  item.columnNo == "1" && <td style={{ textAlign: "center", fontWeight: "bold" }}>{item.result}</td>
              )}
            </tr>

            <tr>
              <td>"H"</td>
              {testList.map(
                (item, index) =>
                  item.columnNo == "2" && <td style={{ textAlign: "center", fontWeight: "bold" }}>{item.result}</td>
              )}
            </tr>

            <tr>
              <td>"AH"</td>
              {testList.map(
                (item, index) =>
                  item.columnNo == "3" && <td style={{ textAlign: "center", fontWeight: "bold" }}>{item.result}</td>
              )}
            </tr>

            <tr>
              <td>"BH"</td>
              {testList.map(
                (item, index) =>
                  item.columnNo == "4" && <td style={{ textAlign: "center", fontWeight: "bold" }}>{item.result}</td>
              )}
            </tr>

            <tr>
              <td>Result</td>
              {testList.map(
                (item, index) =>
                  item.columnNo == "5" && (
                    <td colSpan={6} style={{ textAlign: "left", paddingLeft: "20px", fontWeight: "bold" }}>
                      {item.result}
                    </td>
                  )
              )}
            </tr>

            {/* {Array.isArray(testList) && */}
          </tbody>
          <tfoot></tfoot>
        </table>
      );
    else if (tableType == "SEMEN-1")
      return (
        <table className="tbl lab-report-tblOnOnePage">
          <thead>
            <tr>
              <th colSpan={3} style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px" }}>
                {mainHeading}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={3} style={{ textAlign: "left", paddingLeft: "20px", fontWeight: "bold", fontSize: "16px" }}>
                {subHeading}
              </td>
            </tr>

            <tr>
              <td colSpan={3} style={{ textAlign: "left", paddingLeft: "40px", fontWeight: "bold" }}>
                <table style={{ textAlign: "left", width: "100%" }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "left", width: "50%", borderColor: "white", verticalAlign: "top" }}>
                        <table style={{ textAlign: "left", width: "100%" }}>
                          <thead></thead>
                          <tbody>
                            {testList.map(
                              (item, index) =>
                                //testName, normalValue, unit, result
                                item.columnNo == "1" && (
                                  <tr key={index + 1}>
                                    {/* <td>{index + 1}</td> */}
                                    <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                                      {item.testName} {": "} <strong>{item.result}</strong> {item.unit}
                                    </td>
                                  </tr>
                                )
                            )}
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                      </td>
                      <td style={{ textAlign: "left", width: "50%", borderColor: "white", verticalAlign: "top" }}>
                        <table style={{ textAlign: "left", width: "100%" }}>
                          <thead></thead>
                          <tbody>
                            {testList.map(
                              (item, index) =>
                                //testName, normalValue, unit, result
                                item.columnNo == "2" && (
                                  <tr key={index + 1}>
                                    {/* <td>{index + 1}</td> */}
                                    <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                                      {item.testName} {": "} <strong>{item.result}</strong> {item.unit}
                                    </td>
                                  </tr>
                                )
                            )}
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </td>
            </tr>

            <tr>
              <td style={{ verticalAlign: "top", width: "34%" }}>
                <table style={{ textAlign: "left", width: "100%" }}>
                  <thead>
                    <tr>
                      <th>PHYSICAL EXAMINATION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testList.map(
                      (item, index) =>
                        //testName, normalValue, unit, result
                        item.columnNo == "3" && (
                          <tr key={index + 1}>
                            {/* <td>{index + 1}</td> */}
                            <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                              {item.testName} {": "} <strong>{item.result}</strong> {item.unit}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </td>
              <td style={{ verticalAlign: "top", width: "33%" }}>
                <table style={{ textAlign: "left", width: "100%" }}>
                  <thead>
                    <tr>
                      <th>CHEMICAL EXAMINATION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testList.map(
                      (item, index) =>
                        //testName, normalValue, unit, result
                        item.columnNo == "4" && (
                          <tr key={index + 1}>
                            {/* <td>{index + 1}</td> */}
                            <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                              {item.testName} {": "} <strong>{item.result}</strong> {item.unit}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </td>
              <td style={{ verticalAlign: "top", width: "33%" }}>
                <table style={{ textAlign: "left", width: "100%" }}>
                  <thead>
                    <tr>
                      <th>MICROSCOPIC EXAMINATION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testList.map(
                      (item, index) =>
                        //testName, normalValue, unit, result
                        item.columnNo == "5" && (
                          <tr key={index + 1}>
                            {/* <td>{index + 1}</td> */}
                            <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                              {item.testName} {": "} <strong> {item.result}</strong> {item.unit}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={{ textAlign: "left", fontWeight: "bold" }}>
                <table style={{ textAlign: "left", width: "90%", margin: "auto" }}>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left", width: "60%" }}>
                        Nature of Motility acc to W.H.O. (McLeod Grade)
                      </th>
                      <th style={{ textAlign: "center", width: "20%" }}>After 1/2 hrs</th>
                      <th style={{ textAlign: "center", width: "20%" }}>After 2 hrs</th>
                    </tr>
                  </thead>
                  <tbody>
                    {semen1_table6.map((item, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "left", borderColor: "white" }}>{item.testName} </td>
                        <td style={{ textAlign: "center", borderColor: "white" }}>
                          <strong>{item.result}</strong> {item.unit}
                        </td>
                        <td style={{ textAlign: "center", borderColor: "white" }}>{semen1_table7[index].result}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
      );
    else if (tableType == "SEMEN-2")
      return (
        <>
          <table className="tbl lab-report-tblOnOnePage">
            <thead>
              <tr>
                <th colSpan={4} style={{ textAlign: "center", fontWeight: "bold", fontSize: "16px" }}>
                  {mainHeading}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  colSpan={4}
                  style={{ textAlign: "left", paddingLeft: "20px", fontWeight: "bold", fontSize: "16px" }}
                >
                  {subHeading}
                </td>
              </tr>
              <tr>
                <td style={{ verticalAlign: "top", width: "25%" }}>
                  <table style={{ textAlign: "left", width: "100%" }}>
                    <thead>
                      <tr>
                        <th>AB. OF HEAD</th>
                      </tr>
                    </thead>
                    <tbody>
                      {testList.map(
                        (item, index) =>
                          item.columnNo == "1" && (
                            <tr key={index + 1}>
                              <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                                {item.testName} {": "} <strong>{item.result}</strong> {item.unit}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </td>
                <td style={{ verticalAlign: "top", width: "25%" }}>
                  <table style={{ textAlign: "left", width: "100%" }}>
                    <thead>
                      <tr>
                        <th>AB. OF NECK</th>
                      </tr>
                    </thead>
                    <tbody>
                      {testList.map(
                        (item, index) =>
                          item.columnNo == "2" && (
                            <tr key={index + 1}>
                              <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                                {item.testName} {": "} <strong>{item.result}</strong> {item.unit}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </td>
                <td style={{ verticalAlign: "top", width: "25%" }}>
                  <table style={{ textAlign: "left", width: "100%" }}>
                    <thead>
                      <tr>
                        <th>AB. OF TAIL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {testList.map(
                        (item, index) =>
                          //testName, normalValue, unit, result
                          item.columnNo == "3" && (
                            <tr key={index + 1}>
                              {/* <td>{index + 1}</td> */}
                              <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                                {item.testName} {": "} <strong> {item.result}</strong> {item.unit}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </td>
                <td style={{ verticalAlign: "top", width: "25%" }}>
                  <table style={{ textAlign: "left", width: "100%" }}>
                    <thead>
                      <tr>
                        <th>MISCELLANEOUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {testList.map(
                        (item, index) =>
                          item.columnNo == "4" && (
                            <tr key={index + 1}>
                              <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                                {item.testName} {": "} <strong> {item.result}</strong> {item.unit}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>

          {/* <table className="tbl lab-report-tblOnOnePage">
            <thead></thead>
            <tbody>
              <tr>
                <td style={{ textAlign: "left", fontWeight: "bold" }}> */}
          <div style={{ margin: "30px" }}>
            {testList.map(
              (item, index) =>
                item.columnNo == "5" && (
                  <div
                    key={index + 1}
                    style={{ textAlign: "left", paddingLeft: "50px", width: "100%", borderColor: "white" }}
                  >
                    {item.testName} {": "} <strong> {item.result}</strong> {item.unit}
                  </div>
                )
            )}
          </div>
          {/* </td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table> */}

          <table className="tbl lab-report-tblOnOnePage" style={{ textAlign: "left", width: "90%", margin: "auto", marginTop: "30px" }}>
            <thead>
              <tr>
                <th colSpan={2} style={{ textAlign: "center", width: "100%" }}>
                  CELLULAR ELEMENTS
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ borderColor: "white" }}>
                  <table style={{ textAlign: "left", width: "100%" }}>
                    <thead></thead>
                    <tbody>
                      {testList.map(
                        (item, index) =>
                          item.columnNo == "6" && (
                            <tr key={index + 1}>
                              <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                                {item.testName} {": "} <strong> {item.result}</strong> {item.unit}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </td>
                <td style={{ borderColor: "white" }}>
                  <table style={{ textAlign: "left", width: "100%" }}>
                    <thead></thead>
                    <tbody>
                      {testList.map(
                        (item, index) =>
                          item.columnNo == "7" && (
                            <tr key={index + 1}>
                              <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                                {item.testName} {": "} <strong> {item.result}</strong> {item.unit}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>

          <table className="tbl lab-report-tblOnOnePage" style={{ textAlign: "left", width: "90%", margin: "auto", marginTop: "30px" }}>
            <thead>
              <tr>
                <th colSpan={2} style={{ textAlign: "center", width: "100%" }}>
                  MISCELLANEOUS CHARACTERS & BACTERIOLOGICAL EXAMINATION
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ borderColor: "white" }}>
                  <table style={{ textAlign: "left", width: "100%" }}>
                    <thead></thead>
                    <tbody>
                      {testList.map(
                        (item, index) =>
                          item.columnNo == "8" && (
                            <tr key={index + 1}>
                              <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                                {item.testName} {": "} <strong> {item.result}</strong> {item.unit}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </td>
                <td style={{ borderColor: "white" }}>
                  <table style={{ textAlign: "left", width: "100%" }}>
                    <thead></thead>
                    <tbody>
                      {testList.map(
                        (item, index) =>
                          item.columnNo == "9" && (
                            <tr key={index + 1}>
                              <td style={{ textAlign: "left", width: "100%", borderColor: "white" }}>
                                {item.testName} {": "} <strong> {item.result}</strong> {item.unit}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>

          {testList.map(
            (item, index) =>
              item.columnNo == "10" && (
                <div
                  key={index + 1}
                  style={{
                    textAlign: "left",
                    paddingLeft: "30px",
                    margin: "30px",
                    width: "100%",
                    borderColor: "white",
                  }}
                >
                  {item.testName} {": "} <strong> {item.result}</strong> {item.unit}
                </div>
              )
          )}
        </>
      );

    return null;
  };

  return renderElement();
}

function CheckAndBoldValue(result1, normalValue) {
  let result = result1.trim();

  try {
    let datasplit = normalValue.split("-");
    if (datasplit.length >= 2) {
      // console.log("datasplit.length >= 2 ===>");
      let startVal = parseFloat(datasplit[0].trim());
      let endVal = parseFloat(datasplit[1].trim());

      let r = 0;
      // console.log("Result", r, "      startVal", startVal, "   endVal", endVal);
      // if (typeof result === "number") {
      if (!isNaN(parseFloat(result))) {
        r = parseFloat(result);

        if (r < startVal || r > endVal) {
          // console.log("startVal OR endVal===>", r);
          return (
            // <div style={{ textAlign: "right", fontWeight: "bold", fontSize: "14px", width: "85%" }}> {result}</div>
            <table style={{ width: "100%"}}>
              <tr>
                <td style={{ width: "50%", border:"0px", backgroundColor:"#f0f0f0"}}> </td> <td style={{width: "50%", border:"0px", textAlign: "center", backgroundColor:"#f18f7e"}}> {result} </td>
              </tr>
            </table>
          );
        } else {
          // console.log("endVal Else ===>", r);
          return (
            // <div style={{ textAlign: "left", fontWeight: "bold", width: "90%", fontSize: "14px", paddingLeft: "20px" }}>
            //   {result}
            // </div>
            <table style={{ width: "100%"}}>
            <tr>
              <td style={{ width: "50%", border:"0px", backgroundColor:"#f0f0f0", textAlign: "center"}}> {result} </td> <td style={{width: "50%", border:"0px", backgroundColor:"#f18f7e"}}>  </td>
            </tr>
          </table>

          );
        }
      } else {
        // console.log("typeof number Else ===>");
        return <div style={{ textAlign: "center", width: "90%", fontSize: "16px" }}>{result}</div>;
      }
    } else {
      // console.log("datasplit.length Else ===>");
      return <div style={{ textAlign: "center", width: "90%", fontSize: "16px" }}>{result}</div>;
    }
  } catch (error) {
    console.error(error);
    return <div style={{ textAlign: "center", width: "90%", fontSize: "16px" }}>{result}</div>;
  }
}
