import React, { useEffect, useRef, useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import Controls from "../../../components/Controls";
import dayjs from "dayjs";

import * as GenServices from "../../../helper/GenServices";

import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import { useSelector, useDispatch } from "react-redux";

export default function OPD_Report_Print(props) {
  // const dispatch = useDispatch();
  const hospitalHeader = useSelector((state) => state.HospitalHeader_Reducer);
  // console.log("hospitalHeader ====>", hospitalHeader);
  // const {printForm} = props;

  const { isPrintDirect, setIsPrintDirect } = props;

  const reportData = props.reportData;
  const formData = props.formData;
  const total = props.total;

  const [arrTableData, setArrTableData] = useState([]);

  useEffect(() => {
    // isPrintDirect && handlePrint();
    handleClose();
    // TableBodyRow();
  }, [isPrintDirect]);

  const handleClose = (value) => {
    setIsPrintDirect && setIsPrintDirect(false);
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let marginTop = "50px";
  let marginBottom = "50px";

  let marginLeft = "10px";
  let marginRight = "10px";

  const getPageMargins = () => {
    return ` 

    table,
    img,
    svg {
    break-inside: auto;
    }

    @page {
        size: A4; 
        margin-top: 1cm;
        margin-bottom: 1cm; 
      }

    .tbl-header {
        height: 120px;
        margin-bottom: 10px;
        text-align: center;
      }
      
      .tbl-header-dumy-space {
        height: 130px;
        display: none;
        text-align: center;
        margin-bottom: 20px;
      }

    .tbl-footer {
        height: 30px; 
        text-align: left;
        font-weight: bold;
        font-size: 18px;
        padding-top: 10px;
        width: 100%;
      }
      .tbl-footer-dumy-space {
        height: 40px;
        margin-top: 10px;
        display: none;
      } 

      @media print {  
        .tbl-header {
          position: fixed;
          top: 0;
          text-align: center; 
        }
        .tbl-footer {
          position: fixed;
          bottom: 0; 
        }
      
        .tbl-header-dumy-space,
        .tbl-footer-dumy-space { 
          display: block;
        } 
      }
      

    }`;

    // @media print{ @page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important;}
  };

  //   useEffect(() => {
  //     createGroup();
  //   }, [reportData]);

  //   useEffect(() => {
  //     console.log("arrTableData ====>", arrTableData);
  //   }, [arrTableData]);

  // const getPageMargins = () => {
  //   return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
  // };

  let tableHeaderValue = "";
  let isPrintTableHeader = true;

  return (
    // <h1>{props.opdNo}</h1>
    <>
      <div style={{ position: "fixed", top: "100px", left: "50%" }}>
        <Controls.ActionButton color="primary" title="Print OPD Form" onClick={handlePrint}>
          <PrintIcon />
        </Controls.ActionButton>
      </div>

      <style>{getPageMargins()}</style>

      <div ref={componentRef}>
        <header className="tbl-header">
          {/* This is Header Part */}
          <div
            style={{
              width: "1060px",
              border: "0",
              backgroundColor: "transparent",
              // display: "flex",
              // height:"400pt",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              style={{
                width: "960px",
                backgroundColor: "White",
                border: "0",
                marginLeft: "auto",
                marginRight: "auto",
                fontWeight: "bold",
              }}
            >
              <div>
                <table style={{ width: "100%",  }}>
                  {/* <thead></thead> */}
                  <tbody>
                    <tr>
                      {/* <td style={{ width: "25%" }}>
                        <div style={{ padding: "10px", textAlign: "right" }}>
                          {/* <img src="logo192.png" alt="Header Image" width={110} height={80} /> * /}
                        </div>
                      </td> */}
                      <td style={{ width: "50%", textAlign: "center" }}>
                        <div>
                          <span style={{ fontSize: "22px" }}> {hospitalHeader.hospitalName} </span>
                        </div>
                        {/* <div> {hospitalHeader.hospitalAddress} </div> */}
                        {/* <div> {hospitalHeader.hospitalAddress2} </div> */}
                        {/* <div> {hospitalHeader.hospitalPhoneNo} </div> */}
                        <div
                          style={{
                            fontSize: "22px",
                            fontWeight: "bold",
                            textAlign: "center",
                            // textUnderlineOffset: "5px",
                            // textDecorationLine: "underline",
                            paddingTop: "0px",
                          }}
                        >
                          OPD DETAIL REPORT
                        </div>
                        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                          <table className="tbl1111" style={{ width: "100%", border: "1px solid black" }}>
                            <tr>
                              <td className="center" style={{ width: "50%" }}>
                                From: {dayjs(formData.startdate).format("DD/MMM/YYYY")} To:
                                {dayjs(formData.enddata).format("DD/MMM/YYYY")}{" "}
                              </td>
                              <td className="center" style={{ width: "50%" }}>
                                Consultant: {formData.consultant}{" "}
                              </td>
                            </tr>
                          </table>
                        </div>

                        <div style={{ backgroundColor: "#f0f0f0", }} >
                          <table style={{ width: "100%",fontSize:"12px" }}>
                            {/* <thead></thead> */}
                            <tbody>
                              <tr style={{ fontWeight: "bold" }}>
                                <td style={{ width: "10%", textAlign: "center" }}> BillNo </td>
                                <td style={{ width: "15%", textAlign: "center" }}> Bill Date </td>
                                <td style={{ width: "10%", textAlign: "center" }}> OPD No.</td>
                                <td style={{ width: "10%", textAlign: "center" }}> MRNo. </td>
                                <td style={{ width: "30%", textAlign: "center" }}> Patient Name </td>
                                <td style={{ width: "10%", textAlign: "center" }}>Age/Sex</td>                                 
                                <td style={{ width: "15%", textAlign: "center" }}> Reg. Charges</td>
                              </tr>
                            </tbody>
                            {/* <tfoot></tfoot> */}
                          </table>
                        </div>
                      </td>
                      {/* <td style={{ width: "25%" }}> </td> */}
                    </tr>
                  </tbody>
                  {/* <tfoot></tfoot> */}
                </table>
              </div>
            </div>
          </div>
        </header>

        <table
          id="labMainTable"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            // backgroundColor: "silver",
          }}
        >
          <thead>
            <tr>
              <td>
                <div style={{ backgroundColor: "transparent" }} className="tbl-header-dumy-space"></div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {/* <section className="labReportBody"> */}
                <div>
                  <div
                    style={{
                      width: "1060px",
                      border: "0",
                      backgroundColor: "transparent",
                      // display: "flex",
                      // height:"400pt",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <div
                      style={{
                        width: "960px",
                        backgroundColor: "White",
                        border: "0",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    ></div>
                  </div>
                  {/* </header> */}
                </div>

                {/* Report Body Content */}
                <div
                  style={{
                    width: "960px",
                    backgroundColor: "white",
                    border: "0",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div style={{ padding: "5px", border: "1px solid black" }}>
                    {/* {<TableBodyRow />} */}
                    <table className="tbl" style={{ fontWeight: "bold", border: "0px solid white", width: "100%", fontSize:"9px" }}>
                      <thead></thead>
                      <tbody> 
                        {Array.isArray(reportData) &&
                          reportData.map((row, index) => {
                            //testName, normalValue, unit, result
                            return (
                              <tr key={index + 1}>
                                {/* <td>{index + 1}</td> */} 
                                <td style={{ textAlign: "center", width: "10%" }}>{row.billNo}</td>
                                <td style={{ textAlign: "center", width: "15%" }}>{dayjs(row.opdDate).format("DD/MM/YYYY hh:mm a")}</td>
                                <td style={{ textAlign: "center", width: "10%" }}>{row.opdNo}</td>
                                <td style={{ textAlign: "center", width: "10%" }}>{row.mrNo}</td>
                                <td style={{ textAlign: "center", width: "30%" }}>{row.name}</td>
                                <td style={{ textAlign: "center", width: "10%" }}>{row.age} / {row.gender.slice(0,1)}</td>
                                <td style={{ textAlign: "center", width: "15%" }}>₹{row.paidRs}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                      <tfoot></tfoot>
                    </table>

                    <table className="tbl" style={{ fontWeight: "bold", fontSize: "20px" }}>
                      <tr>
                        {/* <td>{index + 1}</td> */}
                        <td style={{ textAlign: "right", width: "50%", fontSize: "14px" }}>Grand Total</td>
                        <td style={{ textAlign: "center", width: "25%", backgroundColor: "silver" }}>
                          {total.totalOPD}
                        </td>
                        <td style={{ textAlign: "center", width: "25%", backgroundColor: "silver" }}>
                          {total.totalValue}
                        </td>
                      </tr>
                    </table>
                  </div>
                  {/* <div style={{ margin: "30px", textAlign: "center" }}>
                    * * * * * * * * * * * * * * * * End of Report * * * * * * * * * * * * * * * *
                  </div> */}
                </div>
                {/* </div> */}

                {/* <div style={{ marginTop: "50px" }}></div> */}
                {/* </section> */}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div className="tbl-footer-dumy-space">&nbsp;</div>
              </td>
            </tr>
          </tfoot>
        </table>

        {/* <div className="tbl-footer">
          <div style={{ textAlign: "right", marginRight: "50px", fontSize: "11px" }}>
            <span style={{ fontWeight: "normal" }}> MRNo.: </span>
            <span style={{ fontWeight: "normal" }}> Name: </span>
          </div> 
        </div> */}

        {/* <div className="tbl-footer">Lab Incharge</div> */}

        {/* <div class="footer">    
    <div align="center">Page <span class="pagenumber"/> of <span class="pagecount"/></div>
    <div> Lab Incharge  </div> </div> */}
      </div>
    </>
  );
}
