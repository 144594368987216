import React, { useEffect, useRef, useState } from "react";
import axios from "../../axios/axios";
import PrintIcon from "@mui/icons-material/Print";
import Controls from "../../components/Controls";
import dayjs from "dayjs";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import { BorderColor } from "@mui/icons-material";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { HtmlToWord } from "../../utils/HtmlToWord";

export default function OpdRegnFormPrint(props) {
  const [qualification, setQualification] = useState({ qual1: "", qual2: "", qual3: "" });

  // console.log("Print Opd Form ====>", props);

  // const {printForm} = props;

  // console.log("printForm.opdNo ====>", props.opdNo);

  const { isPrintDirect, setIsPrintDirect } = props;
  // console.log("isPrintDirect ====>", isPrintDirect);
  // console.log("final Data for Print ====>", props);
  // return;

  const { mrNo, name, soDo, fhName, gender, age, disease, diseaseHis, pAddress, pMobNo } = props;
  const { address, city, state, country } = pAddress;

  // const { mrNo, name, soDo, fhName, gender, age, disease, diseaseHis } = props.ref_reg_patient;
  // const { address, city, state, country } = props.ref_reg_patient.pAddress;
  // const { pMobNo } = props.ref_reg_patient.mobileNo;
  const {
    hid,
    opdNo,
    opdDate,
    appointmentType,
    depttName,
    staffUN,
    depttAnnualNo,
    consultant,
    tokenNo,
    roomNo,
    unitName,
    paidRs,
    paymentMode,
    validUpto,
    visitNo,
    staffId,
  } = props;

  useEffect(() => {
    getDoctorQualification(hid, staffId);
  }, []);

  const getDoctorQualification = async (hid, staffId) => {
    //setLoading(true);
    // validate(initialValues);
    console.log("getDoctorQualification Starting............", staffId);

    try {
      //This is get from HRM Module
      const res = await axios.get(`/staff/getdoctorqualification/?hid=${hid}&staffId=${staffId}`);
      console.log("getDoctorQualification ===>", res.data);

      const result = res.data.qualification;
      if (result) {
        let data = result.split(";");
        if (data.length === 1) {
          // setQualification((preValue){ qual1: data[0], qual2: data[1] });
          setQualification((preValue) => ({ ...preValue, qual1: data[0] }));
        } else if (data.length === 2) {
          setQualification((preValue) => ({ ...preValue, qual1: data[0] }));
          setQualification((preValue) => ({ ...preValue, qual2: data[1] }));
        } else if (data.length === 3) {
          setQualification((preValue) => ({ ...preValue, qual1: data[0] }));
          setQualification((preValue) => ({ ...preValue, qual2: data[1] }));
          setQualification((preValue) => ({ ...preValue, qual3: data[2] }));
        }

        // setQualification(res.data);
        // console.log("res.data ===>", res.data)
      }
      // setLoading(false);
    } catch (error) {
      console.error("Error in getDoctorQualification ===>", error);

      // setLoading(false);
      // setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  const handleClose = (value) => {
    setIsPrintDirect && setIsPrintDirect(false);
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    pageStyle: `@media print { 
      @page {        
        size: A4 !important;
        margin-top: 1cm !important;
        margin-bottom: 1cm !important;
        margin-left: 1.5cm !important;
        margin-right: 0.5cm !important; 
      }
    }
    `,
    content: () => componentRef.current,
    documentTitle: ".",
    onPrintError: (error) => console.log(error),
  });

  useEffect(() => {
    isPrintDirect && handlePrint();
    handleClose();
  }, [isPrintDirect]);

  const exportToWord = () => {
    //var htmlData = document.getElementById(element).innerHTML;
    var htmlData = componentRef.current.innerHTML;
    HtmlToWord(htmlData, "OPD-Consultation-Form.docx");
  };

  return (
    // <h1>{props.opdNo}</h1>
    <div>
      <div style={{ position: "fixed", top: "100px", left: "50%" }}>
        <Controls.ActionButton color="primary" title="Export to MS-Word" onClick={exportToWord}>
          <FileDownloadIcon />
        </Controls.ActionButton>
        <Controls.ActionButton color="primary" title="Print OPD Form" onClick={handlePrint}>
          <PrintIcon />
        </Controls.ActionButton>
      </div>
      <table
        ref={componentRef}
        align="center"
        style={{ marginTop: "0px", width: "100%", border: "0", backgroundColor: "White" }}
      >
        <tr>
          <td valign="top">
            <table align="center" style={{ width: "100%", border: "1", backgroundColor: "White" }}>
              <tr>
                <td valign="top">
                  {/* <img src="header.jpg" alt="Header Image" /> */}
                  <div
                    style={{
                      fontWeight: "bold",
                      height: "70px",
                      marginTop: "5px",
                      paddingLeft: "5px",
                      fontSize: "12px",
                    }}
                  >
                    <span className="fontsize18"> {consultant} </span> <br />
                    {qualification.qual1} <br />
                    {qualification.qual2} <br />
                    {qualification.qual3}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ textAlign: "center", marginTop: "10px", fontWeight: "bold", fontSize: "16px" }}>
                    {/* <h3
                            style={{
                              border: "2px solid #000000",
                              borderRadius: "7px",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          > */}
                    {/* OUT PATIENT ASSESSMENT RECORD */}
                    {/* </h3> */}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ border: "2px solid #000000", borderRadius: "7px", padding: "5px" }}>
                    <table style={{ width: "100%" }} className="fontsize14">
                      <tr>
                        <td style={{ width: "17%" }}>Patient's Name</td>
                        <td style={{ width: "34%", fontWeight: "bold" }} className="fontsize14">
                          : {name}
                        </td>
                        <td style={{ width: "16%" }}>M.R. No.</td>
                        <td style={{ width: "33%", fontWeight: "bold" }} className="fontsize14">
                          : {mrNo}
                        </td>
                      </tr>
                      <tr>
                        <td>Age/Gender</td>
                        <td style={{ fontWeight: "bold" }} className="fontsize14">
                          : {age} / {gender}
                        </td>
                        <td>Date & Time</td>
                        <td style={{ fontWeight: "bold" }} className="fontsize14">
                          : {dayjs(opdDate).format("DD/MM/YYYY")} at {dayjs(opdDate).format("hh:mm a")}
                        </td>
                      </tr>
                      <tr>
                        <td> Address </td>
                        <td style={{ fontWeight: "bold" }} className="fontsize14">
                          : {address} {",  "} {city}{" "}
                        </td>
                        <td>OPD No. </td>
                        <td style={{ fontWeight: "bold" }} className="fontsize14">
                          : {opdNo}
                        </td>
                      </tr>
                      {/* <tr>
                        <td> Disease </td>
                        <td>: {disease} </td>
                        <td> Disease History </td>
                        <td>: {diseaseHis} </td>
                      </tr> */}
                      <tr>
                        <td> Mobile No. </td>
                        <td style={{ fontWeight: "bold" }} className="fontsize14">
                          : {pMobNo}{" "}
                        </td>
                        <td>Consult-Type </td>
                        {/* <td>: {visitNo} {" ("}Valid Upto: {dayjs(validUpto).format("DD/MM/YYYY")}{")"}</td> */}
                        <td style={{ fontWeight: "bold" }} className="fontsize14">
                          : {appointmentType}, ₹ {paidRs}/-
                        </td>
                      </tr>
                      {/* <tr>
                        <td> Department </td>
                        <td>: {depttName} </td>
                        <td> Consultant </td>
                        <td>: {consultant} </td>
                      </tr> */}

                      {/* <tr>
                        <td> Mobile No. </td>
                        <td>:  </td>
                        <td> Paid Rs. </td>
                        <td>
                          {/ * : {paidRs} {" / "} {paymentMode} {"  / "} User: {staffUN} * /}
                        </td>
                      </tr> */}
                    </table>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ fontWeight: "bold", textAlign: "left" }}>
                    {/* <div style={{ textAlign: "left", height: "20px", paddingLeft: "20px" }}>
                      <strong>
                        <span>Vital:-</span>
                      </strong>
                    </div> */}
                    <div style={{ marginTop:"10px", marginBottom:"10px"}}>
                    <table style={{ width: "100%", fontSize: "11px" }}>
                    <tr>
                      <td>Weight</td>
                      <td>..............</td>
                      <td>kgs.,</td>
                      <td>Height</td>
                      <td>.............</td>
                      <td>cms,</td>
                      <td>Temp</td>
                      <td>.............</td>
                      <td>°F,</td>
                      <td>Pulse</td>
                      <td>.............</td>
                      <td>/min</td>
                      <td>SPO2</td>
                      <td>.............</td>
                      <td>%,</td>
                      <td>BP(mmHg)</td>                      
                      <td>L:</td>
                      <td>...............</td>
                      <td>R:</td>
                      <td>...............</td>
                    </tr>
                    </table>

                      {/* Weight..............kgs., Height.............cms, Temp.............°F, Pulse............./min,
                      SPO2.............%, BP(mmHg) L: ............... R: ............... */}
                    </div>
                    <div>
                      <table style={{ width: "100%", fontSize: "13px" }}>
                        <tr>
                          <td>Pain:</td>
                          <td>Yes ▢</td>
                          <td>No ▢</td>
                          <td>
                            <img src="painscale.png" style={{ height: "40px", width: "300px" }} />
                          </td>
                          <td>Nut. Ass./Screening Required:</td>
                          <td>Yes ▢</td>
                          <td>No ▢</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <hr style={{ fontWeight: "bold", height: "4px", background: "black", marginTop: "0px" }}></hr>

                  <table style={{ width: "100%", height: "80px" }}>
                    <tr>
                      <td valign="top" width="43%">
                        <div style={{ fontWeight: "bold", textAlign: "left" }}>Diagnosis</div>
                      </td>
                      <td valign="top" width="37%">
                        <div style={{ fontWeight: "bold", textAlign: "left", paddingLeft: "100px" }}>Allergies</div>
                      </td>
                      <td valign="top" width="20%">
                        <div style={{ fontWeight: "bold", textAlign: "center" }}>Note</div>
                      </td>
                    </tr>
                  </table>
                  <hr style={{ fontWeight: "bold", height: "4px", background: "black" }}></hr>
                  <div style={{ height: "400px" }}></div>
                </td>
              </tr>
              {/* <tr>
                <td>
                  <p style={{ padding: "0px", color: "black", marginRight: "150px" }}>
                    Note: Not for Medico Legal Purpose{" "}
                  </p>
                </td>
              </tr> */}
            </table>
          </td>
        </tr>
      </table>
    </div>
  );
}
