import { ActionTypes } from "../constants/actionTypes";


//***************************************************************** */

export const login = user => {
  return {
    type: ActionTypes.LOGIN,
    payload: user
  };
};

export const logout = () => {
  return {
    type: ActionTypes.LOGOUT
  };
};


//***************************************************************** */

export const setAllOriginalFeeHeads = (feeHeads) => {
  return {
    type: ActionTypes.OPD_BILLING_GET_FEEHEADS_LIST_MASTER,
    payload: feeHeads,
  };
};

export const selectedAllOriginalFeeHeads = (feeHeads) => {
  return {
    type: ActionTypes.OPD_BILLING_SELECTED_ORIGINAL_FEEHEADS,
    payload: feeHeads,
  };
};

export const setAllFeeHeadsMasterList = (feeHeads) => {
  return {
    type: ActionTypes.GET_FEE_HEADS_MASTER_LIST,
    payload: feeHeads,
  };
};

//***************************************************************** */
export const add_CSV_data = (data) => {
  return {
    type: ActionTypes.ADD_CSV_DATA,
    payload: data,
  };
};

export const removeCSV_one_row = (id) => {
  return {
    type: ActionTypes.REMOVE_CSV_ONE_ROW,
    payload: id,
  };
};

export const set_HospitalHeader = (id) => {
  return {
    type: ActionTypes.SET_HOSPITAL_HEADER,
    payload: id,
  };
};
//***************************************************************** */
// export const add_OPD = (data) => {
//   return {
//     type: ActionTypes.ADD_OPD,
//     payload: data,
//   };
// };

// export const get_OPD_List_by_MRNo = (data) => {
//   return {
//     type: ActionTypes.GET_OPD_LIST_BY_MRNO,
//     payload: data,
//   };
// };

// export const get_OPD_List = () => {
//   return {
//     type: ActionTypes.GET_OPD_LIST,
//     //payload: data,
//   };
// };

export const set_OPD_List = (data) => {
  return {
    type: ActionTypes.SET_OPD_LIST,
    payload: data,
  };
};

// export const delete_OPD = (id) => {
//   return {
//     type: ActionTypes.DELETE_OPD,
//     payload: id,
//   };
// };

//***************************************************************** */
export const addLabTestListMasterData = (testList) => {
  return {
    type: ActionTypes.ADD_LAB_TEST_LIST_MASTER_DATA,
    payload: testList,
  };
};
//***************************************************************** */
// //  export const LOGIN = "LOGIN";
// //  export const LOGOUT = "LOGOUT";

// export const login = user => {
//   return {
//     type: "LOGIN",
//     payload: user
//   };
// };

// export const logout = () => {
//   return {
//     type: "LOGOUT"
//   };
// };

//***************************************************************** */

// export const incrementNumber = (num) => {
//   return {
//     type: "INCREMENT",
//     payload: num,
//   };
// };

// export const decrementNumber = (num) => {
//   return {
//     type: "DECREMENT",
//     payload: num,
//   };
// };
//***************************************************************** */
// export const addTodo = (val) => {
//   return {
//     type: "ADD_TODO",
//     payload: {
//       id: new Date().getTime().toString(),
//       data: val,
//     },
//   };
// };

// export const deleteTodo = (id) => {
//   return {
//     type: "DELETE_TODO",
//     id: id,
//   };
// };
// export const removeTodo = (id) => {
//   return {
//     type: "REMOVE_TODO",
//   };
// };
