import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "./../axios/axios";

export const getSubHeadingList = async (hid) => {
  try {
    
    let result = [];
    const res = await axios.get(`/fee/headlist/?hid=${hid}`);

    // console.log("getSubHeadingList res.data AAAAAA ===>", res.data);
    //   console.log("getRecord by appointmentType  ===>", res.data);
    //   console.log("getRecord by res.status  ===>", res.status);
    if (res.data) {
      // console.log("getConsultant res.data ===>", res.data);
      res.data.map((row) => {
        row.feeHeads.map((item) => {
          // result.push({ val: item._id, text: item.feeHead + " ("+ row.feeHeadMain + ") Rate:("+ item.rate +")" });
          result.push({
            ref_fee_head_main_id: row._id,
            ref_fee_head_nested_id: item._id,
            val: item._id,
            text: "(" + row.feeHeadMain + ")  " + item.feeHead +" (Rate:" + item.rate + ")",
          });
        });
      });

      // console.log("getSubHeadingList result ===>", result);

      return result;
    }
    // setLoading(false);
    return [];
  } catch (error) {
    console.error("getSubHeadingList ===> Error: ", error);
    throw error;
  }
};


export const getMainHeadingList = async (hid) => {
  try {
    
    let result = [];
    const res = await axios.get(`/fee/headlist/?hid=${hid}`);
    //   console.log("getRecord by res.status  ===>", res.status);
    if (res.data) {
      // console.log("getConsultant res.data ===>", res.data);
      res.data.map((row) => { 
         result.push( {val: row.feeHeadMain, text: row.feeHeadMain}); 
      });
      // console.log("getSubHeadingList result ===>", result);

      return result;
    }
    // setLoading(false);
    return [];
  } catch (error) {
    console.error("getMainHeadingList ===> Error: ", error);
    throw error;
  }
};

export const getSubHeadingListWithHeadName = async (hid) => {
  try {
    
    let result = [];
    const res = await axios.get(`/fee/headlist/?hid=${hid}`);
    //   console.log("getRecord by res.status  ===>", res.status);
    if (res.data) {
      // console.log("getConsultant res.data ===>", res.data);
      res.data.map((row) => {
         row.feeHeads.map((item) => {
          // result.push({ val: item._id, text: item.feeHead + " ("+ row.feeHeadMain + ") Rate:("+ item.rate +")" });
         result.push( {val: item.feeHead, text: "(" + row.feeHeadMain + ")  " + item.feeHead,});
          //});
        });
      });
      // console.log("getSubHeadingList result ===>", result);

      return result;
    }
    // setLoading(false);
    return [];
  } catch (error) {
    console.error("getSubHeadingList ===> Error: ", error);
    throw error;
  }
};
// export async function get FeeHeadMainList() {
//   let options = [];
//   try {
//     const res = await axios.get("/fee/ headsub/mainhead_list");
//     // console.log("getRecord  ===>", res);
//     if (res) {
//       options = res.data.map((row) => {
//         return { val: row._id, text: row.feeHeadMain };
//       });
//       // console.log("res.data  options ===>", options)
//     }

//     return options;

//   } catch (error) {
//     return options;
//   }
// }

const getRecord = async () => {
  //setLoading(true);
  // validate(initialValues);
  //setLoading(false);
};
