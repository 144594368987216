import React, { useEffect, useReducer, useState } from "react";
import dayjs from "dayjs";
import axios from "../../axios/axios";

import PageHeader from "../../components/PageHeader";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import PrintIcon from "@mui/icons-material/Print";
// import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { IconButton, Typography } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import DirectionsIcon from "@mui/icons-material/Directions";

import ColorizeIcon from "@mui/icons-material/Colorize";
import { InputAdornment, Box, Paper, TableContainer } from "@mui/material";

import DataGrid from "../../components/useDataGrid";
import Popup from "../../components/Popup";
import Controls from "../../components/Controls";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import { Stack } from "@mui/system";
import { MyUseForm, MyForm } from "../../components/useForm";
import LabReportPrint from "./LabReportPrint";

import * as GenServices from "../../helper/GenServices";
import { useSelector, useDispatch } from "react-redux";
import { addLabTestListMasterData } from "../../redux/actions";

import { useGlobalContext } from "../../context/Context";

export default function LabReport() {
  const [hid] = useState(2);

  const { staffUN, ip, isEditRight, isDeleteRight } = useGlobalContext();

  const [loading, setLoading] = useState(false);

  //   const dispatch = useDispatch();
  // const AllsubHeadings = useSelector((state) => state.subHeadingList_Reducer.mainHeading);

  //   const hook_AllTestList = useSelector((state) => state.LabTestList_Reducer.testData);

  // const records = useSelector((state)=> state.LabTestList_Reducer.testData);
  //console.log("records==>  ", records);

  // const [formData, setFormData] = useReducer((state, newState)=>( ({...state, ...newState}), initialValues ));
  //   const [allLabTestList, setAllLabTestList] = useState([]);

  //   const [feeHead_id, setFeeHead_id] = useState("");

  // const [records, setRecords] = useState([]);
  const [originalReportData, setOriginalReportData] = useState([]);
  const [reportData, setReportData] = useState([]);

  const [printForm, setPrintForm] = useState([]);
  const [openPopupReceipt, setOpenPopupReceipt] = React.useState(false);
  const [isPrintDirect, setIsPrintDirect] = React.useState(false);

  const [searchVal, setSearchVal] = useState("");

  const [dateRange, setDateRange] = useState({
    startdate: dayjs(),
    enddate: dayjs(),
  });

  // const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });

  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    isConfirm: null,
    title: "title",
    subtitle: "subtitle",
  });
  //   const { formData, setFormData, errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);
  // const { errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);

  const getLabReport = async () => {
    //setLoading(true);
    // validate(initialValues);

    try {
      const res = await axios.get(
        `/lab/addtest/getlabresultreport/?hid=${hid}&startdate=${dateRange.startdate}&enddate=${dateRange.enddate}`
      );

      // console.log("getLabReport  ===>", res.data);

      if (res) {
        setOriginalReportData(res.data);
        setReportData(res.data);
        setSearchVal("");
        // console.log("setOriginalReportData ===>", res.data);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.error("error ===>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  useEffect(() => {
    getLabReport();
  }, []);

  useEffect(() => {
    // console.log("dateRange=", dateRange);
    getLabReport();
  }, [dateRange]);
   
  const onDelete = async (labResultNo) => {
    //if (window.confirm("Do you want to delete this record?")) {
    try {
      // console.log("axios.patch===>", Patient);
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      const res = await axios.delete(`/lab/addtest/onerecorddelete/?hid=${hid}&labResultNo=${labResultNo}`);
      if (res) {
        setNotify({ isOpen: true, message: "Deleted Successfully", type: "success" });
        await getLabReport();
      }
    } catch (error) {
      console.error("error=====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    // RegnServices.deletePatient(id);
  };

  const handleClickDelete = (data) => {
    //if (window.confirm("Do you want to delete this record?")) {
      console.log(data)
    if (isDeleteRight) {
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure to delete this record?",
        subtitle: "You can't undo this operation",
        isConfirm: () => {
          onDelete(data._id);
        },
      });
    } else {
      setNotify({ isOpen: true, message: "you do not have the right to delete", type: "error" });
    }
  };

  const columnsLabReport = [
    {
      field: "delete",
      headerName: "Delete",
      width: 60,
      sortable: false,
      renderCell: (curItem) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
            <Controls.ActionButton
                color="red"
                title="Delete"
                onClick={() => handleClickDelete(curItem.row)}
              >
                <DeleteForeverIcon />
              </Controls.ActionButton> 
            </Stack>
          </>
        );
      },
    },
    {
      field: "reportDate",
      headerName: "Report-Date",
      width: 120,
      valueGetter: (item) => dayjs(item.row.reportDate).format("DD/MM/YYYY hh:mm: a"),
    },
    {
      field: "labResultNo",
      headerName: "MRNo/LabNo",
      width: 130,
      // valueGetter: (item) => item.row.mrNo,
      renderCell: (item) => (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {item.row.mrNo}
          </Typography>
          <Typography color={"blue"} fontWeight={"bold"}>{item.row.labResultNo}</Typography>
        </div>
      ),
    },

    {
      field: "billNo",
      headerName: "BillNo/BillDate",
      width: 150,
      // valueGetter: (item) => item.row.mrNo,
      renderCell: (item) => (
        <div>
          <Typography>{item.row.billNo}</Typography>
          <Typography sx={{ fontSize: 13 }}>
           {dayjs(item.row.billDate).format("DD/MM/YYYY hh:mm a")}
          </Typography>
        </div>
      ),
    },

    // { field: "billNo", headerName: "BillNo", width: 100 },
    // {
    //   field: "billDate",
    //   headerName: "Bill Date",
    //   width: 100,
    //   valueGetter: (item) => dayjs(item.row.billDate).format("DD/MM/YYYY hh:mm a"),
    // },

    // { field: "labResultNo", headerName: "ResultNo", width: 110 },
    // { field: "mrNo", headerName: "MRNo", width: 90 },
    // { field: "iopNo", headerName: "OPDNo", width: 100 },
    // const { name, gender, age } = item.ref_reg_opd.ref_reg_patient;
    // const { pMobNo } = item.ref_reg_opd.ref_reg_patient.mobileNo;

    // { field: "name", headerName: "Name", width: 200, valueGetter: (item) => item.row.ref_reg_opd.ref_reg_patient.name },
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "gender",
      headerName: "Gender/Age",
      width: 100,
      valueGetter: (item) => item.row.gender + "/ " + item.row.age,
    },

    // { field: "email", headerName: "Email", width: 200 },

    // {
    //   field: "feeHeads",
    //   headerName: "Top 1 TestName",
    //   width: 200,
    //   valueGetter: (item) => item.row.billDetail[0].feeHead,
    // },

    // { field: "billNo", headerName: "BillNo", width: 120 },

    // { field: "paidRs", headerName: "PaidRs", width: 120 },
    // { field: "paymentMode", headerName: "paymentMode", width: 120 },
    {
      field: "print",
      headerName: "Print",
      width: 60,
      sortable: false,
      renderCell: (curItem) => {
        return (
          <>
            <Stack direction="row" spacing={1}> 
              <Controls.ActionButton
                color="primary"
                title="Print Lab Report"
                onClick={() => handlePrintBill(curItem.row)}
              >
                <PrintIcon />
              </Controls.ActionButton> 
            </Stack>
          </>
        );
      },
    },
    // { field: "pMobNo", headerName: "MobNo", width: 120 },
    { field: "subHeading", headerName: "Lab-Test", minWidth: 800, flex: 1 },
  ];

  const handlePrintBill = async (item) => {
    // console.log("handlePrintBill ====", item);
    // console.log("handlePrintBill 111111 ====", ConvertDataToPrintableReceipt(item));
    try {
      // const res = await axios.get(`/lab/addtest/getlabprintdatabyid/${item._id}`);
      // const res = await axios.get(`/lab/addtest/getlabprintingdata/?hid=${hid}&labResultNo=${item._id}`);
      const res = await axios.get(`/lab/addtest/getlabprintingdata/?hid=${hid}&labResultNo=${item.labResultNo}`);
      // console.log("getLabReport  ===>", res.data);

      if (res) {
        // const data = [item];
        setIsPrintDirect(true);
        setPrintForm(res.data);
        setOpenPopupReceipt(true);
        // console.log("setOriginalReportData ===>", res.data);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
  };

  const handleSearch = async (e) => {
    const val = e.target.value;
    // const str = val || "";
    // console.log("BindBottomTable Value========>", val);
    setSearchVal(val);
    //if (val) {
    //setReportData([]);

    //optional chaining (?.)  operator for nullish value, undefinded value
    // const filteredRows = originalReportData?.filter(
    //   (row) =>
    //     row?.name?.match(new RegExp(str, "I")) ||
    //     row?.mrNo?.match(new RegExp(str, "I")) ||
    //     row?.iopNo?.match(new RegExp(str, "I"))
    // );

    const filteredRows = search(originalReportData, val);

    // console.log("filteredRows =>", filteredRows);
    // const filteredRows2 = await filteredRows[0].feeHeads;
    // const filteredRows3 = await filteredRows2.slice().reverse();
    setReportData(filteredRows);
    //}
  };

  const [fieldNamesForSearch] = useState(["name", "mrNo", "iopNo", "pMobNo", "labResultNo"]);

  function search(allItems, val) {
    return allItems.filter((item) => {
      return fieldNamesForSearch.some((newItem) => {
        return item[newItem].toString().toLowerCase().indexOf(val.toLowerCase()) > -1;
      });
    });

    //return items.filter((item) => {
    /*
 // in here we check if our region is equal to our c state
 // if it's equal to then only return the items that match
 // if not return All the countries
 */
    // if (item.region == filterParam) {
    //   return searchParam.some((newItem) => {
    //     return item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
    //   });
    // } else if (filterParam == "All") {
    //   return searchParam.some((newItem) => {
    //     return item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
    //   });
    // }
    //});
  }

  const getDataByMRNo = () => {};
  const requestSearch = () => {};
  const cancelSearch = () => {
    setSearchVal("");
    setReportData(originalReportData);
  };

  return (
    <div>
      <h2>Lab Test Report</h2>

      {/* <MyForm onSubmit={handleSubmit}> */}
      <Grid container spacing={2}>
        {/* billDate */}
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Controls.DatePicker
            label="Start-Date"
            name="startDate"
            value={dayjs(dateRange.startdate).format("DD/MMM/YYYY")}
            onChange={(e) => setDateRange({ ...dateRange, startdate: e.target.value })}
            // error={errors.billDate}
            // style={{ background: "#e9e9e9" }}
          />
        </Grid>

        {/* sampleDate */}
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Controls.DatePicker
            label="End-Date"
            name="endDate"
            value={dayjs(dateRange.enddate).format("DD/MMM/YYYY")}
            onChange={(e) => setDateRange({ ...dateRange, enddate: e.target.value })}
            // error={errors.sampleDate}
            // style={{ background: "#e9e9e9" }}
          />
        </Grid>
        {/* sampleDate */}
        <Grid item xs={2} sm={2} md={2} lg={1}>
          <Controls.Button variant="outlined" text="Go" onClick={() => getLabReport()} />
        </Grid>

        <Grid item xs={6} sm={4} md={4} lg={4}>
          <Controls.Input
            label="Search"
            name="search"
            value={searchVal}
            onChange={handleSearch}
            // title="press tab key for get record"
            // onKeyDown={(e) => (e.key === "Tab" ? getDataByMRNo() : "")}
            // error={errors.mrNo}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={cancelSearch} color="primary" sx={{ p: "10px" }} aria-label="directions">
                    <ClearIcon />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
                    <SearchIcon />
                    {/* <DirectionsIcon /> */}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            //       <IconButton
            //         onClick={getDataByMRNo}
            //         color="primary"
            //         sx={{ p: "10px" }}
            //         aria-label="directions"
            //       >
            //         <SearchIcon/>
            //         {/* <DirectionsIcon /> */}
            //       </IconButton>
            //     </InputAdornment>
            //   ),
            // }}
          />
        </Grid>
      </Grid>
      <Box>
        <p>Note: Get Record by ReportDate</p>
      </Box>

      <Box sx={{ mb: "50px", mt: "20px" }}>
        <DataGrid
          heading="Patient List"
          columns={columnsLabReport}
          rows={reportData}
          pageSize={50}
          showToolbar={true}
          boxHeight={2000}
        />
      </Box>

      <br></br>

      <Popup
        title="Patient Lab Report"
        openPopup={openPopupReceipt}
        setOpenPopup={setOpenPopupReceipt}
        isCloseOutside={true}
      >
        <LabReportPrint
          // billHeading="OPD Bill - Cum - Receipt"
          // feeHead="OPD Registration Charges"
          printForm={printForm}
          isPrintDirect={isPrintDirect}
          // setIsPrintDirect={setIsPrintDirect}
          // isPrintDirect={isPrintDirect}
          setIsPrintDirect={setIsPrintDirect}
        />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </div>
  );
}
