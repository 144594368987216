import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";

import axios from "../../axios/axios";
// import RegistrationForm from "./RegistrationForm";
import PageHeader from "../../components/PageHeader";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import AddIcon from "@mui/icons-material/Add";
// import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DataGrid from "../../components/useDataGrid";
import Popup from "../../components/Popup";
import Controls from "../../components/Controls";
import Checkbox from "@mui/material/Checkbox";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import { Stack } from "@mui/system";
import Grid from "@mui/material/Grid";
import { Typography, Box } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { InputAdornment } from "@mui/material";
import { MyUseForm, MyForm } from "../../components/useForm";
import ClipLoader from "react-spinners/ClipLoader";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import * as GenServices from "../../helper/GenServices";
// import { getEntityInnnerData } from "../../helper/GenServices";
import * as RegnServices from "../../helper/RegnServices";
import { Toolbar } from "@mui/material";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
// import { ComponentToPrint } from './ComponentToPrint';
import IpdRegnFormPrint from "./IpdRegnFormPrint";
import DirectBillPrint from "../Billing/DirectBillPrint";
import { get_IPD_List, set_IPD_List } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

import { useGlobalContext } from "../../context/Context";
import { Tune } from "@mui/icons-material";

const initialValuesRegn = {
  _id: "",
  mrNo: "",
  regNo: "",
  name: "",
  soDo: "S/o.",
  fhName: "",
  gender: "",
  age: "0Years",
  disease: "",
  mobileNo: { pMobNo: "" },
  email: "",
};

const initialValuesIpd = {
  // _id:"",
  hid: "2",
  ref_reg_patient: "",
  mrNo: "",
  ipdNo: "",
  unitName: "Main-Unit", //here used null becouse of AutoComplete Control
  inOut: "IN",
  admissionDate: dayjs(),
  dischargeDate: null,
  //patientDisStatus: //'GOOD', 'CURED', 'SYMPTOMS RELIEVED', 'CONDITION STABLE', 'PARTIAL IMPROVEMENT', 'DETERIORATION'
  ref_hrm_staff: "",  //{ type: "ObjectId", ref: "HRM_Staff" },
  consultant: "",
  staffId: "",
  depttName: "",
  roomInfo: [{ roomNo: "", roomDateIn: null, roomDateOut: null, staffUN: "" }],
  staffUN: "",
  ip: "",
  isRefer: false,
  referTo: "",
  referDate: null,
  referBy: "",
};

const initialValuesforDischarge = {
  _id: "",
  ipdNo: "",
  inOut: "OUT",  //IN, OUT
  dischargeDate: dayjs(),
  patientDisStatus: "",    //'GOOD', 'CURED', 'SYMPTOMS RELIEVED', 'CONDITION STABLE', 'PARTIAL IMPROVEMENT', 'DETERIORATION'
  // roomInfo: [{ roomDateOut: null, staffUN: "" }],
  staffUN: "",
  ip: "",
};


export default function IpdRegn() {
  const { staffUN, ip, isEditRight, isDeleteRight } = useGlobalContext();
  // const dispatch = useDispatch();
  // const hookIPDData = useSelector((state) => state.IPD_Reducer.list);

  const [hid] = useState(2);

  const [loading, setLoading] = useState(false);

  // const [originalReportData, setOriginalReportData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [reportDataForRightTable, setreportDataForRightTable] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  // const [rateSetup, setRateSetup] = useState("");

  // const [appointmentTypeValue, setAppointmentTypeValue] = useState([]);
  const [deptt, setDeptt] = useState([]);
  const [unitName, setUnitName] = useState([]);
  const [roomNo, setRoomNo] = useState([]);

  // console.log("state =====> ", state);
  // return });  //GET_IPD_LIST_BY_MRNO

  // const data = getPatientsList_Regn();
  // const [regnListLeft, setRegnListLeft] = useState([]);

  const [regnData, setRegnData] = useState(initialValuesRegn);
  const [mrNoValue, setMRNoValue] = useState("");
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [dischargeData, setDischargeData] = useState(initialValuesforDischarge);

  // const [selectedValue, setSelectedValue] = React.useState({});
  const [notify, setNotify] = React.useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    isConfirm: null,
    title: "title",
    subtitle: "subtitle",
  });

  const [printForm, setPrintForm] = useState([]);
  const [isPrintDirect, setIsPrintDirect] = React.useState(false);
  const [mrNoValueForReport, setMRNoValueForReport] = useState("");
  const [openPopupReceipt, setOpenPopupReceipt] = React.useState(false);
  const [consultant, setConsultant] = useState([]);
  // const [paymentMode, setPaymentMode] = useState([]);


  const getHospitalUnit = async () => {
    const rrr = await GenServices.getEntityInnnerData(2, "HospitalUnit");
    // console.log("HospitalUnit ------", rrr);
    setUnitName(rrr);
    // return rrr;
  };

  const getRoomNo = async () => {
    const rrr = await GenServices.getEntityInnnerData(2, "IpdRoomNo");
    // console.log("IpdRoomNo ------", rrr);
    setRoomNo(rrr);
    // return rrr;
  };

  const getConsultants = async () => {
    // const rrr = await GenServices.getConsultants(hid);
    //console.log("getConsultants ------", rrr);
    // setConsultant(rrr);

    try {
      const res = await axios.get(`/staff/getconsultantswithdepartment/?hid=${hid}`);
      // console.log("getConsultant res.data AAAAAA ===>", res.data);
      //   console.log("getRecord by appointmentType  ===>", res.data);
      //   console.log("getRecord by res.status  ===>", res.status);
      if (res.data) {
        // console.log("getConsultant res.data ===>", res.data);
        const result = res.data.map((item) => {
          return { val: item.staffId, text: item.name, deptt: item.deptt };
        });
        // return result;
        setConsultant(result);
      }
      // setLoading(false);
      //return [];
    } catch (error) {
      console.error("getConsultant ===> Error: ", error);
      //return [];
    }
  };

  const handleConsultantChange = (e) => {
    //if(e.target.value){
    if (e.target.value) {
      setFormData((preValues) => {
        return { ...preValues, consultant: e.target.value.text };
      });
      setFormData((preValues) => {
        return { ...preValues, staffId: e.target.value.val };
      });

      let depttList = consultant.find((element) => element.val == e.target.value.val).deptt;

      //console.log("deptt List =======> ", depttList);

      // let finalDepttList = depttList.map((option) => ({ val: option, text: option }));
      let finalDepttList = depttList.map((option) => option);
      // console.log("finalDepttList List =======> ", finalDepttList);
      setDeptt(finalDepttList);
    }
  };

  useEffect(() => {
    // getPatientsList_Regn();
    get_IPD_Report_Today();
    getHospitalUnit();
    getRoomNo();
    getConsultants();
  }, []);


  //Form Start
  //*************************************************************************************************** */

  const validateRegn = (fieldValues = regnData) => {
    let temp = { ...errors };
    // console.log("fieldValues =====>", fieldValues);
    if ("mrNo" in fieldValues) temp.mrNo = fieldValues.mrNo ? "" : "required.";
    if ("name" in fieldValues) temp.name = fieldValues.name ? "" : "required.";
    // if ("fhName" in fieldValues) temp.fhName = fieldValues.fhName ? "" : "Field is required.";
    setErrors({
      ...temp,
    });

    if ((fieldValues = formData)) return Object.values(temp).every((x) => x == "");
  };

  const validateForm = (fieldValues = formData) => {
    let temp = { ...errors };
    // console.log("fieldValues =====>", fieldValues);
    // if ("appointmentType" in fieldValues) temp.appointmentType = fieldValues.appointmentType !== "0" ? "" : "required.";
    // if ("validDays" in fieldValues) temp.validDays = parseInt(fieldValues.validDays) >= 0 ? "" : "required.";
    // if ("validUpto" in fieldValues) temp.validUpto = dayjs(fieldValues.validUpto).isValid() ? "" : "Valid-Upto is required.";
    if ("depttName" in fieldValues) {
      temp.depttName = fieldValues.depttName !== "" && fieldValues.depttName !== null ? "" : "required.";
    }
    // if ("consultant" in fieldValues) {
    //   temp.consultant = fieldValues.consultant !== "" && fieldValues.consultant !== null ? "" : "required.";
    // }
    // if ("roomInfo" in fieldValues) {
    //   temp.roomNo = fieldValues.roomInfo[0].roomNo !== "" && fieldValues.roomInfo[0].roomNo !== null ? "" : "required.";
    //   temp.roomDateIn = fieldValues.roomInfo[0].roomDateIn !== "" && fieldValues.roomInfo[0].roomDateIn !== null ? "" : "required.";
    //   temp.roomDateOut = fieldValues.roomInfo[0].roomDateOut !== "" && fieldValues.roomInfo[0].roomDateOut !== null ? "" : "required.";
    // }
    if ("unitName" in fieldValues) {
      temp.unitName = fieldValues.unitName !== "" && fieldValues.unitName !== null ? "" : "required.";
    }

    // if ("rate" in fieldValues) temp.rate = parseInt(fieldValues.rate) >= 0 ? "" : "required.";
    // if ("discPer" in fieldValues) temp.discPer = parseInt(fieldValues.discPer) >= 0 ? "" : "required.";
    // if ("discAmt" in fieldValues) temp.discAmt = parseInt(fieldValues.discAmt) >= 0 ? "" : "required.";
    // if ("paidRs" in fieldValues) temp.paidRs = parseInt(fieldValues.paidRs) >= 0 ? "" : "required.";

    // if ("paymentMode" in fieldValues) {
    //   temp.paymentMode = fieldValues.paymentMode !== "" && fieldValues.paymentMode !== null ? "" : "required.";
    // }

    setErrors({
      ...temp,
    });

    // console.log("temp===>", temp);
    //console.log("Full Data===>", formData);
    if ((fieldValues = formData)) return Object.values(temp).every((x) => x == "");
  };

  const { formData, setFormData, errors, setErrors, handleChange } = MyUseForm(initialValuesIpd, true, validateForm);

  // useEffect(() => {
  //   // console.log("FormData ===>", formData)
  // }, [formData]);


  const handleRoomInfoChange = (index, fieldname, e) => {
    const { value } = e.target;
    if (formData.inOut === "IN") {
      const newRoomInfo = [...formData.roomInfo];
      newRoomInfo[index][fieldname] = value;
      newRoomInfo[index].staffUN = staffUN;
      setFormData((prev) => ({ ...prev, roomInfo: newRoomInfo, }));
    }
    // else if (formData.inOut === "OUT") {
    //   const newRoomInfo = [...dischargeData.roomInfo];
    //   newRoomInfo[index][fieldname] = value;
    //   newRoomInfo[index].staffUN = staffUN;
    //   setDischargeData((prev) => ({ ...prev, roomInfo: newRoomInfo, }));
    // }

  };

  const handleChange_Discharge = (e) => {
    const { name, value } = e.target;
    if (formData.inOut === "OUT") {
      setDischargeData((preValues) => ({ ...preValues, [name]: e.target.value }));
    }
  };



  // useEffect(() => {
  //   console.log("formData ===", formData);
  // }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateRegn()) {
      if (validateForm()) {
        if (formData.staffId !== "") {
          // RegnServices.insertPatient(formData);
          //console.log("Submited Click........", formData);
          // resetForm();
          setLoading(true);
          addOrEdit(formData);
        } else {
          setNotify({
            isOpen: true,
            message: "Please Select the Consultant!",
            type: "error",
          });
        }
      } else {
        setLoading(false);
        setNotify({
          isOpen: true,
          message: "Please enter the value in required fields!",
          type: "error",
        });
      }
    } else {
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "Please enter the value in required fields!",
        type: "error",
      });
    }
  };

  const handleSubmit_Discharge = async (e) => {
    // e.preventDefault();
    if (dischargeData._id !== "") {
      // RegnServices.insertPatient(formData);
      //console.log("Submited Click........", formData);
      // resetForm();
      setLoading(true);       
        //This is for Discharge
        try {
          // console.log("axios patch Start===>", ipdData);
          const res = await axios.patch(`/ipd/${dischargeData._id}`, dischargeData);
          // console.log("axios.patch response===>", res);
          if (res) {
            bindData();
            setNotify({ isOpen: true, message: "Discharge Successfully", type: "success" });
            getDataByMRNo();
          }
        } catch (error) {
          console.error("error=====>", error);
          setLoading(false);
          setNotify({ isOpen: true, message: error.message, type: "error" });
        }
      } else { 
      setNotify({
        isOpen: true,
        message: "Patient already discharged OR enter the required Fields!",
        type: "error",
      });
    }
  };

  const resetForm = () => {
    setFormData(initialValuesIpd);
    setRegnData(initialValuesRegn);
    setRecordForEdit(null);
    setErrors({});
    setNotify({ isOpen: false, message: "", type: "" });
    setMRNoValueForReport("");
    cancelSearch();
    setLoading(false);
    setreportDataForRightTable([]);
    setSearchVal("");
  };

  useEffect(() => {
    if (recordForEdit != null) {
      // console.log('aaaaaaaaaaaaa');
      setFormData({
        ...recordForEdit,
      });
    }
  }, [recordForEdit]);

  //Form End
  //*************************************************************************************************** */

  const get_IPD_Report_Today = async () => {
    //setLoading(true);
    // validate(initialValues);
    try {
      //Below report also used in IPD-Report page
      const res = await axios.get(`/ipd/report/?hid=${hid}&startdate=${dayjs()}&enddate=${dayjs()}`);
      // console.log("res ipd data ===>", res.data);

      if (res) {
        setReportData(res.data);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  const getConsultantFormForPrintWhenSaved = async (id) => {
    setLoading(true);
    try {
      const res = await axios.get(`/ipd/getconsultformforprint/${id}`);
      // console.log("res getconsultformforprint ===>", res.data);

      handlePrintConsultationForm(res.data);
    } catch (error) {
      setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    setLoading(false);
  };

  const bindData = async () => {
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    // await getPatientsList_Regn();
    await get_IPD_Report_Today();
    setLoading(false);
  };

  const addOrEdit = async (ipdData) => {
    // const ipdData = {...formData};
    ipdData.staffUN = staffUN;
    ipdData.ip = ip;

    // console.log("ipdData addOrEdit =========>", ipdData);

    // const queryobj = { params: { hid: hid } }; //<=== this query only for testing
    if (ipdData._id) {
      //This is for Update
      // RegnServices.updatePatient(ipdData);
      try {
        // console.log("axios patch Start===>", ipdData);
        const res = await axios.patch(`/ipd/${ipdData._id}`, ipdData);
        // console.log("axios.patch response===>", res);
        if (res) {
          bindData();
          setNotify({ isOpen: true, message: "Updated Successfully", type: "success" });
          getDataByMRNo();
        }
      } catch (error) {
        console.error("error=====>", error);
        setLoading(false);
        setNotify({ isOpen: true, message: error.message, type: "error" });
      }
    } else {
      //This is for Create new
      try {
        // console.log("axios Insert Start===>", ipdData);
        const res = await axios.post("/ipd", ipdData);

        // console.log("axios Insert response===>", res.data);

        if (res.data) {
          bindData();
          setNotify({ isOpen: true, message: "Saved Successfully", type: "success" });
          getDataByMRNo();
          // console.log("axios Insert getConsultantFormForPrintWhenSaved===>", res.data._id);
          await getConsultantFormForPrintWhenSaved(res.data._id);
        }
      } catch (error) {
        setLoading(false);
        // console.error("error=====>", error);
        if (error.response && error.response.status === 400) {
          // Custom handling for 400 error, such as accessing the error message
          setNotify({ isOpen: true, message: error.response.data.message || "Bad Request", type: "error" });
        } else {
          setNotify({ isOpen: true, message: "Something went wrong, please try again", type: "error" });
        }
      }
    }
  };

  const onDelete = async (ipdNo) => {
    //if (window.confirm("Do you want to delete this record?")) {
    try {
      // console.log("axios.patch===>", Patient);
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      const res = await axios.delete(`/ipd/delete-ipd-records/?hid=${hid}&ipdNo=${ipdNo}`);
      if (res) {
        bindData();
        setNotify({ isOpen: true, message: "Deleted Successfully", type: "success" });
        getDataByMRNo();
      }
    } catch (error) {
      console.error("error=====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
  };

  const handleClickDelete = (data) => {
    if (isDeleteRight) {
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure to delete this record?",
        subtitle: "You can't undo this operation",
        isConfirm: () => {
          onDelete(data.row.ipdNo);
        },
      });
    } else {
      setNotify({ isOpen: true, message: "you do not have the right to delete", type: "error" });
    }
  };

  const columnsIpdForRightTop = [
    //{ field: "regNo", headerName: "#", width: 90, filterable: false, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
    // { field: "name", headerName: "Full name", width: 150, editable: true },
    // { field: "_id", headerName: "_id", type: "number", width: 20, hideable: true },
    {
      field: "mrNo",
      headerName: "MRNo/IPD",
      width: 100,
      // valueGetter: (item) => item.row.mrNo,
      renderCell: (item) => (
        <div>
          <Typography>
            <Controls.Button
              text={item.row.mrNo}
              onClick={() => {
                setMRNoValue(item.row.mrNo);
                // setreportDataForRightTable([]);
                // getDataByMRNo();
              }}
              variant="outlined"
            />
          </Typography>
          <Typography color="textSecondary" sx={{ fontSize: 13 }}>
            {item.row.ipdNo}
          </Typography>
        </div>
      ),
    },
    // { field: "ipdNo", headerName: "IPD No.", width: 100 },
    {
      field: "ipdDate",
      headerName: "IPD Date",
      width: 100,
      valueGetter: (item) => dayjs(item.row.ipdDate).format("DD/MM/YYYY hh:mm a"),
    },
    {
      field: "name",
      headerName: "Name/FName",
      width: 150,
      // valueGetter: (item) => item.row.name,
      renderCell: (item) => (
        <div>
          <Typography>{item.row.name}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: 12 }}>
            {item.row.fhName}
          </Typography>
        </div>
      ),
    },

    {
      field: "age",
      headerName: "Age/Sex",
      width: 100,
      // valueGetter: (item) => item.row.ref_reg_patient.age + "/" + item.row.ref_reg_patient.gender.slice(0, 1),
      // valueGetter: (item) => item.row.age + "(Y)/" + item.row.gender.slice(0, 1),
      renderCell: (item) => (
        <div>
          <Typography>{item.row.age + "/" + item.row.gender.slice(0, 1)}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: 12 }}>
            {item.row.pMobNo}
          </Typography>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      renderCell: (curItem) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              {/* <Controls.ActionButton color="primary" title="View IPD Form" onClick={() => handlePrintView(curItem.row)}>
                <VisibilityIcon />
              </Controls.ActionButton> */}
              <Controls.ActionButton
                color="primary"
                title="Print IPD Form"
                onClick={() => handlePrintConsultationForm(curItem.row)}
              >
                <PrintIcon />
              </Controls.ActionButton>

              <Controls.ActionButton color="primary" title="Edit_TopRight" onClick={() => handleEditIPD(curItem.row)}>
                <ModeEditOutlineIcon />
              </Controls.ActionButton>

              <Controls.ActionButton color="red" title="Delete IPD + Dues + LabReports" onClick={() => handleClickDelete(curItem)}>
                <DeleteForeverIcon />
              </Controls.ActionButton>
            </Stack>
          </>
        );
      },
    },
    // { field: "consultant", headerName: "Consultant", width: 150 },
  ];

  const columnsIpdReport = [
    //{ field: "regNo", headerName: "#", width: 90, filterable: false, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
    // { field: "name", headerName: "Full name", width: 150, editable: true },
    // { field: "_id", headerName: "_id", type: "number", width: 20, hideable: true },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (curItem) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              {/* <Controls.ActionButton color="red" title="Delete" onClick={() => handleClickDelete(curItem)}>
                <DeleteForeverIcon />
              </Controls.ActionButton> */}
              <Controls.ActionButton
                color="primary"
                title="Edit_Bottom_Table"
                onClick={() => handleEditIPD(curItem.row)}
              >
                <ModeEditOutlineIcon />
              </Controls.ActionButton>

              {/* <Controls.ActionButton color="primary" title="View IPD Form" onClick={() => handlePrintView(curItem.row)}>
                <VisibilityIcon />
              </Controls.ActionButton> */}
              <Controls.ActionButton
                color="primary"
                title="Print IPD Form"
                onClick={() => handlePrintConsultationForm(curItem.row)}
              >
                <PrintIcon />
              </Controls.ActionButton>

            </Stack>
          </>
        );
      },
    },
    {
      field: "mrNo",
      headerName: "MRNo",
      width: 80,
      valueGetter: (item) => item.row.mrNo,
    },
    { field: "ipdNo", headerName: "IPD No.", width: 100 },
    {
      field: "ipdDate",
      headerName: "IPD Date",
      width: 100,
      valueGetter: (item) => dayjs(item.row.ipdDate).format("DD/MM/YYYY hh:mm a"),
    },
    { field: "inOut", headerName: "Status", width: 100, valueGetter: (item) => (item.row.inOut === "IN" ? "Admitted" : "Discharge") },
    {
      field: "name",
      headerName: "Name / FHName",
      width: 150,
      // valueGetter: (item) => item.row.name,
      renderCell: (item) => (
        <div>
          <Typography>{item.row.name}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: 12 }}>
            {item.row.fhName}
          </Typography>
        </div>
      ),
    },
    {
      field: "age",
      headerName: "Age/Sex",
      width: 100,
      // valueGetter: (item) => item.row.ref_reg_patient.age + "/" + item.row.ref_reg_patient.gender.slice(0, 1),
      valueGetter: (item) => item.row.age + "/" + item.row.gender.slice(0, 1),
    },
    { field: "consultant", headerName: "Consultant", width: 150 },
    // { field: "gender", headerName: "Gender", width: 80 },
    // { field: "tokenNo", headerName: "TokenNo", width: 100 },
    // { field: "pMobNo", headerName: "Mobile No.", width: 120, valueGetter: (item) => item.row.ref_reg_patient.mobileNo.pMobNo },
    // {
    //   field: "Address",
    //   headerName: "Address",
    //   width: 200,
    //   valueGetter: (item) => `${item.row.ref_reg_patient.pAddress.address || ""}, ${item.row.ref_reg_patient.pAddress.city || ""}`,
    // },
    // {
    //   field: "paidRs",
    //   headerName: "Rs/Mode",
    //   width: 100,
    //   valueGetter: (item) => item.row.paidRs + "/" + item.row.paymentMode,
    // },
    // { field: "paymentMode", headerName: "PaymentMode", width: 80 },

    {
      field: "roomNo",
      headerName: "RoomNo",
      width: 120,
      valueGetter: (item) => item.row.roomNo,
    },

    // { field: "visitNo", headerName: "Visit", width: 80 },
    { field: "depttName", headerName: "DepttName", width: 150 },
  ];

  const handleEditIPD = (data) => {
    if (isEditRight) {
      setMRNoValue(data.mrNo);
      setErrors({});
      setNotify(false);
      console.log("AAAAAAAAAAAAAAAAAA==>", data);

      setRecordForEdit(data);
    } else {
      setNotify({ isOpen: true, message: "you do not have the right to edit!", type: "error" });
    }
  };

  useEffect(() => {
    console.log("dischargeData =======>", dischargeData)
  }, [dischargeData]);

  useEffect(() => {
    getDataByMRNo();
  }, [recordForEdit]);

  useEffect(() => {
    if (recordForEdit != null) {
      // console.log("recordForEdit.mrNo", recordForEdit.mrNo);

      // setRegnData({
      //   ...recordForEdit.ref_reg_patient,
      // });

      // const {}=

      setRegnData({
        ...recordForEdit,
      });

      setFormData({
        ...recordForEdit,
      });

      setMRNoValue(recordForEdit.mrNo);

      // console.log("useEffect recordForEdit ===>", recordForEdit);
    }
  }, [recordForEdit]);

  const getDataByMRNo = async () => {
    try {
      // if (recordForEdit === null) {
      if (mrNoValue) {
        // console.log("getDataByMRNo MRNo  ========> ", mrNoValue);
        setSearchVal("");

        const res = await axios.get(`/ipd/get-patient-regn-profile/?mrNo=${mrNoValue}`);
        // const res = await axios.get(`/regn/find/${mrNoValue}`);
        // console.log("getDataByMRNo Data  ========> ", res.data);
        if (res) {
          // const selectedIPD = originalR eportData.filter((item) => item.mrNo == mrNoValue);
          // console.log("selectedIPD  ========> ", selectedIPD);
          // setreportDataForRightTable(selectedIPD);
          if (res.data) {
            setRegnData(res.data);
            setFormData((preValues) => ({ ...preValues, ref_reg_patient: res.data._id }));
            setFormData((preValues) => ({ ...preValues, mrNo: res.data.mrNo }));
            getAll_Data_Of_One_MRNo();

            if (formData.inOut === "OUT") {
              get_IPDNo();
            };

          } else {
            setNotify({ isOpen: true, message: "Data not found!", type: "error" });
          }
        } else {
          setNotify({ isOpen: true, message: "Data not found!", type: "error" });
        }
      }
      // else {
      //   setNotify({ isOpen: true, message: "Please enter the MRNo!", type: "error" });
      // }
      // } else {
      //   setNotify({ isOpen: true, message: "Please reset the form!", type: "error" });
      // }
    } catch (error) {
      setNotify({ isOpen: true, message: error.message, type: "error" });
      setLoading(false);
    }
  };

  const getAll_Data_Of_One_MRNo = async () => {
    try {
      if (mrNoValue) {
        // console.log("getAll_Data_Of_One_MRNo MRNo  ========> ", mrNoValue);

        const res = await axios.get(`/ipd/?hid=${hid}&mrNo=${mrNoValue}`);

        if (res.data) {
          // const selectedIPD = reportData.filter((item) => item.mrNo == mrNoValue);
          // console.log("setreportDataForRightTable  ========> ", res.data);
          setreportDataForRightTable(res.data);
        } else {
          setNotify({ isOpen: true, message: "Data not found!", type: "error" });
        }
      }
    } catch (error) {
      console.error("Error getAll_Data_Of_One_MRNo ====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
      setLoading(false);
    }
  };

  const handleChangeMRNo = (e) => {
    if (recordForEdit === null) {
      setErrors({});
      setMRNoValue(e.target.value);
      setFormData(initialValuesIpd);
      setRegnData(initialValuesRegn);
      cancelSearch();
      setLoading(false);
      // setRecordForEdit(null);
      setreportDataForRightTable([]);

      if (formData.inOut === "OUT") {
        get_IPDNo();
      };

    } else {
      setNotify({ isOpen: true, message: "Please reset the form!", type: "error" });
    }
    // console.log(mrNoValue)
  };


  const get_IPDNo = async () => {
    //setLoading(true);
    // validate(initialValues);
    try {
      //Below report also used in IPD-Report page
      const res = await axios.get(`/ipd/get-ipdno/?hid=${hid}&mrno=${mrNoValue}`);

      console.log("res ipd data ===>", res.data);

      if (res.data) {
        setDischargeData((preValues) => ({ ...preValues, _id: res.data._id }));
        setDischargeData((preValues) => ({ ...preValues, ipdNo: res.data.ipdNo }));
        setDischargeData((preValues) => ({ ...preValues, staffUN: staffUN }));
        setDischargeData((preValues) => ({ ...preValues, ip: ip }));
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  // const [print, setPrint]=  useState(false);
  // const [printContent, setPrintContent]=  useState(null);

  // <ReactToPrint
  //     trigger={print}
  //     content={printContent}
  //     documentTitle="This is print page title"
  //     pageStyle="print"
  //     ></ReactToPrint>

  const componentRef = useRef();

  const PrintComponent = useReactToPrint({
    content: () => componentRef.current,
  });

  // const handlePrintView = (item) => {
  //   // console.log("item ====>", item); ref={componentRef}
  //   setPrintForm(item);
  //   // useReactToPrint({
  //   setOpenPopup(true);
  //   //   content: () => componentRef.current,
  //   // });
  // };

  const handlePrintConsultationForm = (item) => {
    // console.log("item ====>", item); //ref={componentRef}
    setIsPrintDirect(false);
    setPrintForm(item);
    setOpenPopup(true);
    // PrintComponent();
  };

  // const handlePrintReceipt = (item) => {
  //   // console.log("handlePrintReceipt ====>", item); //ref={componentRef}
  //   setIsPrintDirect(false);
  //   setPrintForm(ConvertDataToPrintableReceipt(item));
  //   setOpenPopupReceipt(true);
  // };

  // const ConvertDataToPrintableReceipt = (item) => {
  //   // console.log("item ====>", item);
  //   // const { mrNo, name, gender, age } = item.ref_reg_patient;
  //   // const { pMobNo } = item.ref_reg_patient.mobileNo;
  //   const {
  //     hid,
  //     ipdNo,
  //     ipdDate: billDate,
  //     billNo,
  //     staffUN,
  //     consultant,
  //     staffId,
  //     rate,
  //     discAmt,
  //     paidRs,
  //     paymentMode,
  //     tblDataRows,
  //     mrNo,
  //     name,
  //     gender,
  //     age,
  //     pMobNo,
  //   } = item;

  //   const others = { feeHead: "IPD Consultation Charges" };

  //   const printData = {
  //     hid,
  //     mrNo,
  //     name,
  //     gender,
  //     age,
  //     pMobNo,
  //     ipdNo,
  //     billDate,
  //     billNo,
  //     staffUN,
  //     consultant,
  //     staffId,
  //     rate,
  //     discAmt,
  //     paidRs,
  //     paymentMode,
  //     tblDataRows,
  //     ...others,
  //   };

  //   // console.log("printData ====>", printData);

  //   return printData;
  // };

  // const handleChangeMRNoForReport = (e) => {
  //   // if (recordForEdit === null) {
  //   setErrors({});
  //   setMRNoValueForReport(e.target.value);

  //   // console.log(mrNoValue)
  // };

  let filterTimeout;

  const handleSearch = async (e) => {
    try {
      clearTimeout(filterTimeout);
      const val = e.target.value;
      setSearchVal(val);
      if (val.length > 1) {
        filterTimeout = setTimeout(async () => {
          const res = await axios.get(`/ipd/search-ipd-regn-data/?hid=${hid}&searchtext=${val}`);

          console.log("RRRRRRRRRRRRRRRRR  ========> ", res.data);

          if (res.data) {
            // const selectedIPD = reportData.filter((item) => item.mrNo == mrNoValue);
            // console.log("selectedIPD  ========> ", selectedIPD);
            setreportDataForRightTable(res.data);
          } else {
            setNotify({ isOpen: true, message: "Data not found!", type: "error" });
          }
        }, 500);
      } else {
        setreportDataForRightTable([]);
      }
    } catch (error) {
      console.error("Error handleSearch ====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
      setLoading(false);
    }

    // const str = val || "";
    // console.log("BindBottomTable Value========>", val);

    //if (val) {
    // const filteredRows = search(reportData, val);
    // console.log("filteredRows =>", filteredRows);
    // setReportData(filteredRows);
    //}
  };

  // const [fieldNamesForSearch] = useState(["name", "mrNo", "ipdNo"]);
  //"mobileNo.pMobNo", pAddress.address
  // const [fieldNamesForSearch] = useState(["mobileNo"]); //"mobileNo.pMobNo",

  // function search(allItems, val) {
  //   return allItems.filter((item) => {
  //     console.log("Items", item);
  //     return fieldNamesForSearch.some((newItem) => {
  //       if (newItem === "name")
  //         return item.ref_reg_patient.name.toString().toLowerCase().indexOf(val.toLowerCase()) > -1;
  //       // else if (newItem === "pAddress")
  //       //   return item.pAddress.address.toString().toLowerCase().indexOf(val.toLowerCase()) > -1;
  //       else return item[newItem].toString().toLowerCase().indexOf(val.toLowerCase()) > -1;
  //     });
  //   });
  // }

  const cancelSearch = () => {
    setreportDataForRightTable([]);
    setSearchVal("");
    // setReportData(reportData);
  };

  return (
    <>
      <PageHeader title="Patient IPD Entry" icon={<PeopleOutlineRoundedIcon fontSize="large" color="primary" />} />
      {/* <ReactToPrint
        trigger={() => <button>Print this out!</button>}
        content={() => componentRef.current}
      /> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {/* <Typography variant="h6" color="inherit" noWrap sx={{ pb: 1 }}>
            IPD Entry
          </Typography> */}
          <MyForm onSubmit={handleSubmit}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.DropdownList
                  label="Action Type"
                  name="inOut"
                  value={formData.inOut}
                  onChange={(e) => {
                    setFormData((preValue) => ({ ...preValue, inOut: e.target.value }));
                    get_IPDNo();
                  }}
                  options={[
                    { val: "IN", text: "Admit" },
                    { val: "OUT", text: "Discharge" },
                  ]}
                />
              </Grid>

              {/* mrNo */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="MRNo"
                  name="mrNo"
                  value={mrNoValue}
                  onChange={handleChangeMRNo}
                  title="press tab key for get record"
                  onKeyDown={(e) => (e.key === "Tab" ? getDataByMRNo() : "")}
                  error={errors.mrNo}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setMRNoValue("");
                            setreportDataForRightTable([]);
                          }}
                          color="primary"
                          sx={{ p: "10px" }}
                          aria-label="directions"
                        >
                          <ClearIcon />
                        </IconButton>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton onClick={getDataByMRNo} color="primary" sx={{ p: "10px" }} aria-label="directions">
                          <DirectionsIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* Name */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="Patient Name"
                  name="name"
                  value={regnData.name}
                  // onChange={handleChange}
                  error={errors.name}
                  // style={{background: "rgb(232, 241, 250)"}}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>

              {/* Father/MotherName */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Input
                  label="Father/Husband Name"
                  name="fhName"
                  value={regnData.fhName}
                  // onChange={handleChange}
                  error={errors.fhName}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{regnData.soDo}</InputAdornment>,
                  }}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>

              {/* Gender/Age */}
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Controls.Input
                  label="Gender/Age"
                  name="gender"
                  value={regnData.gender + " / " + regnData.age}
                  // onChange={handleChange}
                  // options={GenServices.getGender()}
                  // error={errors.gender}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid>

              {/* disease */}
              {/* <Grid item sm={6} md={3}>
                <Controls.Input
                  label="Disease"
                  name="disease"
                  value={regnData.disease}
                  // onChange={handleChange}
                  // error={errors.disease}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid> */}

              {/* mobileNo.pMobNo */}
              {/* <Grid item sm={6} md={3}>
                <Controls.Input
                  label="Patient Mobile Number"
                  name="mobileNo.pMobNo"
                  value={regnData.mobileNo.pMobNo}
                  // onChange={handleChange}
                  // error={errors.pMobNo}
                  style={{ background: "#e9e9e9" }}
                />
              </Grid> */}

              {/* unitName */}
              {formData.inOut === "IN" && <Grid item xs={6} sm={6} md={3} lg={3}>
                <Controls.Autocomplete
                  label="Branch Name"
                  name="unitName"
                  value={formData.unitName}
                  onChange={handleChange}
                  options={unitName.map((option) => option.text)}
                  error={errors.unitName}
                />
              </Grid>}

              {/* <Divider sx={{ width: "100%", height: 10, m: 0.5 }} orientation="horizontal" /> */}

              {/* consultant */}
              {formData.inOut === "IN" && <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Autocomplete
                  label="Consultant"
                  name="consultant"
                  value={formData.consultant}
                  // value={formData.staffId}
                  onChange={(e) => {
                    // handleChange(e);
                    handleConsultantChange(e);
                  }}
                  // onChange={handleChange}
                  // options={consultant.map((option) => option.text)}
                  options={consultant}
                  error={errors.consultant}
                />
              </Grid>}

              {/* depttName */}
              {formData.inOut === "IN" && <Grid item xs={12} sm={6} md={6} lg={6}>
                {/* <Controls.DripdownList */}
                <Controls.Autocomplete
                  label="Department Name"
                  name="depttName"
                  value={formData.depttName}
                  onChange={handleChange}
                  // options={deptt.map((option) => ({val:option.text, text:option.text}))}
                  options={deptt}
                  error={errors.depttName}
                />
              </Grid>}

              {/* RoomNo */}
              {formData.inOut === "IN" && <Grid item xs={12} sm={6} md={6} lg={6}>
                <Controls.Autocomplete
                  label="Room-No."
                  name="roomNo"
                  // value={formData.roomNo}
                  value={formData.roomInfo[0].roomNo}
                  onChange={(e) => handleRoomInfoChange(0, 'roomNo', e)}
                  options={roomNo.map((option) => option.text)}
                // error={errors.roomNo}
                />
              </Grid>}

              {/* admissionDate */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Controls.Input
                  label="Admission Date"
                  name="admissionDate"
                  value={dayjs(formData.admissionDate).format("DD/MM/YYYY")}
                  // onChange={handleChange}
                  // style={{background: "rgb(232, 241, 250)"}}
                  style={{ background: "#e9e9e9" }}
                  disabled={formData.inOut === "OUT"}
                // error={errors.admissionDate}
                />
              </Grid>

              {/* roomDateIn */}
              {formData.inOut === "IN" && <Grid item xs={12} sm={6} md={4} lg={4}>
                <Controls.DatePicker
                  label="Room-Date-In"
                  name="roomDateIn"
                  value={formData.roomInfo[0].roomDateIn}
                  onChange={(e) => formData.inOut === "IN" && handleRoomInfoChange(0, 'roomDateIn', e)}
                  disabled={formData.inOut === "OUT"}
                // error={errors.roomDateIn}
                />
              </Grid>}

              {/* roomDateOut */}
              {formData.inOut === "IN" && <Grid item xs={12} sm={6} md={4} lg={4}>
                <Controls.DatePicker
                  label="Room-Date-Out"
                  name="roomDateOut"
                  value={formData.roomInfo[0].roomDateOut}
                  onChange={(e) => handleRoomInfoChange(0, 'roomDateOut', e)}
                  disabled={formData.inOut === "OUT"}
                // error={errors.roomDateOut}
                />
              </Grid>}

              {/* dischargeDate */}
              {formData.inOut === "OUT" && <Grid item xs={12} sm={6} md={4} lg={4}>
                <Controls.DatePicker
                  label="Discharge Date"
                  name="dischargeDate"
                  value={dischargeData.dischargeDate}
                  onChange={handleChange_Discharge}
                // error={errors.roomDateOut}
                />
              </Grid>}

              {/* patientDisStatus */}
              {formData.inOut === "OUT" && <Grid item xs={12} sm={6} md={8} lg={8}>
                <Controls.DropdownList
                  label="Patient Discharge Status"
                  name="patientDisStatus"
                  value={dischargeData.patientDisStatus}
                  onChange={handleChange_Discharge}
                  options={[
                    { val: "GOOD", text: "GOOD" },
                    { val: "CURED", text: "CURED" },
                    { val: "SYMPTOMS RELIEVED", text: "SYMPTOMS RELIEVED" },
                    { val: "CONDITION STABLE", text: "CONDITION STABLE" },
                    { val: "PARTIAL IMPROVEMENT", text: "PARTIAL IMPROVEMENT" },
                    { val: "DETERIORATION", text: "DETERIORATION" },
                  ]}
                />
              </Grid>}

              {/* IPD No */}
              {formData.inOut === "OUT" && <Grid item xs={12} sm={6} md={4} lg={4}>
                <Controls.Input
                  label="IPD No"
                  name="ipdNo"
                  value={dischargeData.ipdNo}
                  disabled={true}
                // error={errors.roomDateOut}
                />
              </Grid>}

              {/* Button */}
              <Grid item sm={12} alignContent="center">
                <Box m={1} display="flex">
                  <span>
                    <Controls.Button
                      text="Reset"
                      onClick={resetForm}
                      //variant="outlined"
                      startIcon={<RestartAltIcon />}
                    // color="default"
                    // sx={{ flexGrow: 0 }}
                    />
                  </span>
                  <span style={{ mx: "10px", flexGrow: 1, textAlign: "center" }}>
                    {formData.inOut === "IN" && <Controls.Button
                      sx={{ mx: "10px" }}
                      text="Save and Print"
                      //onClick={() => console.log(formData)}
                      //variant="outlined"
                      disabled={loading}
                      type="submit"
                      startIcon={<SaveRoundedIcon />}
                    />}

                    {formData.inOut === "OUT" && <Controls.Button
                      sx={{ mx: "10px" }}
                      text="Save Discharge"
                      onClick={handleSubmit_Discharge}
                      //variant="outlined"
                      disabled={loading}
                      startIcon={<SaveRoundedIcon />}
                    />}

                    <ClipLoader loading={loading} color="#36d7b7" speedMultiplier={1} />
                  </span>
                </Box>
              </Grid>

              {/* <Grid item sm={12}>
          <FormControlLabel control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid> */}

              {/* <Grid item sm={12} md={3}>
          <FormGroup row>  
            
            <TextField label="Test-Name" variant="standard" placeholder="username" />
            </FormGroup>
        </Grid> */}
            </Grid>
          </MyForm>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controls.Input
                label="Searching by MRNo, IPDNo, Name, FHName, MobNo"
                name="search"
                value={searchVal}
                onChange={handleSearch}
                // title="press tab key for get record"
                // onKeyDown={(e) => (e.key === "Tab" ? getDataByMRNo() : "")}
                // error={errors.mrNo}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={cancelSearch} color="primary" sx={{ p: "10px" }} aria-label="directions">
                        <ClearIcon />
                      </IconButton>
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                      <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
                        <SearchIcon />
                        {/* <DirectionsIcon /> */}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <DataGrid
            // heading="Patient IPD Records for Right Table"
            loading={loading}
            columns={columnsIpdForRightTop}
            rows={reportDataForRightTable}
            // showToolbar={false}
            pageSize={40}
            autoPageSize
            boxHeight={350}
          />

          {/* Registration Record */}
          {/* <DataGrid
            heading="Registered Patient"
            loading={loading}
            columns={columnsRegn}
            rows={regnListLeft}
            showToolbar={false}
            margin="dense"
          /> */}
        </Grid>
      </Grid>
      <br></br>
      {/* IPD Record */}
      <DataGrid
        // heading="Patient IPD Records"
        loading={loading}
        columns={columnsIpdReport}
        rows={reportData}
        // showToolbar={false}
        pageSize={40}
        boxHeight={680}
      />
      <Popup title="IPD Consultation Form" openPopup={openPopup} setOpenPopup={setOpenPopup} isCloseOutside={true}>
        <IpdRegnFormPrint {...printForm} isPrintDirect={isPrintDirect} setIsPrintDirect={setIsPrintDirect} />
      </Popup>
      {/* <Popup
        title="IPD Consultation Charges"
        openPopup={openPopupReceipt}
        setOpenPopup={setOpenPopupReceipt}
        isCloseOutside={true}
      >
        <DirectBillPrint
          // billHeading="IPD Bill - Cum - Receipt"
          billHeading="Receipt"
          feeHead="IPD Consultation Charges"
          {...printForm}
          isPrintDirect={isPrintDirect}
          setIsPrintDirect={setIsPrintDirect}
        />
      </Popup> */}
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      Note: The above table will show only today's records.
    </>
  );

  // {
  //   field: "fullName",
  //   headerName: "Full name",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  // },
}
