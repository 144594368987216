import React from "react";
import Box from "@mui/material/Box";
import { Avatar, Card, Paper, Typography } from "@mui/material";
import { margin } from "@mui/system";

export default function PageHeader(props) {
  const { title, subTitle, icon } = props;
  return (
    <>
      {/* <Paper elevation={3} square> */}
      {/* </Paper> */}

      <Box
        sx={{
          //backgroundColor: "yellow",
          display: "flex",
          flexWrap: "wrap",
          //   width: 450,
          //   height: 200
          //   "& > :not(style)": {
          //     m: 1,
          //     width: 128,
          //     height: 128,
          //   },
        }}
      >
        <Paper elevation={0} sx={{ margin: "20px", width: "60px" }}>
          <Card sx={{ height:'50px', paddingLeft:'8px', paddingTop:'8px' }}>{icon}</Card>
        </Paper>
{/* <div style={{ margin: "20px", width: "80px",   }}>
<Card sx={{ margin: "10px" }}>{icon}</Card>
</div> */}

        {/* <Avatar sx={{ margin: "20px", width: "50px" }} variant="rounded">
           {icon}
        </Avatar> */}
        <div style={{ margin: "20px", paddingTop:'5px' }}>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          {/* <Typography
            variant="subtitle2"
            component="div"
            sx={{ opacity: "0.5" }}
          >
            {subTitle}
          </Typography> */}
        </div>
      </Box>
    </>
  );
}
