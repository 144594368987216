
export const login = (user) => {
  return {
    type: "LOGIN",
    payload: user
  };
};

export const logout = () => {
  return {
    type: "LOGOUT"
  };
};

  //***************************************************************** */

export const getStories = (val) => {
    return {
      type: "SET_STORIES",
      payload: val,
    };
  };

  export const showLoading = () => {
    return {
      type: "SET_LOADING"      
    };
  };

  export const removeStory = (val) => {
    return {
      type: "REMOVE_ITEM",
      payload: val,
    };
  };

  //***************************************************************** */

export const incrementNumber = (num) => {
    return {
      type: "INCREMENT",
      payload: num,
    };
  };
  
  export const decrementNumber = (num) => {
    return {
      type: "DECREMENT",
      payload: num,
    };
  };
  //***************************************************************** */
  export const addTodo = (val) => {
    return {
      type: "ADD_TODO",
      payload: {
        id: new Date().getTime().toString(),
        data: val
      }
    };
  };
  
  export const deleteTodo = (id) => {
    return {
      type: "DELETE_TODO",
      id: id
    };
  };
  export const removeTodo = (id) => {
    return {
      type: "REMOVE_TODO"    
    };
  };
  