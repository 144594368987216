import React from "react";
// import axios from "../axios/axios";

const reducerFun = (state, action) => {
  //console.log("action.payload", action.payload);
  // const ipAdd = await getIP();

  switch (action.type) {
    case "LOGIN": {
      {
        return {
          ...state,
          isLogin: true,
          isLogout: false,
          userPayload: action.payload,
          roles:action.payload.roles.role,
          staffUN:action.payload.userName,
          ip: action.payload.ip,
          isEditRight: action.payload.isEditRight,
          isDeleteRight: action.payload.isDeleteRight,
          // hmsPayload:
          //page: action.payload.page,
        };
      }
    }

    // if (action.payload.userName === "admin" && action.payload.password === "1234") {
    //   return {
    //     ...state,
    //     isLogin: true,
    //     isLogout: false,
    //     userName: action.payload.email,
    //     //page: action.payload.page,
    //   };
    // } else {
    //   return {
    //     ...state,
    //     isLogin: false,
    //     isLogout: true,
    //     //page: action.payload.page,
    //   };
    case "LOGOUT":
      return {
        ...state,
        isLogin: false,
        isLogout: true,
        userName:"",
        userPayload: {},
        // hmsPayload:{}
      };

    case "SET_STORIES":
      return {
        ...state,
        hits: action.payload.hits,
        nbPages: action.payload.nbPages,
        page: action.payload.page,
        isLoading: false,
        //   query: action.payload.query
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "REMOVE_ITEM":
      return {
        ...state,
        hits: state.hits.filter((cVal) => cVal.objectID !== action.payload.post_id),
      };
    default:
      return state;
  }
};

export default reducerFun;
