import React, { useEffect, useRef, useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import Controls from "../../components/Controls";
import dayjs from "dayjs";

import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import { useSelector, useDispatch } from "react-redux";

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { HtmlToWord } from "../../utils/HtmlToWord";

export default function OpdRegnFormPrint(props) {

  // const dispatch = useDispatch();
  const hospitalHeader = useSelector((state) => state.HospitalHeader_Reducer);
  // console.log("hospitalHeader ====>", hospitalHeader);
  // const {printForm} = props;
  // console.log("printForm.opdNo ====>", props.opdNo);

  const { isPrintDirect, setIsPrintDirect, billHeading } = props;

  // console.log("billHeading ====>", billHeading);

  const {
    mrNo,
    name,
    gender,
    age,
    pMobNo,
    opdNo,
    opdDate,
    billNo,
    billDate,
    staffUN,
    consultant,
    rate,
    discAmt,
    paidRs,
    paymentMode,    
    feeHead,
    billDetail,
  } = props;
  // const { pMobNo } = props.ref_reg_patient.mobileNo;
  // const { opdNo, opdDate, billNo, staffUN, consultant, paidRs, paymentMode, feeHead, billDetail, } = props;

  // const [totalRate, setTotalRate] = useState(0);
  const [totalDisc, setTotalDisc] = useState(0);
  // const [totalAmount, setTotalAmount] = useState(0);

  const handleClose = (value) => {
    setIsPrintDirect && setIsPrintDirect(false);
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    isPrintDirect && handlePrint();
    handleClose();
    // TableBodyRow();
  }, [isPrintDirect]);

  useEffect(() => {
    if (Array.isArray(billDetail)) {
      let conRs =0.00;
        billDetail.map((item, index) => {
          conRs= parseFloat(conRs) + parseFloat(item.discAmt);
          // console.log("item.discAmt: ========>", conRs)
      });
      conRs = conRs.toFixed(2);
      setTotalDisc(conRs);
    }  //Below code for OPD-Consultation-Bill only
    else{
      setTotalDisc(discAmt || 0);
    }
  }, []);

  const TableBodyRow = () => {
    if (Array.isArray(billDetail)) {
      let totalDiscRs = 0;
      const dynamicRows = billDetail.map((item, index) => {
        return (
          <tr key={index + 1}>
            <td>{index + 1}</td>
            <td style={{ textAlign: "left" }}>{item.feeHead}</td>
            <td>{item.qty}</td>
            <td>{item.rate}</td>
            <td>{item.discAmt}</td>
            <td>{item.netAmt}</td>
          </tr>
        );
      });
      return dynamicRows;
    } else {
      // setTotalRate(paidRs);
      //
      // setTotalAmount(paidRs);
      //Below code for OPD-Consultation-Bill only
      return (
        <tr key="1">
          <td>1</td>
          <td style={{ textAlign: "left" }}>{feeHead}</td>
          <td>1</td>
          <td>{rate || paidRs}</td>
          <td>{discAmt || 0}</td>
          <td>{paidRs}</td>
        </tr>
      );
    }
  };

  const exportToWord = ()=>{
    //var htmlData = document.getElementById(element).innerHTML;
    var htmlData = componentRef.current.innerHTML;
     HtmlToWord(htmlData,'OPD-Consultation-Form.docx');
   }

  return (
    // <h1>{props.opdNo}</h1>
    <div>
      {/* <div style={{ textAlign: "right", paddingRight: "100px" }}> */}
      <div style={{ position:"fixed", top:"100px", left:"50%", }}>
      <Controls.ActionButton color="primary" title="Export to MS-Word" onClick={exportToWord}>
          <FileDownloadIcon />
        </Controls.ActionButton>
        <Controls.ActionButton color="primary" title="Print OPD Form" onClick={handlePrint}>
          <PrintIcon />
        </Controls.ActionButton>
      </div>
      <table
        ref={componentRef}
        align="center"
        style={{ width: "100%", border: "0", backgroundColor: "White" }}
      >
        <tr>
          <td valign="top">
            <table
              align="center"
              style={{ width: "900px", backgroundColor: "White", border: "1px solid #000000" }}
            >             
              <tr>
                <td>
                  <table style={{ width: "100%", border: "1px solid #000000" }}>
                    <tr>
                      <td style={{ width: "50%", borderRight: "1px solid #000000" }}>
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td style={{ width: "25%" }} rowSpan={2}>
                              <img src="logo192.png" alt="Header Image" width={100} height={100} />{" "}
                            </td>
                            <td>
                              <div>
                                <strong> {hospitalHeader.hospitalName} </strong>
                              </div>
                              <div> {hospitalHeader.hospitalAddress} </div>
                              <div> {hospitalHeader.hospitalPhoneNo} </div>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "center" }}> 
                              <strong>{billHeading}</strong>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td style={{ width: "50%" }}>
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td>Pt. Name</td>
                            <td colSpan={3}>
                              : <strong>{name}</strong>{" "}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: "20%" }}>Bill No. </td>
                            <td style={{ width: "30%" }}>: {billNo} </td>
                            <td style={{ width: "20%" }}> Bill Dt. </td>
                            <td style={{ width: "30%" }}>
                              : {dayjs(billDate).format("DD/MMM/YYYY")}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>MR No.</td>
                            <td>: {mrNo} </td> <td> Age/Sex </td>
                            <td>
                              :{age} / {gender}
                            </td>
                          </tr>
                          <tr>
                            <td>OPD No.</td>
                            <td>: {opdNo} </td> <td> MobNo. </td>
                            <td>: {pMobNo} </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td
                  style={{ border: "1px solid #000000", textAlign: "right", paddingRight: "20px" }}
                >
                  Consultant: {consultant}
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ padding: "0px" }}>
                    {/* : {paidRs} {" / "} {paymentMode} {"  / "} User: {staffUN}{" "} */}
                    <table id="tblDirectBilling">
                      <thead>
                        <tr>
                          <th style={{ width: "5%" }}>SrNo.</th>
                          <th style={{ width: "55%" }}>Service Name</th>
                          <th style={{ width: "10%" }}>Qty.</th>
                          <th style={{ width: "10%" }}>Rate</th>
                          <th style={{ width: "10%" }}>Disc.Rs.</th>
                          <th style={{ width: "10%" }}>Amount</th>
                        </tr>
                      </thead>
                      <tbody>{<TableBodyRow />}</tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={4} style={{ textAlign: "right", paddingRight: "10px" }}>
                            <b> Bill Total Amount: </b>
                          </td>
                          <td>
                            <b>{totalDisc}</b>
                          </td>
                          <td>
                            <b>{paidRs}</b>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={5} style={{ textAlign: "right", paddingRight: "10px" }}>
                            Receipt Amount Rs.:
                          </td>
                          <td>
                            <b>{paidRs}</b>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={5} style={{ textAlign: "right", paddingRight: "10px" }}>
                            Dues/Balance Amount:
                          </td>

                          <td>0</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div>
                    Received with thanks from {name}. A Sum of Rs. {paidRs}/-
                    <br />
                    In Words: {paidRs} <br></br>
                    PaymentMode: {paymentMode}
                  </div>

                  <div
                    style={{
                      marginTop: "50px",
                      textAlign: "right",
                      paddingRight: "20px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <table>
                      <tr>
                        <td>
                          <b>(Authorised Signatory)</b>
                        </td>
                      </tr>
                      <tr>
                        <td>{staffUN}</td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  );
}
