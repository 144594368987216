import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "./../axios/axios";
import { addLabTestListMasterData } from "./../redux/actions";
//import { useDispatch } from "react-redux";

// export  async function GetLabTestList(hid) {
//  // const dispatch = useDispatch();

//   try {
//     const res = await axios.get(`/lab/testlist/?hid=${hid}`); //getAll <= here Get All MainHead + Nested(SubHead)
//     console.log("getRecord by mainHeading  ===>", res.data);
//     if (res) {
//       // setRecords(res.data);
//       //dispatch(addLabTestListMasterData(res.data));
//       //   console.log("getRecord res.data ===>", res.data);
//       return res.data;
//     }
//     // setLoading(false);
//     return [];
//   } catch (error) {
//     throw error;
//     // setLoading(false);
//     // setNotify({ isOpen: true, message: error.message, type: "error" });
//   }
// };

export function get_Lab_MainHeading() {
  return [
    "BIOCHEMISTRY",
    "HAEMATOLOGY",
    "SEROLOGY",
    "HORMONES",
    "URINE EXAMINATION",
    "MICROSCOPY EXAMINATION",
    "STOOL EXAMINATION",
    "BODY FLUID ANALYSIS",
    "SEMEN EXAMINATION",
  ];
}

export function getLabUnitName() {
  return [
    " ",
    "--",
    "%",
    "°C",
    "/HPF",
    "10^3uL",
    "10^6/uL",
    "10^9/IL",
    "cell/cumm",
    "cumm",
    "Days",
    "Eu/ml",
    "FL",
    "g/dl",
    "gm%",
    "gm/dl",
    "gms/dl",
    "IU/L",
    "IU/ml",
    "lac/cumm",
    "m/cumm",
    "mEq/L",
    "mg%",
    "mg/dl",
    "mg/l",
    "mg/ml",
    "mgs%",
    "microgm/d",
    "millions",
    "million/cumm.",
    "millions/ml",
    "minutes",
    "min./sec.",
    "mL",
    "mm",
    "mm/1st hr.",
    "mmoI/L",
    "nmoI/L",
    "ng/mL",
    "pg",
    "pg/cell",
    "Sec.",
    "U/L",
    "ug/dL",
    "uIU/mL",    
  ];
}

export function getTableType() {
  return [
    { val: "GENERAL", text: "GENERAL" },
    { val: "URINE", text: "URINE" },
    { val: "BFA", text: "BFA" },
    { val: "WIDAL", text: "WIDAL" },
    { val: "SEMEN-1", text: "SEMEN-1" },
    { val: "SEMEN-2", text: "SEMEN-2" },
    // { val: "SEMEN-FULL", text: "SEMEN-FULL" },

  ];
}

export function getColumnNumber() {
  return [ 
    { val: "1", text: "1" },
    { val: "2", text: "2" },
    { val: "3", text: "3" },
    { val: "4", text: "4" },
    { val: "5", text: "5" },
    { val: "6", text: "6" },
    { val: "7", text: "7" },
    { val: "8", text: "8" },
    { val: "9", text: "9" },
    { val: "10", text: "10" },
  ];
}
