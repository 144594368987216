import * as React from "react";
 import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Controls from "./Controls";
import { createTheme } from "@mui/material/styles";
 
 const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2) + "!important",
    position: "absolute!important",
    top: theme.spacing(5) + "!important",
  },
}));

export default function MyPopupDialog(props) {
  const classes = useStyles();

  const { title, children, openPopup, setOpenPopup, isCloseOutside, maxWidth } = props;

  const handleClose = (value) => { setOpenPopup && setOpenPopup(false) };

  // const handleClose2 = (value) => { setOpenPopup && setOpenPopup(false) };


  return (
    <div> 
      <Dialog
        open={openPopup}
        onClose={isCloseOutside && handleClose}    
        fullWidth     
        maxWidth={maxWidth || "lg"}// 'xs', 'sm', 'md', 'lg',
        classes={{ paper: classes.dialogWrapper }}
      >
        <DialogTitle>
          <div style={{ display: "flex" }}>
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
              {title}
            </Typography>
            <Controls.ActionButton color="red" title="Close" onClick={handleClose}>
              <CloseIcon />
            </Controls.ActionButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
      </Dialog>
    </div>
  );
}
