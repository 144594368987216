import axios from "axios";
import React, { useEffect, useState } from "react";
import getToken from "./getToken";

  
// const axiosInstance = axios.create(
//   {
//     baseURL: process.env.REACT_APP_API_URL,
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: token ? `Bearer ${token}` : "",
//     },
//     timeout: 2000,
//   }
//   //timeout: 30000,
// );

const axiosInstance = axios.create(
  {
    baseURL: process.env.REACT_APP_API_URL,
  }
  //timeout: 30000,
);

axiosInstance.interceptors.request.use(
  (config) => {
    // const token = getToken();
    const token = getToken();
    // config.headers.common["Authorization"] = auth;
    const auth = token ? `Bearer ${token}` : "";
    // console.log('Token nnnnnnnnnnnnnnnnnnnnnnnnnnnnnn', auth);

    config.headers["Authorization"] = auth;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error('interceptors.response ======>',error);

    return Promise.reject(error);
    // if (error.response.status == 403) {
    //   // const navigate = useNavigate();
    //   // alert("Your session has been expired, Please login again");
    //   //refreshToken();
    //   // navigate("/login");
    // }
  }
);

// axios.defaults.headers.common['Authorization'] =  token;

// axios.defaults.headers.post['Authorization'] =  token;

export default axiosInstance;
