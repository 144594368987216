import React, { useEffect, useReducer, useState } from "react";
import dayjs from "dayjs";
import axios from "../../axios/axios";

import PageHeader from "../../components/PageHeader";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import ColorizeIcon from "@mui/icons-material/Colorize";
import { Typography, InputAdornment, Box, Paper, TableContainer } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// import Paper from "@mui/material/Paper";
// import InputBase from "@mui/material/InputBase";
// import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
// import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

import DataGrid from "../../components/useDataGrid";
import Popup from "../../components/Popup";
import Controls from "../../components/Controls";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/Notification";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import { Stack } from "@mui/system";
import { MyUseForm, MyForm } from "../../components/useForm";

import { useGlobalContext } from "../../context/Context";

const initialPatientProfile = {
  // _id: "",
  //hid: "2",
  labResultNo: "",
  mrNo: "",
  iopNo: "",
  name: "",
  age: "",
  gender: "",
};
const initialValues = [
  {
    _id: "", //<== this is Main Object ID
    reportDate: "",
    isResultAdded: false,
    testList: [
      {
        _id: "",
        //testName: "",
        //normalValue: "",
        result: "",
        //unit: "",
        //sorting: 1,
        staffUN: "",
        ip: "",
      },
    ],
    staffUN: "",
    ip: "",
  },
];

export default function AddResult() {
  const { staffUN, ip, isEditRight, isDeleteRight } = useGlobalContext();

  const [hid] = useState(2);

  const [searchVal, setSearchVal] = useState("");

  const [dateRange, setDateRange] = useState({
    startdate: dayjs(),
    enddate: dayjs(),
  });

  const [loading, setLoading] = useState(false);

  //   const dispatch = useDispatch();
  // const AllsubHeadings = useSelector((state) => state.subHeadingList_Reducer.mainHeading);
  //   const hook_AllTestList = useSelector((state) => state.LabTestList_Reducer.testData);
  // const records = useSelector((state)=> state.LabTestList_Reducer.testData);
  //console.log("records==>  ", records);
  // const [formData, setFormData] = useReducer((state, newState)=>( ({...state, ...newState}), initialValues ));
  //   const [allLabTestList, setAllLabTestList] = useState([]);
  //   const [feeHead_id, setFeeHead_id] = useState("");
  // const [records, setRecords] = useState([]);
  // const [dataForResultUpd, setDataForResultUpd] = useState([]);

  const [dataTopTable, setDataTopTable] = useState([]);
  const [originalReportData, setOriginalReportData] = useState([]);

  const [patientProfile, setPatientProfile] = useState(initialPatientProfile);

  // const [labHeadTobeAdded, setLabHeadTobeAdded] = useState([]);

  // const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    isConfirm: null,
    title: "title",
    subtitle: "subtitle",
  });

  const validate = (fieldValues = formData) => {
    let temp = { ...errors };

    // console.log("fieldValues =====>", fieldValues.dob);
    if ("mrNo" in fieldValues) temp.mrNo = fieldValues.mrNo ? "" : "required.";
    if ("iopNo" in fieldValues) temp.iopNo = fieldValues.iopNo ? "" : "required.";
    if ("name" in fieldValues) temp.name = fieldValues.name ? "" : "required.";
    if ("billNo" in fieldValues) temp.billNo = fieldValues.billNo ? "" : "required.";
    if ("billDate" in fieldValues) temp.billDate = fieldValues.billDate ? "" : "required.";
    if ("refBy" in fieldValues) temp.refBy = fieldValues.refBy ? "" : "required.";
    if ("depttName" in fieldValues) temp.depttName = fieldValues.depttName ? "" : "required.";
    // if ("diet Type" in fieldValues) temp.diet Type = fieldValues.diet Type != "0" ? "" : "required.";

    // console.log("fieldValues.testList", fieldValues.testList);

    // if (fieldValues.testList != undefined) {
    //   if (fieldValues.testList.length <= 0) {
    //     temp.testHeads = "required.";
    //   } else {
    //     temp.testHeads = "";
    //   }
    // }

    setErrors({
      ...temp,
    });
    // console.log("temp===>", temp);
    if ((fieldValues = formData)) return Object.values(temp).every((x) => x == "");
  };

  const [formData, setFormData] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const [sampleDate, setSampleDate] = useState(null);
  const [reportDate, setReportDate] = useState(null);

  const [isReportUpdate, setIsReportUpdate] = useState(false);

  // useEffect(() => {
  //   console.log("patientProfile ", patientProfile);
  // }, [patientProfile]);

  const handleChange = (e) => {
    const { name, value } = e.target;
  };
  // const { formData, setFormData, errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);
  // const { errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);

  const getRecordForEnterResult = async (labResultNo) => {
    //setLoading(true);
    // validate(initialValues);
    try {
      if (labResultNo) {
        const res = await axios.get(`/lab/addtest/getrecordforenterresult/?hid=${hid}&labResultNo=${labResultNo}`);
        // console.log("getRecordForEnterResult DDDDDDDDDDDDDDDDDD  ===>", res.data);
        let data = res.data;

        if (data) {
          // data["reportDate"] = dayjs();
          setFormData(data);
          // setDataForResultUpd(data);
          setErrors({});

          const viewData = {
            labResultNo: data[0].labResultNo,
            mrNo: data[0].mrNo,
            iopNo: data[0].iopNo,
            name: data[0].name,
            age: data[0].age,
            gender: data[0].gender,
          };

          setSampleDate(data[0].sampleDate);
          setReportDate(dayjs(data[0].reportDate).isValid() ? data[0].reportDate : dayjs());

          // data.sampleDate = dayjs(sampleDate).isValid() ? sampleDate : dayjs().add(-30, "minutes");
          // data.reportDate = dayjs(data[0].reportDate).isValid() ? data[0].reportDate : dayjs();
          setPatientProfile(viewData);
          // console.log("labResultNo ===>", labResultNo);
        }
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.error("error ===>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  const getReportForUpdate = async () => {
    if (isEditRight) {
      try {
        const labResultNo = patientProfile?.labResultNo;
        // console.log("BBBBBBBBB", labResultNo) //'LBR23-138'
        if (labResultNo) {
          await getRecordForEnterResult(labResultNo);
        }
      } catch (error) {
        // setLoading(false);
        console.error("error ===>", error);
        setNotify({ isOpen: true, message: error.message, type: "error" });
      }
    } else {
      setNotify({ isOpen: true, message: "you do not have the right to edit!", type: "error" });
    }
  };

  //const handleAddTestToState = (data) => {
  //  data["iopNo"] = data["iopNo"];
  // data["hid"] = 2;
  // //data["refBy"] = data["consultant"];
  // data["staffUN"] = "AUTO";
  // data["ip"] = "0.0.0.0";
  // data["mainHeading"] = "";
  // data["subHeading"] = "";
  // data["testList"] = [];
  // data["reportDate"] = dayjs();
  // setFeeHead_id(data._id);
  // delete data["_id"];
  // setFormData(data);
  // setErrors({});
  //};

  // useEffect(() => {
  //   console.log("formData ===========> ", formData);
  // }, [formData]);

  const getPatientsRecord = async () => {
    //setLoading(true);
    // validate(initialValues);
    try {
      const res = await axios.get(
        `/lab/addtest/getalldataforaddresult/?hid=${hid}&startdate=${dateRange.startdate}&enddate=${dateRange.enddate}`
      );
      // console.log("getPatientsRecord BBBBBBBBBBBBBBBBBBBB  ===>", res.data);

      if (res) {
        setOriginalReportData(res.data);
        setDataTopTable(res.data);
        setSearchVal("");
        // console.log("setDataTopTable ===>", res.data);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.error("error ===>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  useEffect(() => {
    getPatientsRecord();
  }, []);

  useEffect(() => {
    // console.log("dateRange=", dateRange);
    getPatientsRecord();
  }, [dateRange]);

  useEffect(() => {
    setTimeout(() => {
      if (isReportUpdate) {
        bindData();
        setIsReportUpdate(false);
      }
    }, 1000);
    // console.log('ppppppppppppppppppppp')
  }, [isReportUpdate]);

  const handleSubmitSaveRecord = (e) => {
    e.preventDefault();
    // alert('aaaaaaaa')
    // if (validate()) {
    if (patientProfile.mrNo) {
      const isSaved = formData.map(async (row, index) => {
        // console.log("Final Values to be sent =======> index " + index + " ===", row);
        return await addOrEdit(row);
      });
      // console.log('isSaved == >', isSaved) ==> this is return the promise so that use the state for handle this problem.
      // console.log('isReportUpdate == >', isReportUpdate)
      // if (isReportUpdate === true) {
      //   bindData();
      // }
      // else {
      //   setNotify({ isOpen: true, message: "Please try again!", type: "error" });
      // }
    } else {
      setNotify({ isOpen: true, message: "Please select the patient Record!", type: "error" });
    }
    // } else {
    //   setNotify({ isOpen: true, message: "Please enter the value in required fields!", type: "error" });
    //   //console.log(errors)
    // }
  };

  const resetForm = () => {
    setFormData(initialValues);
    setErrors({});
    getPatientsRecord();
    setNotify({ isOpen: false, message: "", type: "" });
    setPatientProfile(initialPatientProfile);
    // setMRNoValueForReport("");
  };

  const bindData = () => {
    resetForm();
    // setRecordForEdit(null);
    setFormData(initialValues);
    getPatientsRecord();
  };

  const addOrEdit = async (data) => {
    // const data = {...formData};
    // console.log("data addOrEdit =========>", data);
    // const queryobj = { params: { mrNo: 1234 } }; //<=== this query only for testing
    if (data._id) {
      //This is for Update
      // RegnServices.updatePatient(data);
      try {
        data.sampleDate = dayjs(sampleDate).isValid() ? sampleDate : dayjs().add(-30, "minutes");
        data.reportDate = dayjs(reportDate).isValid() ? reportDate : dayjs();

        data.isResultAdded = true;

        // if (data.reportDate === null) {
        //   // console.log("if (data.reportDate === null =====>");
        //   data.reportDate = dayjs();
        //   // data.isResultAdded = true;
        //   // console.log("axios patch Start===>", data);
        // }
        // if (data.isResultAdded === false) {
        //   // console.log("if (data.isResultAdded === false)  ====>");
        //   // data.reportDate = dayjs();
        //   data.isResultAdded = true;
        // }

        if (dayjs(data.sampleDate).isAfter(dayjs(data.reportDate))) {
          setNotify({ isOpen: true, message: "Sample Date must be less than Report Date", type: "error" });
          setIsReportUpdate(false);
          // return false;
        } else {
          const res = await axios.patch(`/lab/addtest/${data._id}`, data); //, queryobj);
          // console.log("axios.patch response===>", res);
          if (res) {
            // bindData();
            setNotify({ isOpen: true, message: "Updated Successfully", type: "success" });
            // return true;
            setIsReportUpdate(true);
          }
        }
      } catch (error) {
        console.error("error=====>", error);
        setNotify({ isOpen: true, message: error.message, type: "error" });
        // return false;
        setIsReportUpdate(false);
      }
    } else {
      setNotify({ isOpen: true, message: "Please select the Patient!", type: "error" });
      // return false;
      setIsReportUpdate(false);
    }
  };

  const onDelete = async (id) => {
    //if (window.confirm("Do you want to delete this record?")) {
    try {
      // console.log("axios.patch===>", Patient);
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      const res = await axios.delete(`/lab/addtest/onerecorddelete/?hid=${hid}&labResultNo=${id}`);
      if (res) {
        setNotify({ isOpen: true, message: "Deleted Successfully", type: "success" });
        await getPatientsRecord();
      }
    } catch (error) {
      console.error("error=====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    // RegnServices.deletePatient(id);
  };

  const handleClickDelete = (data) => {
    //if (window.confirm("Do you want to delete this record?")) {
    if (isDeleteRight) {
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure to delete this record?",
        subtitle: "You can't undo this operation",
        isConfirm: () => {
          onDelete(data._id);
        },
      });
    } else {
      setNotify({ isOpen: true, message: "you do not have the right to delete", type: "error" });
    }
  };

  const AddNestedTest_Direct = async (row) => {
    console.log("AddNestedTest_Direct ===", row);
    //addnestedlabtest_direct
    try {
      const res = await axios.patch(`/lab/addtest/addnestedlabtest_direct/?hid=${hid}&labResultNo=${row.labResultNo}`); //, queryobj);
      // console.log("axios.patch response===>", res);
      if (res) {
        //bindData();
        setNotify({ isOpen: true, message: "Nested Test Updated Successfully", type: "success" });
      }
    } catch (error) {
      console.error("error=====>", error);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
  };

  // const openInEdit = (data) => {
  //   // console.log("data  aaaaaaa", data);
  //   setFormData(data);
  // };

  const columnsPatientList = [
    // { field: "_id", headerName: "id", width: 250 },
    {
      field: "delete",
      headerName: "Del",
      width: 50,
      sortable: false,
      disableColumnMenu: true,
      // valueGetter: (item) => item.row.mrNo,
      renderCell: (item) => (
        <div>
          <Stack direction="row" spacing={1}>
            <Controls.ActionButton
              color="red"
              title="Delete"
              sx={{ fontSize: "9px" }}
              onClick={() => handleClickDelete(item.row)}
            >
              <DeleteForeverIcon />
            </Controls.ActionButton>
          </Stack>
        </div>
      ),
    },

    {
      field: "mrNo",
      headerName: "MRNo/LabNo",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      // valueGetter: (item) => item.row.mrNo,
      renderCell: (item) => (
        <div>
          <Typography>
            <Controls.Button
              title="Add Nested Test"
              text={item.row.mrNo}
              onClick={() => AddNestedTest_Direct(item.row)}
              variant="outlined"
              //variant="outlined"
              // startIcon={<RestartAltIcon />}
              // color="default"
            />
          </Typography>
          <Typography color="textSecondary" sx={{ fontSize: 13 }}>
            {item.row.labResultNo}
          </Typography>
        </div>
      ),
    },

    {
      field: "billNo",
      headerName: "BillNo/Date",
      width: 110,
      // valueGetter: (item) => item.row.mrNo,
      renderCell: (item) => (
        <div>
          <Typography>{item.row.billNo}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: 13 }}>
            {dayjs(item.row.billDate).format("DD/MMM/YYYY")}
          </Typography>
        </div>
      ),
    },

    // { field: "billNo", headerName: "BillNo", width: 100 },
    // { field: "billDate", headerName: "BillDate", width: 120 },
    // {
    //   field: "billDate",
    //   headerName: "Bill Date",
    //   width: 100,
    //   valueGetter: (item) => dayjs(item.row.billDate).format("DD/MM/YYYY hh:mm a"),
    // },

    // const { name, gender, age } = item.ref_reg_opd.ref_reg_patient;
    // const { pMobNo } = item.ref_reg_opd.ref_reg_patient.mobileNo;

    // { field: "name", headerName: "Name", width: 200, valueGetter: (item) => item.row.ref_reg_opd.ref_reg_patient.name },
    // { field: "name", headerName: "Name", width: 200 },

    {
      field: "name",
      headerName: "name/Age",
      width: 150,
      // valueGetter: (item) => item.row.mrNo,
      renderCell: (item) => (
        <div>
          <Typography>{item.row.name}</Typography>
          <Typography color="textSecondary" sx={{ fontSize: 13 }}>
            {item.row.age + "/ " + item.row.gender}
          </Typography>
        </div>
      ),
    },

    {
      field: "actions",
      headerName: "Add",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (curItem) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              <Controls.ActionButton
                color="primary"
                title="Get Record"
                onClick={() => getRecordForEnterResult(curItem.row.labResultNo)}
              >
                {/* <ColorizeIcon /> */}
                <AddIcon />
              </Controls.ActionButton>
              {/* <Controls.Button
                color="primary"
                title="Sample Collect"
                text="Add"
                onClick={() => getRecordForEnterResult(curItem.row.labResultNo)}
              >
                {/* <ColorizeIcon/> Sample Collect * /}
              </Controls.Button> */}

              {/* <Controls.ActionButton
                color="red"
                title="Delete"
                onClick={() => handleClickDelete(curObj.row)}
              >
                <CloseIcon />
              </Controls.ActionButton> */}
            </Stack>
          </>
        );
      },
    },
    // {
    //   field: "gender",
    //   headerName: "Age/Gender",
    //   width: 100,
    //   valueGetter: (item) => item.row.age + "/ " + item.row.gender.slice(0, 1),
    // },
    // { field: "paidRs", headerName: "PaidRs", width: 120 },
    // { field: "paymentMode", headerName: "paymentMode", width: 120 },

    // { field: "pMobNo", headerName: "MobNo", width: 120 },
    // { field: "email", headerName: "Email", width: 200 },
    // { field: "subHeading", headerName: "Lab-Test-Heading", width: 300,  fontSize: "9px"  },

    {
      field: "subHeading",
      headerName: "Test-Heading",
      width: 200,
      // valueGetter: (item) => item.row.mrNo,
      renderCell: (item) => (
        <div>
          <Typography color="textSecondary" sx={{ fontSize: 12 }}>
            {item.row.subHeading.slice()}
          </Typography>
        </div>
      ),
    },
  ];

  //   const handleTestToBeAdded = (e) => {
  //     // alert("FFFFFFFFFF");
  //     setErrors({});
  //     // console.log(e.target.value);

  //     if (e.target.value != null) {
  //       const data = e.target.value;
  //       setFormData((preValue) => ({ ...preValue, mainHeading: data.mainHeading }));
  //       setFormData((preValue) => ({ ...preValue, subHeading: data.subHeading }));
  //       setFormData((preValue) => ({ ...preValue, testList: data.testList }));

  //       setFormData((preValue) => ({ ...preValue, staffUN: "LSK" }));
  //       setFormData((preValue) => ({ ...preValue, ip: "192.168.0.1" }));
  //     }
  //   };

  // const addNestedLabTest = () => {
  //   if (headNamesObj != null) {
  //     const data = headNamesObj;
  //     setFormData((preValue) => ({ ...preValue, mainHeading: data.mainHeading }));
  //     setFormData((preValue) => ({ ...preValue, subHeading: data.subHeading }));
  //     setFormData((preValue) => ({ ...preValue, testList: data.testList }));

  //     setFormData((preValue) => ({ ...preValue, staffUN: "LSK" }));
  //     setFormData((preValue) => ({ ...preValue, ip: "192.168.0.1" }));
  //   }
  // };

  const updateNestedStateItem = (e, mainObj_Index, nestedIndex) => {
    let result = e.target.value;

    // console.log("Result ==========> ", result);
    // console.log("mainObj_Index ==========> ", mainObj_Index);
    // console.log("nestedIndex ==========> ", nestedIndex);

    // newArray[elementsIndex] = {...newArray[elementsIndex], completed: !newArray[elementsIndex].completed}
    // let index = formData.testList.findIndex((x) => x._id === _id);

    if (nestedIndex !== -1) {
      let newObj = formData[mainObj_Index];

      newObj.reportDate = dayjs();
      newObj.isResultAdded = true;
      newObj.staffUN = "AUTO";
      newObj.ip = "127.0.0.1";
      newObj.testList[nestedIndex].result = result;
      newObj.testList[nestedIndex].staffUN = "AUTO";
      newObj.testList[nestedIndex].ip = "127.0.0.1";

      setFormData((preValue) => [...preValue.slice(0, mainObj_Index), newObj, ...preValue.slice(mainObj_Index + 1)]);
    }
  };

  const handleSearch = async (e) => {
    const val = e.target.value;
    // console.log("BindBottomTable Value========>", val);
    setSearchVal(val);
    const filteredRows = search(originalReportData, val);
    setDataTopTable(filteredRows);
  };

  const [fieldNamesForSearch] = useState(["name", "mrNo", "age"]);

  function search(allItems, val) {
    return allItems.filter((item) => {
      return fieldNamesForSearch.some((newItem) => {
        return item[newItem].toString().toLowerCase().indexOf(val.toLowerCase()) > -1;
      });
    });
  }

  const cancelSearch = () => {
    setSearchVal("");
    setDataTopTable(originalReportData);
  };

  return (
    <div>
      <h2>Add Lab Test Result</h2>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Controls.DatePicker
                label="Start-Date"
                name="startDate"
                value={dayjs(dateRange.startdate).format("DD/MMM/YYYY")}
                onChange={(e) => setDateRange({ ...dateRange, startdate: e.target.value })}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Controls.DatePicker
                label="End-Date"
                name="endDate"
                value={dayjs(dateRange.enddate).format("DD/MMM/YYYY")}
                onChange={(e) => setDateRange({ ...dateRange, enddate: e.target.value })}
              />
            </Grid>

            <Grid item xs={2} sm={2} md={2} lg={1}>
              <Controls.Button variant="outlined" text="Go" onClick={() => getPatientsRecord()} />
            </Grid>

            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Controls.Input
                label="Search"
                name="search"
                value={searchVal}
                onChange={handleSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={cancelSearch} color="primary" sx={{ p: "10px" }} aria-label="directions">
                        <ClearIcon />
                      </IconButton>
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                      <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Box sx={{ mb: "50px" }}>
            <DataGrid
              heading=""
              columns={columnsPatientList}
              rows={dataTopTable}
              pageSize={100}
              showToolbar={false}
              boxHeight={1500}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          {/* <MyForm onSubmit={handleSubmit}> */}
          <Grid container spacing={2}>
            {/* labResultNo */}
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Controls.Input
                label="Lab No."
                name="labResultNo"
                value={patientProfile.labResultNo}
                onChange={(e) => setPatientProfile((preValue) => ({ ...preValue, labResultNo: e.target.value }))}
                // disabled={!isEditRight}
                // options={GenServices.get_Lab_MainHeading()}
                // error={errors.labResultNo}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                      <IconButton
                        onClick={() => getReportForUpdate()}
                        color="primary"
                        sx={{ p: "10px" }}
                        aria-label="directions"
                      >
                        <DirectionsIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* mrNo */}
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Controls.Input
                label="MRNo"
                name="mrNo"
                value={patientProfile.mrNo}
                // onChange={handleChange}
                // options={GenServices.get_Lab_MainHeading()}
                error={errors.mrNo}
                style={{ background: "#e9e9e9" }}
              />
            </Grid>

            {/* name */}
            <Grid item xs={6} sm={6} md={5} lg={5}>
              <Controls.Input
                label="Name"
                name="name"
                value={patientProfile.name}
                // sx={{fontWeight:"bold"}}
                // onChange={handleChange}
                error={errors.name}
                style={{ background: "#e9e9e9", fontWeight: "bold" }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {/* testList to be Saved */}
              {/* <Box sx={{ mt: "15px", mb: "15px" }}>
            <Grid container spacing={2}></Grid>
          </Box> */}
              {formData[0]._id == "" ? (
                <div style={{ padding: "40px", textAlign: "center" }}>
                  <h3>Data Not Found. Please select the Test &#x1F603; </h3>
                </div>
              ) : (
                <>
                  <Paper sx={{ mt: "15px", mb: "15px" }}>
                    <Box sx={{ mb: "10px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Controls.DateTimePicker
                            label="Sample Date"
                            name="sampleDate"
                            value={sampleDate}
                            // options={GenServices.getDietType}
                            // onChange={(e) => updateNestedStateItem(e, topIndex, index)}
                            onChange={(e) => {
                              setSampleDate(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Controls.DateTimePicker
                            label="Report Date"
                            name="reportDate"
                            value={reportDate}
                            // options={GenServices.getDietType}
                            onChange={(e) => {
                              setReportDate(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <TableContainer>
                      {Array.isArray(formData) &&
                        formData.map((topRow, topIndex) => (
                          <table id="tblGeneral">
                            <thead>
                              <tr>
                                {/* <th style={{ width: "200px" }}> aaaaa</th> */}
                                <th style={{ width: "300px" }}>{topRow.subHeading}</th>
                                <th style={{ width: "200px" }}>Result</th>
                                <th style={{ width: "200px" }}>Normal Value / Unit</th>
                                {/* <th style={{ width: "150px" }}>Unit</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {topRow.length <= 0 || topRow.testList.length <= 0 ? (
                                <tr key={topIndex}>
                                  <td colSpan={3}>
                                    <div style={{ padding: "40px", textAlign: "center" }}>
                                      <h3> Data Not Found! </h3>
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                // (
                                //   <tr key={topIndex+9999}>
                                //     <td colSpan={5}>
                                //       <div style={{ padding: "40px", textAlign: "center" }}>
                                //         <h6> {topRow.mainHeading} </h6>
                                //       </div>
                                //     </td>
                                //   </tr>
                                // ) &&
                                topRow.testList.map((row, index) => {
                                  // const { testName, normalValue, unit, sorting, isValueUpdated } = row;
                                  const { testName, normalValue, unit, result, _id } = row;
                                  return (
                                    <tr key={index}>
                                      {/* <td></td> */}
                                      <td>{testName}</td>
                                      <td>
                                        {/* {result} */}
                                        <Controls.Input
                                          label="Result"
                                          name="result"
                                          value={formData[topIndex].testList[index].result}
                                          // options={GenServices.getDietType}
                                          onChange={(e) => updateNestedStateItem(e, topIndex, index)}
                                        />
                                      </td>
                                      <td>
                                        {normalValue} {unit}
                                      </td>
                                      {/* <td></td> */}
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                            <tfoot></tfoot>
                          </table>
                        ))}
                    </TableContainer>
                  </Paper>
                  <Box sx={{ mt: "15px", mb: "15px", textAlign: "center" }}>
                    <Stack direction="row" spacing={20}>
                      <Controls.Button
                        text="Reset"
                        onClick={resetForm}
                        //variant="outlined"
                        startIcon={<RestartAltIcon />}
                        // color="default"
                      />

                      <Controls.Button
                        sx={{ mx: "10px" }}
                        text="Save"
                        onClick={handleSubmitSaveRecord}
                        //variant="outlined"
                        // subHeading="submit"
                        // type="submit"
                        startIcon={<SaveRoundedIcon />}
                      />
                    </Stack>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>

          {/* isActive */}
          {/* <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.Checkbox
              label="Is-Active"
              name="isActive"
              value={formData.isActive}
              onChange={handleChange}
              // error={errors.isActive}
            />
          </Grid> */}
        </Grid>
      </Grid>
      <br></br>

      <br></br>
      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </div>
  );
}
