import React from "react";

export default function TableForPrintBill(props) {
  // console.log("DDDDDDDDDDDDDDDDD====", props);

  //   console.log("AAAAAAAAAAAAAAAAAAAAAAAAA====",props); id="tblDirectBilling"
  const { feeHeadMain, groupBy, reportType } = props;
  // return;

  const renderElement = () => {
    return (
      <table className="tbl11" style={{ fontWeight: "bold", border: "0px solid white", width: "100%" }}>
        <thead></thead>
        <tbody>
          <tr>
            <td colSpan={3} style={{ textAlign: "left", paddingLeft: "10px", fontWeight: "bold", fontSize: "20px" }}>
              {feeHeadMain}
            </td>
          </tr>
          {/* {Array.isArray(testList) && */}
          {groupBy.map((item, index) => (
            //testName, normalValue, unit, result
            <tr key={index + 1}>
              {/* <td>{index + 1}</td> */}
              <td style={{ textAlign: "left", width: "50%", fontSize: "14px", paddingLeft: "25px" }}>
                {item.consultant}
              </td>
              <td style={{ textAlign: "center", width: "25%" }}>
                {reportType === "SUMMARY" ? item.NoOfBills : item.amtRs + " x " + item.NoOfBills}
              </td>
              <td style={{ textAlign: "center", width: "25%" }}>₹{item.paidRs}</td>
            </tr>
          ))}
        </tbody>
        <tfoot></tfoot>
      </table>
    );

    return null;
  };

  return renderElement();
}
