import React, { useState } from "react";
import dayjs from "dayjs";
// import useAxiosPrivate from "../hooks/useAxiosPrivate11111111";
import axios from "../axios/axios";
// import "dayjs/locale/en-in";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { countryList } from "./CountryList";
import { stateList } from "./StateList";

export function getOrdinalWithIncrement(value = 1) {
  // var strPart = i.match(/[^\d]/);
  var i = 1;
  if (value === 0 || value === null || value === undefined || value === "" || value === "0") {
    // console.log("blank");
    i = 1;
  } else if (typeof value === "string") {
    // console.log("string");
    i = parseInt(value.match(/\d+/)[0]) + 1;
  } else if (typeof value === "number") {
    // console.log("number");
    i = value;
  }

  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export function generateRandomId() {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export function getAuthRoles() {
  // return [
  //   "RECEPTIONIST",
  //   "DOCTOR",
  //   "LABORATORY",
  //   "ADMIN",
  //   "PATIENT",
  //   "STAFF",
  //   "SUPERADMIN",
  //   "SENIORDOCTOR",
  //   "NURSE",
  //   "PHARMACIST",
  //   "RADIOLOGIST",
  // ];
  // return {
  //   RECEPTIONIST: "RECEPTIONIST",
  //   DOCTOR: "DOCTOR",
  //   LABORATORY: "LABORATORY",
  //   ADMIN: "ADMIN",
  // };
  return [
    "RECEPTIONIST",
    "LABORATORY",
    "ADMIN",
    //"SUPERADMIN",
  ];
}

export const FeeHeadTypeValues = [
  { val: "GENERAL", text: "GENERAL" },
  { val: "LAB", text: "LAB" },
  { val: "DOCTOR", text: "DOCTOR" },
  { val: "ROOM", text: "ROOM" },
  { val: "LAUNDRY", text: "LAUNDRY" },
];

export const getDietType = [
  { val: "Fasting", text: "Fasting" },
  { val: "PP", text: "PP" },
  { val: "Random", text: "Random" },
];

export function getGSTRate() {
  return [
    // { val: "", text: "None"  },
    { val: "0", text: "0%" },
    { val: "0.1", text: "0.1%" },
    { val: "0.25", text: "0.25%" },
    { val: "1", text: "1%" },
    { val: "1.5", text: "1.5%" },
    { val: "3", text: "3%" },
    { val: "5", text: "5%" },
    { val: "6", text: "6%" },
    { val: "7.5", text: "7.5%" },
    { val: "9", text: "9%" },
    { val: "12", text: "12%" },
    { val: "14", text: "14%" },
    { val: "18", text: "18%" },
    { val: "28", text: "28%" },
  ];
}

//here Get HospitalUnit, Department, Designation, AppointmentType, OpdRoomNo
export const getEntityInnnerData = async (hid, entityName) => {
  // console.log('Start getEntityInnnerData AAAAAAAAAAAAAAA');
  let result = [];
  try {
    const res = await axios.get(`/master/getentitydata/?hid=${hid}&entityname=${entityName}`);
    //console.log("getRecord by appointmentType  ===>", res.data);
    //   console.log("getRecord by res.status  ===>", res.status);
    if (res.data) {
      const R1 = res.data.payload.sort((a, b) => (a.val > b.val ? 1 : -1));
      //const R1 = res.data.payload;

      result = R1.filter((item) => item.isActive == true);
      // R1.map((item) => {
      //   result.push({val: item.val, text: item.text, rate: item.rate});
      // });
      // console.log("res.data ===>", res.data);
    }
    return result;
    // setLoading(false);
  } catch (error) {
    console.error("getEntityInnnerData ===> EntityName: ", entityName, " Error: ", error);
    return result;
  }
};

export const getConsultants = async (hid) => {
  try {
    const res = await axios.get(`/staff/getconsultants/?hid=${hid}`);

    // console.log("getConsultant res.data AAAAAA ===>", res.data);
    //   console.log("getRecord by appointmentType  ===>", res.data);
    //   console.log("getRecord by res.status  ===>", res.status);
    if (res.data) {
      // console.log("getConsultant res.data ===>", res.data);
      const result = res.data.map((item) => {
        return { val: item.staffId, text: item.name };
      });
      return result;
    }
    // setLoading(false);
    return [];
  } catch (error) {
    console.error("getConsultant ===> Error: ", error);
    return [];
  }
};

// export function getDesignation() {
//   return [
//     "Admin",
//     "Doctor",
//     "Clerk",
//     "Accountant",
//     "Nurse",
//     "Physiotherapist",
//     "Lab-Technician",
//     "Senior-Resident",
//     "Resident",
//   ];
// }

export function getGender() {
  return [
    { val: "Male", text: "Male" },
    { val: "Female", text: "Female" },
    { val: "Transgender", text: "Transgender" },
  ];
}

export function getBloodGroup() {
  return [
    { val: "None", text: "None" },
    { val: "A+", text: "A+" },
    { val: "A-", text: "A-" },
    { val: "B+", text: "B+" },
    { val: "B-", text: "B-" },
    { val: "O+", text: "O+" },
    { val: "O-", text: "O-" },
    { val: "AB+", text: "AB+" },
    { val: "AB-", text: "AB-" },
  ];
}

export function getNationality() {
  return [
    { val: "Indian", text: "Indian" },
    { val: "NRI", text: "NRI" },
    { val: "Foreigner", text: "Foreigner" },
  ];
}

export function getCountryList() {
  return countryList;
}

export function getStateList() {
  return stateList;
}

export function getAgeInYear(dob) {
  /* The difference, in years, between NOW and 2012-05-07 */
  // moment().diff(moment('20120507', 'YYYYMMDD'), 'years')
  let age = 0;

  if (dayjs(dob).isValid()) {
    // let birthDate = new Date(dob);
    let birthDate = dayjs(dob);
    let todayDate = dayjs();
    age = todayDate.diff(birthDate, "year");

    // if (birthDate != null || birthDate != undefined) {
    // if (birthDate instanceof Date && !isNaN(birthDate.valueOf())) {
    // 1000 * 60 * 60 * 24 * 365.25  = 31557600000;
    //0.25 because of leap years
    // age = Math.floor((new Date() - birthDate.getTime()) / 31557600000);
    //  age = Math.floor((dayjs() - birthDate.getTime()) / 31557600000);
    //console.log("age ======>", age);
    //}
  }

  return age;
}

export function isAlphaInString(str) {
  return /^[a-zA-Z]+$/.test(str);
}

export function stringToNumber(str) {
  try {
    // console.log('stringToNumber Top ===>', str)
    // console.log('isAlphaInString(str) Top ===>', isAlphaInString(str))
    var num = 0;
    if (isAlphaInString(str)) {
      // var num = str.replace(/[^0-9]/g, '');
      // console.log('stringToNumber ===>', str)
      num = parseFloat(str.replace(/[^\d\.]*/g, ""));
      // return parseInt(num,10);
      // parseFloat(num);
    }
    else{
      num = str;
    }
    return parseFloat(num);

  } catch (error) {
    console.error("stringToNumber====> ", error);
    return 0;
  }
}

export function albhaNumericToString(albhaNumeric) {
  try {
    // var str = albhaNumeric.replace(/[^a-z]/gi, '');
    // // console.log('str ===>', str)
    //   return str;

    // var num = str.replace(/[^0-9]/g, '');

    var b = "";
    for (var i = 0; i < albhaNumeric.length; i++) {
      if (albhaNumeric[i] >= "A" && albhaNumeric[i] <= "z") b += albhaNumeric[i];
    }
    return b;
  } catch (error) {
    console.error("albhaNumericToString===> ", error);
    return "";
  }
}

export const isWeekend = (date) => {
  const day = date.day();
  return day === 0 || day === 6;
};

export const getExtension = (filename) => {
  return filename.split(".").pop().toLower();
};

// const groupBy = data.reduce((acc, row) => {
//   // console.log("row ==>", row);
//   const { depttName } = row;
//   acc[depttName] = acc[depttName] ?? [];
//   // console.log("acc[depttName] ==>", acc[depttName]);
//   acc[depttName].push(row);
//   return acc;
// }, {});

// Accepts the array and key
export const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((accumulate, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    // this step of code can be restructured to multiple lines, see below for the multi line code
    // debugger;
    (accumulate[currentValue[key]] = accumulate[currentValue[key]] || []).push(currentValue);

    // (accumulate[currentValue.feeHeadMain] = accumulate[currentValue.feeHeadMain] || []).push(currentValue);

    // This is how the above code in multiple line
    // if (!accumulate[currentValue[key]]) {
    //   accumulate[currentValue[key]] = [];
    // }
    // accumulate[currentValue[key]].push(currentValue);

    // Return the current iteration `accumulate` value, this will be taken as next iteration `accumulate` value and accumulate
    return accumulate;
  }, {}); // empty object is the initial value for accumulate object
};
