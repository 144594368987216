import React, { useState, useEffect } from "react";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import { css } from "@emotion/react";
import Notification from "../../components/Notification";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { InputAdornment, Box } from "@mui/material";
import { MyUseForm, MyForm } from "../../components/useForm";
// import LoadingSpinner from "../../components/LoadingSpinner";
import ClipLoader from "react-spinners/ClipLoader";

import Controls from "../../components/Controls";

import * as RegnServices from "../../helper/RegnServices";
import * as GenServices from "../../helper/GenServices";

const initialValues = {
  // _id: "0",
  mrNo: "",
  regNo: "",
  name: "",
  soDo: "S/o.",
  fhName: "",
  gender: "0", //here used 0 becouse of DropDown Control
  dob: null, //dayjs("2022-04-07"),
  age: "0Years",
  aadhaarNo: "",
  bloodGroup: "None", //here used 0 becouse of DropDown Control
  // treatmentType: "General",
  disease: "",
  diseaseHis: "",
  familyHis: "",
  pAddress: {
    address: "",
    city: "",
    pincode: "",
    state: "Haryana",
    country: "India",
    nationality: "Indian",
  },
  mobileNo: {
    pMobCountry: "+91",
    pMobNo: "",
    hMobCountry: "+91",
    hMobNo: "",
  },
  email: "",
  phoneNo: "",
  // IsReply: "Y",
  reffDoctor: "",
  staffUN: "",
  ip: "",
  // regFeeRs: 100,
  // isRsPaid: false,
  //   txnDetail: "",
  //   paymentMode: "",
  //   paymentStaffUN: "",
  //   paymentIp: "",
  //   userName: "",
  password: "",
  //   passwordConfirm: "",
  //   role:"PATIENT"
};

// const isWeekend = (date) => {
//   const day = date.day();
//   return day === 0 || day === 6;
// };

export default function RegistrationForm(props) {
  const { addOrEdit, recordForEdit, loading, setLoading, isEditRight } = props;

  const [isNameEdit, setIsNameEdit] = useState(false);
  const [ageSuffix, setAgeSuffix] = useState("Years");

  // const [loading, setLoading] = useState(false);

  const emailExpression = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  // error found in generateAuthToken, ValidationError: mobileNo.pMobNo: Mobile Number is required,
  // pAddress.city: City is required, pAddress.address: Address is required, email:  Please provide a valid email,
  // pAddress.pincode: Pincode is invalid!

  const validate = (fieldValues = formData) => {
    let temp = { ...errors };

    // console.log("fieldValues =====>", fieldValues);

    if ("name" in fieldValues) temp.name = fieldValues.name ? "" : "Name is required.";
    // if ("fhName" in fieldValues) temp.fhName = fieldValues.fhName ? "" : "Field is required.";
    if ("gender" in fieldValues) temp.gender = fieldValues.gender != 0 ? "" : "Gender is required.";
    if ("bloodGroup" in fieldValues) temp.bloodGroup = fieldValues.bloodGroup !== "0" ? "" : "BloodGroup is required.";
    // if ("age" in fieldValues) temp.age = parseInt(fieldValues.age) > -1 ? "" : "Age is required.";
    if ("age" in fieldValues) temp.age = GenServices.stringToNumber(fieldValues.age) > 0 ? "" : "Age is required.";

    if ("email" in fieldValues) {
      if (fieldValues.email != "") temp.email = emailExpression.test(fieldValues.email) ? "" : "Email is not valid.";
      if (fieldValues.email == "") temp.email = "";
    }
    // if ("disease" in fieldValues) temp.disease = fieldValues.disease ? "" : "Disease is required.";
    if ("mobileNo" in fieldValues) {
      if ("pMobNo" in fieldValues.mobileNo) {
        // console.log("pMobNo =====>",fieldValues );
        temp.pMobNo = fieldValues.mobileNo.pMobNo.length == 10 ? "" : "Enter Correct Mobile No.";
      }
    }

    if ("pAddress" in fieldValues) {
      if ("address" in fieldValues.pAddress) {
        // console.log("pMobNo =====>",fieldValues );
        temp.address = fieldValues.pAddress.address.length >= 3 ? "" : "Enter Correct Address";
      }
      if ("city" in fieldValues.pAddress) {
        // console.log("pMobNo =====>",fieldValues );
        temp.city = fieldValues.pAddress.city.length >= 3 ? "" : "Enter Correct City";
      }
      if ("state" in fieldValues.pAddress) {
        temp.state =
          fieldValues.pAddress.state !== "" && fieldValues.pAddress.state !== null ? "" : "Enter Correct state";
      }
      if ("country" in fieldValues.pAddress) {
        temp.country =
          fieldValues.pAddress.country !== "" && fieldValues.pAddress.country !== null ? "" : "Enter Correct country";
      }
      if ("nationality" in fieldValues.pAddress) {
        temp.nationality = fieldValues.pAddress.nationality !== "0" ? "" : "Enter Correct nationality";
      }
    }

    setErrors({
      ...temp,
    });

    //console.log("temp===>", temp);
    if ((fieldValues = formData)) return Object.values(temp).every((x) => x == "");
  };

  const [notify, setNotify] = React.useState({ isOpen: false, message: "", type: "" });

  const { formData, setFormData, errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);

  // console.log(formData);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      // RegnServices.insertPatient(formData);
      // resetForm();
      addOrEdit(formData);
    } else {
      setLoading(false);
      setNotify({ isOpen: true, message: "Please enter the value in required fields!", type: "error" });
      //  console.log(errors)
    }
  };

  useEffect(() => {
    if (recordForEdit != null) {
      // console.log('aaaaaaaaaaaaa');
      setFormData({
        ...recordForEdit,
      });
      setIsNameEdit(true);

      
    }
  }, [recordForEdit]);

  useEffect(() => {
    //  console.log("formData=========>", formData);
     if(!GenServices.isAlphaInString(formData.age) && formData.age > 0){
      setFormData((preValues) => ({ ...preValues, ["age"]: formData.age + ageSuffix }));
      // console.log("ageSuffix AAAAA=========>", ageSuffix);
    }
  }, [formData]);

//   useEffect(() => {
//     console.log("ageSuffix=========>", ageSuffix);
//  }, [ageSuffix]);

  // const countryList = GenServices.getCountryList().map(option => option.label);

  // const handleChangeAutocomplete = (e, val) => {
  //   //console.log(event.target.value);
  //   //const { name, value } = e.target;

  //   setFormData({...formData, pAddress:{country:{val}}});

  //   }

  // const [birthDate, setBirthDate] = React.useState(formData.dob);

  //console.log("countryList ===>", countryList)

  const resetForm = () => {
    setNotify({ isOpen: false, message: "", type: "" });
    setFormData(initialValues);
    setErrors({});
    setLoading(false);
  };

  const handleDOBChange = (e) => {
    const { name, value } = e.target;
    // if (name == "dob") {
    //   if (dayjs(value).isValid()) {
    //     setFormData((preValues) => ({ ...preValues, ["dob"]: value }));
    //     setFormData((preValues) => ({ ...preValues, ["age"]: GenServices.getAgeInYear(value) }));
    //   } else {
    //     setFormData((preValues) => ({ ...preValues, ["dob"]: null }));
    //   }
    // } else
    if (name == "age" && !dayjs(formData.dob).isValid()) {
      setFormData((preValues) => ({ ...preValues, ["age"]: value + ageSuffix }));
      validate({ ["age"]: value });
      //console.log('aaaaaaaaaa  age', value)
    }
    else if (name == "ageSuffix") {
      setAgeSuffix(value);
      setFormData((preValues) => ({ ...preValues, ["age"]: GenServices.stringToNumber(formData.age) + value }));
      // validate({ ["age"]: value });
      //console.log('aaaaaaaaaa  age', value)
    }
  };

  // const overrideCSS = {
  //   display: "block",
  //   margin: "0 auto",
  //   borderColor: "red",
  // };

  return (
    <>
      {/* {loading && <div style={{textAlign:"center"}}> <ClipLoader/> </div>} */}
      {/* <ClipLoader color={"#ffffff"} loading={loading} cssOverride={CSSProperties} size={50} aria-label="Loading Spinner" data-testid="loader" /> */}

      {/* <ClipLoader color={'#fff'} loading={loading} cssOverride={overrideCSS} size={150} />  */}

      {/* <div className="container">
      <ClipLoader color={'#fff'} loading={loading} cssOverride={override} size={150} /> 
    </div>
        */}

      <MyForm onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Name */}
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Controls.Input
              label="Full Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={errors.name}
              disabled={!isEditRight && isNameEdit}
            />
          </Grid>

          {/* Father/MotherName */}
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Controls.Input
              label="Father/Husband Name"
              sx={{ "& .MuiInputBase-root": { pl: 0 } }}
              name="fhName"
              value={formData.fhName}
              onChange={handleChange}
              error={errors.fhName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Controls.DropdownList
                      name="soDo"
                      value={formData.soDo}
                      onChange={handleChange}
                      options={[
                        { val: "S/o.", text: "S/o." },
                        { val: "D/o.", text: "D/o." },
                        { val: "W/o.", text: "W/o." },
                      ]}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* Gender */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.DropdownList
              label="Gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              options={GenServices.getGender()}
              error={errors.gender}
            />
          </Grid>

          {/* dob */}
          {/* <Grid item sm={6} md={2}> */}
          {/* <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.DatePicker label="Date of Birth" name="dob" value={formData.dob} onChange={handleDOBChange} />
          </Grid> */}

          {/* age */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.Input
              type="number"
              sx={{ "& .MuiInputBase-root": { pr: 0 } }}
              label="Age"
              name="age"
              value={GenServices.stringToNumber(formData.age)}
              onChange={handleDOBChange}
              error={errors.age}
              // InputProps={{
              //   endAdornment: <InputAdornment position="end">Year</InputAdornment>,
              // }}

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Controls.DropdownList
                      name="ageSuffix"
                      value={GenServices.albhaNumericToString(formData.age) || 'Years' }
                      onChange={handleDOBChange}           
                      options={[
                        { val: "Years", text: "Years" },
                        { val: "Months", text: "Months" },
                        { val: "Days", text: "Days" },
                      ]}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* bloodGroup */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.DropdownList
              label="bloodGroup"
              name="bloodGroup"
              value={formData.bloodGroup}
              onChange={handleChange}
              options={GenServices.getBloodGroup()}
              error={errors.bloodGroup}
            />
          </Grid>

          {/* disease */}
          {/* <Grid item sm={6} md={4}> */}
          {/* <Grid item xs={12} sm={8} md={6} lg={4}>
            <Controls.Input
              label="Disease"
              name="disease"
              value={formData.disease}
              onChange={handleChange}
              // error={errors.disease}
            />
          </Grid> */}

          {/* diseaseHis */}
          {/* <Grid item sm={6} md={3}> */}
          {/* <Grid item xs={12} sm={6} md={6} lg={3}>
            <Controls.Input
              label="Disease History"
              name="diseaseHis"
              value={formData.diseaseHis}
              onChange={handleChange}
              //error={errors.aadhaarNo}
            />
          </Grid> */}

          {/* familyHis */}
          {/* <Grid item xs={12} sm={6} md={6} lg={3}>
            <Controls.Input
              label="Family Disease History"
              name="familyHis"
              value={formData.familyHis}
              onChange={handleChange}
              //error={errors.aadhaarNo}
            />
          </Grid> */}

          {/* aadhaarNo */}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Controls.Input
              label="Aadhaar No."
              name="aadhaarNo"
              value={formData.aadhaarNo}
              onChange={handleChange}
              //error={errors.aadhaarNo}
            />
          </Grid>

          {/* Email */}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Controls.Input
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={errors.email}
            />
          </Grid>

          {/* mobileNo.pMobNo */}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Controls.Input
              label="Patient Mobile Number"
              name="mobileNo.pMobNo"
              value={formData.mobileNo.pMobNo}
              onChange={handleChange}
              error={errors.pMobNo}
              inputProps={{ maxLength: 10 }}
              // maxLength={10}
            />
          </Grid>

          {/* mobileNo.hMobNo */}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Controls.Input
              label="Home Mobile Number"
              name="mobileNo.hMobNo"
              value={formData.mobileNo.hMobNo}
              onChange={handleChange}
              error={errors.hMobNo}
            />
          </Grid>

          {/* pAddress.Address */}
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <Controls.Input
              label="Present Address"
              name="pAddress.address"
              value={formData.pAddress.address}
              onChange={handleChange}
              error={errors.address}
            />
          </Grid>

          {/* City */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.Input
              label="City"
              name="pAddress.city"
              value={formData.pAddress.city}
              onChange={handleChange}
              error={errors.city}
            />
          </Grid>

          {/* Pincode */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Controls.Input
              label="Pincode"
              name="pAddress.pincode"
              value={formData.pAddress.pincode}
              onChange={handleChange}
              error={errors.pincode}
            />
          </Grid>

          {/* State */}
          <Grid item xs={12} sm={4} md={3} lg={3}>
            <Controls.Autocomplete
              label="State"
              name="pAddress.state"
              value={formData.pAddress.state}
              // onChange={(e, val) => handleChangeAutocomplete(val, "pAddress.state")}
              onChange={handleChange}
              options={GenServices.getStateList().map((option) => option.name)}
              error={errors.state}
            />
          </Grid>

          {/* Country */}
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Controls.Autocomplete
              label="Country"
              name="pAddress.country"
              value={formData.pAddress.country}
              // onChange={(e, val) => handleChangeAutocomplete(val, "pAddress.country")}
              onChange={handleChange}
              options={GenServices.getCountryList().map((option) => option.label)}
              error={errors.country}
            />
          </Grid>

          {/* Nationality */}
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Controls.DropdownList
              label="Nationality"
              name="pAddress.nationality"
              value={formData.pAddress.nationality}
              onChange={handleChange}
              options={GenServices.getNationality()}
              error={errors.nationality}
            />
          </Grid>

          {/* reffDoctor */}
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Controls.Input
              label="Referral Doctor"
              name="reffDoctor"
              value={formData.reffDoctor}
              onChange={handleChange}
              error={errors.reffDoctor}
            />
          </Grid>

          {/* remarks */}
          <Grid item xs={12} sm={6} md={3} lg={4}>
            <Controls.Input
              label="Remarks"
              name="remarks"
              value={formData.remarks}
              onChange={handleChange}
              // error={errors.remarks}
            />
          </Grid>

          {/* Button */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              m={1}
              display="flex"
              // justifyContent="space-between"
              // justifyContent="space-around"
              // justifyContent="center"
              // alignItems="left"
            >
              <span>
                <Controls.Button
                  text="Reset"
                  onClick={resetForm}
                  //variant="outlined"
                  startIcon={<RestartAltIcon />}
                  // color="default"
                />
              </span>
              <span style={{ mx: "10px", flexGrow: 1, textAlign: "center" }}>
                <Controls.Button
                  sx={{ mx: "10px" }}
                  text="Save"
                  //onClick={() => console.log(formData)}
                  //variant="outlined"
                  type="submit"
                  disabled={loading}
                  startIcon={<SaveRoundedIcon />}
                />
                <ClipLoader loading={loading} color="#36d7b7" speedMultiplier={1} />
              </span>
            </Box>
          </Grid>

          {/* <Grid item sm={12}>
          <FormControlLabel control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid> */}

          {/* <Grid item sm={12} md={3}>
          <FormGroup row>  
            
            <TextField label="Test-Name" variant="standard" placeholder="username" />
            </FormGroup>
        </Grid> */}
        </Grid>
      </MyForm>

      <Notification notify={notify} setNotify={setNotify} />

      {/* <Grid item sm={12} md={3}>
            <Controls.RadioGroup
              label="Gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              items={GenServices.getGender()}
              //required={true}
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">Year</InputAdornment>
              //   ),
              // }}
            />
          </Grid> */}
    </>
  );
}
