//  As for trueConnect.jio.com
export const stateList = [
  {
    code: "AN",
    name: "Andaman and Nicobar Islands",
    countryCode: "IN",
  },
  {
    code: "AP",
    name: "Andhra Pradesh",
    countryCode: "IN",
  },
  {
    code: "AR",
    name: "Arunachal Pradesh",
    countryCode: "IN",
  },
  {
    code: "AS",
    name: "Assam",
    countryCode: "IN",
  },
  {
    code: "BR",
    name: "Bihar",
    countryCode: "IN",
  },
  {
    code: "CH",
    name: "Chandigarh",
    countryCode: "IN",
  },
  {
    code: "CT",
    name: "Chhattisgarh",
    countryCode: "IN",
  },
  {
    code: "DN",
    name: "Dadra and Nagar Haveli and Daman and Diu",
    countryCode: "IN",
  },
  {
    code: "DD",
    name: "Daman and Diu",
    countryCode: "IN",
  },
  {
    code: "DL",
    name: "Delhi",
    countryCode: "IN",
  },
  {
    code: "GA",
    name: "Goa",
    countryCode: "IN",
  },
  {
    code: "GJ",
    name: "Gujarat",
    countryCode: "IN",
  },
  {
    code: "HR",
    name: "Haryana",
    countryCode: "IN",
  },
  {
    code: "HP",
    name: "Himachal Pradesh",
    countryCode: "IN",
  },
  {
    code: "JK",
    name: "Jammu and Kashmir",
    countryCode: "IN",
  },
  {
    code: "JH",
    name: "Jharkhand",
    countryCode: "IN",
  },
  {
    code: "KA",
    name: "Karnataka",
    countryCode: "IN",
  },
  {
    code: "KL",
    name: "Kerala",
    countryCode: "IN",
  },
  {
    code: "LK",
    name: "Ladakh",
    countryCode: "IN",
  },
  {
    code: "LD",
    name: "Lakshadweep",
    countryCode: "IN",
  },
  {
    code: "MP",
    name: "Madhya Pradesh",
    countryCode: "IN",
  },
  {
    code: "MH",
    name: "Maharashtra",
    countryCode: "IN",
  },
  {
    code: "MN",
    name: "Manipur",
    countryCode: "IN",
  },
  {
    code: "ML",
    name: "Meghalaya",
    countryCode: "IN",
  },
  {
    code: "MZ",
    name: "Mizoram",
    countryCode: "IN",
  },
  {
    code: "NL",
    name: "Nagaland",
    countryCode: "IN",
  },
  {
    code: "OR",
    name: "Odisha",
    countryCode: "IN",
  },
  {
    code: "OS",
    name: "Offshore",
    countryCode: "IN",
  },
  {
    code: "PY",
    name: "Pondicherry",
    countryCode: "IN",
  },
  {
    code: "PY",
    name: "Puducherry",
    countryCode: "IN",
  },
  {
    code: "PB",
    name: "Punjab",
    countryCode: "IN",
  },
  {
    code: "RJ",
    name: "Rajasthan",
    countryCode: "IN",
  },
  {
    code: "SK",
    name: "Sikkim",
    countryCode: "IN",
  },
  {
    code: "TN",
    name: "Tamil Nadu",
    countryCode: "IN",
  },
  {
    code: "TG",
    name: "Telangana",
    countryCode: "IN",
  },
  {
    code: "TR",
    name: "Tripura",
    countryCode: "IN",
  },
  {
    code: "UP",
    name: "Uttar Pradesh",
    countryCode: "IN",
  },
  {
    code: "UT",
    name: "Uttarakhand",
    countryCode: "IN",
  },
  {
    code: "WB",
    name: "West Bengal",
    countryCode: "IN",
  },
];
