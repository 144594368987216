import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { login, logout } from "../../context/Actions";
import { useGlobalContext } from "../../context/Context";

export default function Logout() {
  const navigate = useNavigate();

  const { isLogin, isLogout, state, dispatch } = useGlobalContext();

  const handleLogout = () => {
    //event.preventDefault();
    //console.log(userData);
    dispatch(logout());
    // localStorage.removeItem("hms-token");
    // navigate("/login");

    console.log("logout ===>", isLogout);
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <div>
      <h1>This is Logout Page</h1>
    </div>
  );
}
