import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid as MuiDataGrid, GridToolbar } from "@mui/x-data-grid";
import { Typography } from "@mui/material";

// const columns = [
//   { field: 'id', headerName: 'ID', width: 90 },
//   {
//     field: 'firstName',
//     headerName: 'First name',
//     width: 150,
//     editable: true,
//   },
//   {
//     field: 'lastName',
//     headerName: 'Last name',
//     width: 150,
//     editable: true,
//   },
//   {
//     field: 'age',
//     headerName: 'Age',
//     type: 'number',
//     width: 110,
//     editable: true,
//   },
//   {
//     field: 'fullName',
//     headerName: 'Full name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     valueGetter: (params) =>
//       `${params.row.firstName || ''} ${params.row.lastName || ''}`,
//   },
// ];

//const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

export default function DataGrid(props) {
  const columns = props.columns;
  const rows = props.rows;

  const { heading, showToolbar, loading, pageSize = 5, boxHeight = 380, ...other } = props;

  // const boxHeight1= {boxHeight < 100 ? pageSize : 100}
  // console.log('...other ', {...other})

  if (!Array.isArray(rows)) {
    // console.log("Data not found!");  isPaging = true,
    return (
      <Typography variant="h5" color="inherit" noWrap sx={{ textAlign: "center", padding: "80px" }}>
        Data not found!
      </Typography>
    );
  }

  return (
    <Box sx={{ height: boxHeight, width: "100%", mb: 4 }}>
      <Typography variant="h6" color="inherit" noWrap sx={{ pb: 1 }}>
        {heading}
      </Typography>
      <MuiDataGrid
        // sx={{
        //   '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '80px' },
        //   // '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
        //   // '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        // }}
        rows={rows}
        columns={columns}
        getRowId={(row) => (row._id ? row._id : row.id)}
        // pageSize={100}
        // {...(isPaging && {pageSize:5})}
        // pageSize={isPaging == true ? 5 : 100}
        // pageSize={pageSize == 0 ? 0 : pageSize < 100 ? pageSize : 100}

        pageSize={(pageSize > 0 && (pageSize < 100 ? pageSize : 100)) || 0}
        // pageSize={50}
        // rowsPerPageOptions={[5]}
        // autoPageSize

        // checkboxSelection
        // margin="dense"
        // spacing={5}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        components={{ Toolbar: showToolbar && GridToolbar }}
        loading={loading}
        {...other}
        getRowHeight={() => "auto"}
        getCollumnWidth={() => "auto"}
        // getRowHeight={() => '30px'}
        // rowHeight={'30px'}
        // componentsProps={{ toolbar: { csvOptions: { allColumns: true } } }}
        //componentsProps={{ toolbar: { csvOptions: { fields: ['lastName', 'firstName'] } } }}
        //componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true } } }}
      />
    </Box>
  );
}

// import { Table } from "@mui/material";
// import React from "react";

// export default function useTable() {

//   const TblContainer = (props) =>{
//     //console.log(props);
//     return(
//     <Table>
//         {props.children}
//     </Table>

{
  /* <Table>
<TableBody>
  {records.map((item) => (
    <TableRow key={item.id}>
      <TableCell>{item.name} </TableCell>
      <TableCell>{item.fhName} </TableCell>
      <TableCell>{item.age} </TableCell>
      <TableCell>{item.aadhaarNo} </TableCell>
      <TableCell>{item.department} </TableCell>
    </TableRow>
  ))}
</TableBody>
</Table> */
}

//     )
//   };

//   return {
//     TblContainer,
//   };
// }
