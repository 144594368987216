import React, { useEffect } from "react";

import { Routes, Route, NavLink, BrowserRouter, Navigate } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";

// import * as GenServices from "../helper/GenServices";

import { useGlobalContext } from "../context/Context";

import NavigationList, { MenuRight } from "./NavigationList";

// const getRoles = {
//   RECEPTIONIST: "RECEPTIONIST",
//   DOCTOR: "DOCTOR",
//   LABORATORY: "LABORATORY",
//   ADMIN: "ADMIN",
// };

export const RenderRoutes = () => {
  const { isLogin, roles } = useGlobalContext();

  // const userRoles1 = userPayload?.roles?.role;

  let userRoles = [];

  if (roles) {
    userRoles = roles;
  }

  // console.log("roles List: ====", roles);

  // console.log("userRoles: ====", userRoles);

  // const Navigation1 = NavigationList;

  const loginOutmenus = NavigationList.filter((r) => r.group === 0);

  let routesMenuList = [];

  if (userRoles.includes("SUPERADMIN")) {
    routesMenuList = NavigationList.filter((r) => r.group !== 0);
  } else if (userRoles.includes("RECEPTIONIST")) {
    routesMenuList = NavigationList.filter((r) => r.group !== 0 && MenuRight.RECEPTIONIST.includes(r.id));
  } else if (userRoles.includes("LABORATORY")) {
    routesMenuList = NavigationList.filter((r) => r.group !== 0 && MenuRight.LABORATORY.includes(r.id));
  } else if (userRoles.includes("ADMIN")) {
    routesMenuList = NavigationList.filter((r) => r.group !== 0 && MenuRight.ADMIN.includes(r.id));
  }

  // console.log("routesMenuList: ====", routesMenuList);

  return (
    <>
      {/* <BrowserRouter> */}

      <Routes>
        {/* Login and Logout Menus */}
        {loginOutmenus.map((r, i) => {
          // if (r.isPrivate && isLogin) {
          return <Route key={i} path={r.path} element={r.element} />;
        })}

        {routesMenuList.map((r, i) => {
          // if (r.isPrivate && isLogin) {
          // if (isLogin) {
          return <Route key={i + 999} path={r.path} element={r.element} />;
          // } else {
          //   return true;
          // }

          // else { return true; }
        })}
        {/* <Route path="/404" element={<ErrorPage />} /> */}
        {/* <Route path="*" element={<Navigate replace to="/404" />} /> */}
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
      {/* </BrowserRouter> */}
    </>
  );
};

export const RenderMenu_Group1 = () => {
  // const roles = GenServices.getRoles();

  //  "RECEPTIONIST",
  //  "DOCTOR",
  //  "LABORATORY",
  //  "ADMIN",

  const { isLogin, roles } = useGlobalContext();

  const userRoles = roles;

  // console.log("roles List: ====", roles);
  // console.log("userRoles: ====", userRoles);

  let menuList = [];

  if (userRoles.includes("SUPERADMIN")) {
    menuList = NavigationList.filter((r) => r.group === 1 && r.isMenu);
  } else if (userRoles.includes("RECEPTIONIST")) {
    menuList = NavigationList.filter((r) => r.group === 1 && r.isMenu && MenuRight.RECEPTIONIST.includes(r.id));
  } else if (userRoles.includes("LABORATORY")) {
    menuList = NavigationList.filter((r) => r.group === 1 && r.isMenu && MenuRight.LABORATORY.includes(r.id));
  } else if (userRoles.includes("ADMIN")) {
    menuList = NavigationList.filter((r) => r.group === 1 && r.isMenu && MenuRight.ADMIN.includes(r.id));
  }

  return (
    <>
      {menuList.map((r, i) => {
        if (isLogin) {
          return <CustomListItemButton key={i} name={r.name} path={r.path} icon={r.menuIcon} />;
        } else {
          return false;
        }
      })}
    </>
  );
};

//Below Menu shown in Report-Menu
export const RenderMenu_Group2 = () => {
  const { isLogin, roles } = useGlobalContext();

  const userRoles = roles;

  let menuList = [];

  if (userRoles.includes("SUPERADMIN")) {
    menuList = NavigationList.filter((r) => r.group === 2 && r.isMenu);
  } else if (userRoles.includes("RECEPTIONIST")) {
    menuList = NavigationList.filter((r) => r.group === 2 && r.isMenu && MenuRight.RECEPTIONIST.includes(r.id));
  } else if (userRoles.includes("LABORATORY")) {
    menuList = NavigationList.filter((r) => r.group === 2 && r.isMenu && MenuRight.LABORATORY.includes(r.id));
  } else if (userRoles.includes("ADMIN")) {
    menuList = NavigationList.filter((r) => r.group === 2 && r.isMenu && MenuRight.ADMIN.includes(r.id));
  }

  return (
    <>
      {menuList.length >= 1 && (
        <ListSubheader component="div" inset>
          Reports
        </ListSubheader>
      )}
      {menuList.map((r, i) => {
        if (isLogin) {
          return <CustomListItemButton key={i} name={r.name} path={r.path} icon={r.menuIcon} />;
        } else {
          return false;
        }
      })}
    </>
  );
};

const CustomListItemButton = ({ name, path, icon }) => (
  <ListItemButton component={NavLink} to={path}>
    <ListItemIcon color="blue">
      {/* {icon && {icon}} */}
      {icon}
    </ListItemIcon>
    <ListItemText primary={name} />
  </ListItemButton>
);
