import {legacy_createStore as createStore} from 'redux';
// import { createStore } from "redux";

import allReducers from "./reducers";

const store = createStore(
  allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// const store = createStore(todos, ['Use Redux'])

// store.dispatch({
//   type: 'INCREMENT'
// })

// console.log(store.getState());

export default store;