import "./App.css";
import Controls from "./components/Controls";
// import "./node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "./node_modules/bootstrap/dist/js/bootstrap.min.js";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Container from "@mui/material/Container";
//import store from './redux/store'
// import { useSelector, useDispatch } from "react-redux";
// import { incrementNumber, decrementNumber } from "./redux/actions";
 
import { Link as MuiLink } from "@mui/material";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles"; 
import TopLeftBarHeader from "./components/TopLeftBarHeader";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "./context/Context";

// import T2 from "./pages/Test1/T2";
 
import { RenderRoutes } from "./navigation/RenderNavigation";
// import Layout from "./components/Layout";

const theme = createTheme();

// const darkTheme = createTheme({
//   palette: {
//     mode: "dark",
//   },
// });

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <MuiLink color="inherit" href="https://essnet.in/" target="_blank">
        www.essnet.in
      </MuiLink>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
  
 
// const ClearCacheComponent = withClearCache(MainApp);

// function App() {
//   return <ClearCacheComponent />;
// }

function App(props) {
  const navigate = useNavigate(); 
 
  // const classes = useStyles();
  // console.log("Project env =====> ", process.env.REACT_APP_API_URL," ", process.env.REACT_APP_PROJECT_ENV);

  const { isLogin } = useGlobalContext();

  // useEffect(() => {
  //   if (!isLogin) {
  //     navigate("/login");
  //   }
  // }, []);

  // console.log(<RenderRoutes />);

  const showLogin = async () => {
    // alert('aaaaaaaaaaaaaaaaaaa');
    try {
      navigate("/login");
    } catch (error) {
      console.error(error);
      // setLoading(false);
    }
  };

  return (
    <>
      {/* {!isLogin && navigate("/login")} */}

      {/* <h1>This is App Page</h1> */}

      {/* <RouterProvider router={router} /> */}
      <ThemeProvider theme={theme}>
        {/* <Routes> */}
        {/* <Route path="/login" element={<Login />} />,  */}
        {/* </Routes> */}
        <TopLeftBarHeader>
          {<RenderRoutes />}
          <Copyright sx={{ position: "relative", textAlign: "center", width: `calc(100% - 300px)` }} />

          {!isLogin && (
            <div style={{ position: "fixed", top: "40%", bottom: "50%", left: "40%", right: "50%" }}>
              <Controls.Button
                variant="outlined"
                text="SHOW LOGIN"
                color="primary"
                onClick={showLogin}
              ></Controls.Button>
            </div>
          )}
        </TopLeftBarHeader>
      </ThemeProvider> 
    </>
  ); 
}

export default App;
